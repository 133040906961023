import { LoanTermsAPIRoutes, PageRoutes, ProductAliases } from '../../../shared/constants';
import PMT from '../../../utils/PMT';
import { navigate } from '@reach/router';
import { ITerm, IFee, IRate } from '../../../Application/store/Models/Offers';
import RATE from '../../../utils/RATE';
import { IBaseSelectKeys } from '../../../Application/store/Models/ApplicantInfo';

class SelectLoanAmountStore {
  api: any;

  private applicationStore: any;

  constructor(api: any, applicationStore: any) {
    this.api = api;
    this.applicationStore = applicationStore;
  } 

   findMaxCreditAmount = (selectedOffer: any, collateralOptions:any) =>
  {
    const collateralAccount = collateralOptions.options.find(
      (k: IBaseSelectKeys) => k.value === selectedOffer.collateralAccount
    );
   
  var maxDocStampFee = 0;
  const maxLoanAmount =Math.min(selectedOffer.loanMax, Math.floor(collateralAccount.balance));
      if(selectedOffer.hasDocStampFee) {
        const feeData = selectedOffer.feeData.find(
          (k: IFee) =>
            k.minInclusive <= maxLoanAmount && k.maxInclusive >= maxLoanAmount
        );
       maxDocStampFee =
          ((maxLoanAmount + (feeData ? feeData.fee : 0)) / 100.0) * 0.35;
        }   

        if((maxLoanAmount + maxDocStampFee)>collateralAccount.balance)
        {
            return maxLoanAmount - maxDocStampFee;
        }
        else
        {
            return maxLoanAmount;
        }
  }

  handleLoanAmountChange = async (selectedLoanAmount: string): Promise<void> => {
    let selectedOffer = this.applicationStore.selectedOffer;

    if (!selectedOffer.selectedLoanAmount || selectedOffer.selectedLoanAmount === '') return;
    selectedOffer.selectedLoanAmount = parseInt(selectedLoanAmount);

    selectedOffer.calculatedterms = selectedOffer.termData.find(
      (k: ITerm) =>
        k.minInclusive <= selectedOffer.selectedLoanAmount && k.maxInclusive >= selectedOffer.selectedLoanAmount
    )?.term;

    if (selectedOffer.hasDocStampFee) {
      const feeData = selectedOffer.feeData.find(
        (k: IFee) =>
          k.minInclusive <= selectedOffer.selectedLoanAmount && k.maxInclusive >= selectedOffer.selectedLoanAmount
      );
      selectedOffer.calculatedDocStampFee =
        ((selectedOffer.selectedLoanAmount + (feeData ? feeData.fee : 0)) / 100.0) * 0.35;
    }

    if (this.applicationStore.isOfferUnsecured()) {
      // First, calculate the monthly payment estimate.
      selectedOffer.monthlyPaymentEstimate = -PMT(
        selectedOffer.annualPercentRateFixed / 100.0 / 12.0,
        selectedOffer.calculatedterms,
        selectedOffer.selectedLoanAmount + selectedOffer.calculatedDocStampFee
      );

      // Then, use monthly payment estimate to calculate the APR.
      selectedOffer.annualPercentRateCalculated = RATE(
        selectedOffer.calculatedterms,
        -1 * selectedOffer.monthlyPaymentEstimate.toFixed(2),
        selectedOffer.selectedLoanAmount + selectedOffer.calculatedDocStampFee,
        0 // added since LOB spreadsheet had this for Excel RATE function
      );
    } else if (this.applicationStore.isOfferSecured()) {
      // First, calculate the APR.
      const rateData = selectedOffer.rateData.find(
        (k: IRate) =>
          k.minInclusive <= selectedOffer.selectedLoanAmount && k.maxInclusive >= selectedOffer.selectedLoanAmount
      );
      selectedOffer.annualPercentRateCalculated = rateData.rate;

      // Then, use APR to calculate the monthly payment estimate.
      selectedOffer.monthlyPaymentEstimate = -PMT(
        selectedOffer.annualPercentRateCalculated / 100.0 / 12.0,
        selectedOffer.calculatedterms,
        selectedOffer.selectedLoanAmount + selectedOffer.calculatedDocStampFee
      );
    }
    
    selectedOffer.totalAmountFinanced = selectedOffer.selectedLoanAmount + selectedOffer.calculatedDocStampFee;
  };

  callLoanValidate = async (): Promise<void> => {
    const { setMeta, handleFatalErrors, postRequestBuilder, selectedOffer, collateralOptions } = this.applicationStore;

    try {
      const Fields = [
        {
          annualPercentRateTotal: selectedOffer.annualPercentRateCalculated,
          loanAmount: selectedOffer.selectedLoanAmount,
          monthlyPaymentEstimatesCount: selectedOffer.calculatedterms,
          estimatedPayment: selectedOffer.monthlyPaymentEstimate,
          docStampFee: selectedOffer.calculatedDocStampFee,
          amountFinancedTotal: selectedOffer.totalAmountFinanced,
          processingFee: selectedOffer.processingFee,
          collateralAccountNumber:
            selectedOffer.productAlias === ProductAliases.DepositSecuredLoan ? selectedOffer.collateralAccount : null,
          fundsRemaining:
            selectedOffer.productAlias === ProductAliases.DepositSecuredLoan ? selectedOffer.fundsRemaining : null,
        },
      ];

      const callLoanValidateResponse = await this.api({
        url: LoanTermsAPIRoutes.loanValidate,
        method: 'post',
        data: postRequestBuilder(Fields),
      });

      const { errors } = callLoanValidateResponse.data;

      if (errors && errors.length > 0) {
        setMeta({ errors });

        let fatalError = errors.find((e: { type: string }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }

      navigate(PageRoutes.SelectLoanPurposePageRoute);
    } catch (e) {
      console.log('SelectLoanAmountStore | Failed on callLoanValidate. Details: ', e);
      handleFatalErrors(e);
    }
  };
}
export default SelectLoanAmountStore;
