import React from 'react';
import queryString from 'query-string';
import { getLoanProduct } from '../../shared/services';
import { ProductAliases } from '../../shared/constants';

let alias = ProductAliases.DigitalLoan;
const parsedUrl = queryString.parse(window.location.search);
if (Object.prototype.hasOwnProperty.call(parsedUrl, 'productAlias')) {
  // @ts-ignore
  alias = parsedUrl.productAlias;
}

const product = getLoanProduct(alias);

const productAliasContext = React.createContext<any>({
  productPageTitle: product.title,
  productThumbnailSrc: product.imageSrc,
  productImageDataTestId: product.imageDataTestId,
});

export default productAliasContext;
