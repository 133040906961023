import { observable } from 'mobx';

export interface ILoanPurpose {
  name: string,
  alias: string
}

const LoanPurposes: ILoanPurpose[] = observable([
]);

export default LoanPurposes;