
const getRegionsHost = () => {
  let host = 'https://jointhebank.regions.com/';

  if (window.location.host.indexOf('-dev') > -1) {
    host = 'https://jointhebank-dev.regionstest.com/'
  }
  else if (window.location.host.indexOf('-qa') > -1) {
    host = 'https://jointhebank-qa.regionstest.com/'
  }
  else if (window.location.host.indexOf('-test') > -1) {
    host = 'https://jointhebank-test.regionstest.com/'
  }
  else if (window.location.host.indexOf('-prod') > -1) {
    host = 'https://jointhebank.regions.com/'
  }

  return host;
};
export default getRegionsHost;




