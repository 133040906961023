export enum ProductAliases {
  DigitalLoan = 'digitalloan',
  PreferredCreditLine = 'prefcreditline',
  OverdraftProtectionCreditLine = 'odpcreditline',
  SavingsSecuredCreditLine = 'securedcreditline',
  UnsecuredLoan = 'unsecuredloan',
  DepositSecuredLoan = 'tdsecured',
}

export enum CustomerTypes {
  primary = 'primary',
}

export enum RuleAliases {}

export enum PageTitles {
  ApplyForARegionsDigitalLoan = 'Apply For a Regions Digital Loan',
  MaintenanceTitle = "We're currently undergoing maintenance",
}

export enum ModalTitles {
  MakeAppointmentModalTitle = 'Make an appointment',
  CoBrowseModalTitle = 'Request screen share',
}

export enum PageRoutes {
  ActivatePageRoute = '/activate',
  GetStartedPageRoute = '/get-started',
  AccountAgreementsPageRoute = '/account-agreements',
  CongratulationsPageRoute = '/congratulations',
  ReviewOfferPageRoute = '/review-offer',
  SelectCollateralAccountPageRoute = '/select-collateral',
  SelectOverdraftAccountPageRoute = '/select-overdraft',
  SelectCreditLimitPageRoute = '/select-credit-limit',
  SelectLoanAmountPageRoute = '/select-loan-amount',
  SelectLoanPurposePageRoute = '/select-loan-purpose',
  SelectTransferAmountPageRoute = '/select-transfer-amount',
  TransferConfirmationPageRoute = '/transfer-confirmation',
  TransferFundsPageRoute = '/transfer-funds',

  // Error views
  GenericErrorPageRoute = '/error',
  Error503PageRoute = '/error-503',
  DeclinationPageRoute = '/declination',
  OdpDeclinationPageRoute = '/odp-declination',
}

export enum CommonUrlRoutes {
  MakeAppointmentTimeTradeRoute = 'https://timetrade.com/app/regions/workflows/REGPHONE/schedule',
  LoanProductPageUrlRoute = 'https://www.regions.com/personal-banking/loans-and-lines-of-credit',
}

export enum FooterUrlRoutes {
  AboutRegionsFooterUrlRoute = 'https://www.regions.com/about-regions',
  InvestorRelationsFooterUrlRoute = 'https://ir.regions.com/',
  PrivacyAndSecurityFooterUrlRoute = 'https://www.regions.com/about-regions/privacy-security',
  WebsiteTermsAndConditionsFooterUrlRoute = 'https://www.regions.com/about-regions/privacy-security/terms-conditions',
  OnlineTrackingAndAdvertisingFooterUrlRoute = 'https://www.regions.com/about-regions/privacy-security/online-privacy-notice#ads',
  AccessibleBankingFooterUrlRoute = 'https://www.regions.com/about-regions/accessible-banking',
  LeaveFeedbackFooterUrlRoute = 'https://survey.regions.com/jfe/form/SV_0Jm6o1kcV7mAmA5?source=rdocredit',
}

// eslint-disable-next-line no-shadow
export enum TransmitAPIRoutes {
  tsAuthDev = 'https://tsauthdev.rgbk.com/api/v2/oidc/oidc_dev/authorize',
  tsAuthQA = 'https://tsciauth.rgbk.com/api/v2/oidc/oidc/authorize',
  tsAuthTest = 'https://olbauth.regionstest.com/api/v2/oidc/oidc_test/authorize',
  tsAuthProd = 'https://olbauth.regions.com/api/v2/oidc/oidc/authorize',
}

// eslint-disable-next-line no-shadow
export enum AuthenticationAPIRoutes {
  AuthenticationFeatureSwitchGet = 'api/authentication/featureswitch/get',
}

// FORM-BASED CONSTANTS
export enum GenericErrorMessages {
  FieldIsRequiredMessage = 'This field is required',
  PEPMessage = 'We are unable to accept online applications from politically exposed persons (PEPs). We apologize for the inconvenience. If you have any questions please visit a Regions branch near you.',
}

export enum CoBrowseServiceNumberValidationMessage {
  RequiredErrorMessage = 'Service Number is required. Please request a service number from the associate who will be sharing your screen.',
  InvalidServiceNumber = 'The Service Number is invalid',
  InvalidServiceNumberLength = 'Service Number has a minimum amount of characters of 6.',
}

export enum EAPRoutes {
  EAP_DEV = 'https://login-dev.rgbk.com',
  EAP_QA = 'https://login-ci.rgbk.com',
  EAP_TEST = 'https://login.regionstest.com',
  EAP_PROD = 'https://login.regions.com',
}

// API ROUTES
export enum GetStartedAPIRoutes {
  MaintenanceCheck = 'api/maintenancemode',
  FetchTokenRoute = 'api/oauth/createtoken',
  AuthorizeEnterprise = 'api/loans/Authorize/enterprise',
  SSOLoginValidate = 'api/login/sso',
  offersInitiateSso = 'api/loans/offers/initiate/sso',
  offersInitiateEap = 'api/loans/offers/initiate/enterprise',
  offersValidate = 'api/loans/offers/validate',
  loanValidate = 'api/loans/Terms/validate',
  configInitiate = 'api/loans/ConfigurationSettings/get',
  TokenInitiate = 'api/loans/SSO/token/initiate',
}

export enum AccountDetailsAPIRoutes {
  accountDetailsInitiate = 'api/loans/AccountDetails/initiate',
}

export enum AccountAgreementsAPIRoutes {
  accountAgreementsInitiate = 'api/loans/AccountAgreements/initiate',
  accountAgreementsValidate = 'api/loans/AccountAgreements/validate',
}

export enum BookingTermsAPIRoutes {
  bookingTermsInitiate = '/api/loans/terms/initiate',
}

export enum LoanTermsAPIRoutes {
  loanValidate = 'api/loans/Terms/validate',
}

export enum LivePersonRoutes {
  GetJWT = 'api/liveperson/LivePersonJwtToken/createjwttoken',
}

export enum SelectLoanPurposeAPIRoutes {
  loanPurposeInitiate = 'api/loans/loanpurpose/initiate',
  loanPurposeValidate = 'api/loans/loanpurpose/validate',
}

export enum ReviewLoanOfferAPIRoutes {
  reviewLoanValidate = 'api/loans/ReviewLoan/validate',
}

export enum CongratulationsAPIRoutes {
  bookLoan = 'api/loans/Booking/validate',
}

// ENVIRONMENTS
export enum UIEnvironmentRoutes {
  UIDevURL = 'loans-ui-dev.apps.np-dmz-b.openshift.rgbk.com',
  UIDevVanityURL = 'dlo-dev.regionstest.com',
  UIQAURL = 'loans-ui-qa.apps.np-dmz-b.openshift.rgbk.com',
  UIQAVanityURL = 'dlo-qa.regionstest.com',
  UITestURL = 'loans-ui-test.apps.np-dmz-b.openshift.rgbk.com',
  UITestVanityURL = 'dlo-test.regionstest.com',
  UIProductionVanityURL = 'dlo.regions.com',
}

export enum APIGatewayEnvironmentRoutes {
  APIGatewayLocalURL = 'https://loans-api-gateway-dev.apps.np-dmz-b.openshift.rgbk.com',
  APIGatewayDevURL = 'https://loans-api-gateway-dev.apps.np-dmz-b.openshift.rgbk.com',
  APIGatewayDevVanityURL = 'https://loans-api-gateway-dev.regionstest.com',
  APIGatewayQAURL = 'https://loans-api-gateway-qa.apps.np-dmz-b.openshift.rgbk.com',
  APIGatewayQAVanityURL = 'https://loans-api-gateway-qa.regionstest.com',
  APIGatewayTestURL = 'https://loans-api-gateway-test.apps.np-dmz-b.openshift.rgbk.com',
  APIGatewayTestVanityURL = 'https://loans-api-gateway-test.regionstest.com',
  APIGatewayProductionURL = 'https://loans-api-gateway.regions.com',
}

export const API_PORT = process.env.REACT_APP_API_PORT || '';

export enum AcapsStatusCode {
  A = '000',
  D = '001',
  E = '010',
  R = '011',
  U = '100',
}

export enum ProgressBarCurrentStep {
  NoProgressBarOnPage = 1,
}

export enum AppSettings {
  LoansChatEnabled = 'LoansChatEnabled',
  LoansCoBrowseEnabled = 'LoansCoBrowseEnabled',
  LpJsTag = 'LPJSTAG',
}

export const ProductMap = new Map<string, string>([
  [ProductAliases.UnsecuredLoan, 'Unsecured Installment Loan'],
  [ProductAliases.PreferredCreditLine, 'Preferred Line of Credit'],
  [ProductAliases.DepositSecuredLoan, 'Savings Secured Loan'],
  [ProductAliases.SavingsSecuredCreditLine, 'Savings Secured Line of Credit'],
  [ProductAliases.OverdraftProtectionCreditLine, 'Protection Line of Credit'],
]);
