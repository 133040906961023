import { floatFrom } from "../../components/ValidatedCurrencyField";

const hasMinMaxLength = (
  value: string,
  minLength: number,
  maxLength: number,
  errorMessage: string,
  allowSpaces = false
) => {
  // Checks if value is empty space, or if string starts with an empty space
  if (!allowSpaces && (value === ' ' || /^\s/.test(value))) {
    return { valid: false, errorMessage };
  }
  const testMinLength = value.length >= minLength;
  const testMaxLength = value.length <= maxLength;

  if (!testMinLength || !testMaxLength) {
    return { valid: false, errorMessage };
  }
  return { valid: true, errorMessage: '' };
};

export default hasMinMaxLength;

export interface IValidation {
  name: string;
  isValid: Function;
  errorMessage: Function;
}

export const BaseValidations = Array<IValidation>(
  {
    name: 'IfValueExists',
    isValid: (v: string, min?: number, max?: number, balance?: number) => {
      if (!v) {
        return false;
      }
      return true;
    },
    errorMessage: (min?: number, max?: number, balance?: number) => {
      return 'Please enter an amount between $' + min + ' and $' + max + '.';
    }
  },
  {
    name: 'IsCurrency',
    isValid: (v: string, min?: number, max?: number, balance?: number) => {
      v = v.replace(/,/g, '');
      const currencyRegex = /^\$?[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;
      if (!currencyRegex.test(v)) {
        return false;
      }
      return true;
    },
    errorMessage: (min?: number, max?: number, balance?: number) => {
      return 'Please enter an amount between $' + min + ' and $' + max + '.';
    }
  },
  {
    name: 'IsWithinMinMax',
    isValid: (v: string, min?: number, max?: number, balance?: number) => {
      const vFloat = floatFrom(v);
      const hasMaxMin = max !== undefined && min !== undefined;
      const isLessThanMin = hasMaxMin && vFloat < min;
      const isGreaterThanMax = hasMaxMin && vFloat > max;
      if (isLessThanMin || isGreaterThanMax) {
        return false;
      }
      return true;
    },
    errorMessage: (min?: number, max?: number, balance?: number) => {
      return 'Please enter an amount between $' + min + ' and $' + max + '.';
    }
  },
  {
    name: 'IsWithinBalance',
    isValid: (v: string, min?: number, max?: number, balance?: number) => {
      const vFloat = floatFrom(v);
      const hasBalance = balance !== undefined;
      const isGreaterThanBalance = hasBalance && vFloat > balance;
      if (isGreaterThanBalance) {
        return false;
      }
      return true;
    },
    errorMessage: (min?: number, max?: number, balance?: number) => {
      return 'Your line amount may not exceed the available balance.';
    }
  }
);
