import { observable } from 'mobx';

export interface IAnnualPercentRate {
  annualPercentRateTotal: number;
  annualPercentRatePrime: number;
  annualPercentRateExtra: number;
  annualPercentRateFixed: number;
  annualPercentRateCalculated: number;
}

export interface IOfferDetails extends IAnnualPercentRate {
  productAlias: string;
  customerFirstName: string;
  loanMax: number;
  loanMin: number;
  fundsRemaining: number;
  hasDocStampFee: boolean;
  hasTransferAccounts: boolean;
  monthlyPaymentEstimate: number;
  annualFeeInitial: number;
  annualFee: number;
  paymentAmountPercent: number;
  paymentAmountMin: number;
  cashAdvance: number;
  lateFee: number;
  selectedLoanAmount: number;
  calculatedterms: number;
  calculatedDocStampFee: number;
  totalAmountFinanced: number;
  transferType: string;
  transferAmount: number;
  collateralAccount: string;
  transferAccount: string;
  overdraftAccount: string;
  processingFee: number;
  purposeType: string;
  rateData?: IRate[];
  termData?: ITerm[];
  feeData?: IFee[];
  repayData?: IRepay[];
}

export interface IRate {
  minInclusive: number;
  maxInclusive: number;
  rate: number;
  indicator: number;
}
export interface ITerm {
  minInclusive: number;
  maxInclusive: number;
  term: number;
}

export interface IFee {
  minInclusive: number;
  maxInclusive: number;
  fee: number;
}

export interface IRepay {
  minInclusive: number;
  maxInclusive: number;
  paymentAmountPercent: number;
  paymentAmountMin: number;
}

const Offers: IOfferDetails[] = observable([]);

export default Offers;
