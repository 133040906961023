import { observable } from 'mobx';

export interface ISessionMeta {
  ApplicationId?: number;
  ExternalId?: string;
  Hash?: string;
  ProductAlias?: string;
  CustomerType?: string;
}

export interface IMeta {
  productAlias?: string;
  applicationId?: number;
  externalId?: string;
  hash?: string;
  fields?: any[];
  veridQuestions?: any[];
  identityVerificationQuestions?: any[];
  errors?: IErrorsSchema[];
  sessionMeta?: ISessionMeta;
  hasFatalError?: IErrorsSchema | boolean | undefined;
  customerType?: 'primary' | 'joint';
  isHostAndOLBExistingUser?: boolean | undefined;
    isHostButNoOLBExistingUser?: boolean | undefined;
    loginSSOToken?: string;
}

export interface IErrorsSchema {
  message: string;
  name: string;
  ruleAlias: string;
  tag?: any;
  type: string;
}

const Meta: IMeta = observable({
  productAlias: '',
  applicationId: 0,
  externalId: '',
  hash: '',
  fields: [],
  veridQuestions: [],
  identityVerificationQuestions: [],
  errors: [],
  loginSSOToken: undefined,
  customerType: 'primary',
  get sessionMeta() {
    return {
      ApplicationId: this.applicationId,
      ExternalId: this.externalId,
      Hash: this.hash,
      ProductAlias: this.productAlias,
      CustomerType: this.customerType,
    };
  },
  get hasFatalError(): IErrorsSchema | boolean | undefined {
    if (this.errors.length > 0) {
      const fatalError: IErrorsSchema | undefined = this.errors.find((e: IErrorsSchema) => e.type === 'FATAL');
      if (fatalError !== null) {
        return fatalError;
      }
    }
    return false;
  },
});

export default Meta;
