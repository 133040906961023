import React from 'react';

const PersonIcon = () => (
  <svg width='48px' height='48px' viewBox='0 0 48 48' version='1.1' data-testid='Person_Icon'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        d='M24,3 C12.9554599,3 4,11.9537029 4,22.9991215 C4,34.0445401 12.9545814,42.998243 24,42.998243 C35.0462971,42.998243 44,34.0445401 44,22.9991215 C44,11.9537029 35.0462971,3 24,3 Z M24,8.97997013 C27.6545726,8.97997013 30.6160063,11.9422824 30.6160063,15.595098 C30.6160063,19.2487921 27.6545726,22.2102258 24,22.2102258 C20.3471844,22.2102258 17.3857507,19.2487921 17.3857507,15.595098 C17.3857507,11.9422824 20.3471844,8.97997013 24,8.97997013 Z M23.9956075,37.769393 C20.3506984,37.769393 17.0123869,36.4419749 14.4374945,34.2448388 C13.8102433,33.7098304 13.4483001,32.9253272 13.4483001,32.1021699 C13.4483001,28.3975226 16.4466309,25.4325749 20.1521567,25.4325749 L27.8496003,25.4325749 C31.5560046,25.4325749 34.5429149,28.3975226 34.5429149,32.1021699 C34.5429149,32.9262057 34.1827286,33.7089519 33.554599,34.2439603 C30.9805851,36.4419749 27.6413951,37.769393 23.9956075,37.769393 Z'
        fill='#5E9C11'
        fillRule='nonzero'
      />
    </g>
  </svg>
);

export default PersonIcon;
