import { observable, action, set, computed } from 'mobx';
import { saveAs } from 'file-saver';
import { navigate } from '@reach/router';
import queryString from 'query-string';
import { get, pick } from 'lodash-es';
import ApplicantInfo, { IApplicantInfo } from './Models/ApplicantInfo';
import Lookups, { ILookups } from './Models/Lookups';
import Meta, { IMeta, ISessionMeta } from './Models/Meta';
import UI, { IUI } from './Models/UI';
import {
  ProductAliases,
  GetStartedAPIRoutes,
  PageRoutes,
  TransmitAPIRoutes,
  AuthenticationAPIRoutes,
} from '../../shared/constants';
import { lazyLoad, generateCodeChallenge, generateCodeVerifier } from '../../shared/services';
import { baseUrl } from '../../shared/server';
import Auth, { IAuth } from './Models/Auth';
import FeatureSwitch, { IFeatureSwitch } from './Models/FeatureSwitch';
import Disclosures, { IDisclosures } from './Models/Disclosures';
import ApplicationSubmit, { IApplicationSubmit } from './Models/ApplicationSubmit';
import StepUp, { IStepUp } from './Models/StepUp';
import Offers, { IFee, IOfferDetails } from './Models/Offers';
import { IAccountOptions } from './Models/AccountOptions';
import LoanPurposes from './Models/LoanPurposes';
import LivePersonChatInfo, { ILivePersonChatInfo } from './Models/LivePersonChatInfo';

export interface IPostRequestSchema {
  Fields: any[] | null;
  SessionMeta: ISessionMeta;
}

class ApplicationStore {
  @observable lookups = Lookups;

  @observable applicantInfo = ApplicantInfo;

  @observable meta = Meta;

  @observable disclosures = Disclosures;

  @observable auth = Auth;

  @observable ui = UI;

  @observable responseInterceptor = null;

  @observable featureSwitch = FeatureSwitch;

  @observable applicationSubmit = ApplicationSubmit;

  @observable stepUp = StepUp;

  @observable lpChatInfo = LivePersonChatInfo;

  @observable offers = Offers;

  @observable collateralOptions: IAccountOptions = observable({
    options: [],
    selectedOption: { label: '', value: '' },
  });

  @observable overdraftOptions: IAccountOptions = observable({
    options: [],
    selectedOption: { label: '', value: '' },
  });

  @observable transferOptions: IAccountOptions = observable({
    options: [],
    selectedOption: { label: '', value: '' },
  });

  @observable selectedOffer: IOfferDetails = observable({
    productAlias: '',
    customerFirstName: '',
    loanMax: 0,
    loanMin: 0,
    fundsRemaining: 0,
    hasDocStampFee: false,
    hasTransferAccounts: false,
    monthlyPaymentEstimate: 0,
    annualFeeInitial: 0,
    annualFee: 50,
    annualPercentRateTotal: 0,
    annualPercentRatePrime: 0,
    annualPercentRateExtra: 0,
    annualPercentRateFixed: 0,
    annualPercentRateCalculated: 0,
    paymentAmountPercent: 0,
    paymentAmountMin: 0,
    cashAdvance: 0,
    lateFee: 0,
    selectedLoanAmount: 0,
    calculatedterms: 0,
    calculatedDocStampFee: 0,
    totalAmountFinanced: 0,
    transferType: 'fullAmount',
    transferAmount: 0,
    collateralAccount: '',
    transferAccount: '',
    overdraftAccount: '',
    processingFee: 0,
    purposeType: '',
  });

  @observable loanPurposes = LoanPurposes;

  private api: any;

  constructor(api: any) {
    this.api = api;
    // this.initApiAndLoadApplication();
  }

  @action('ApplicationStore | isOfferUnsecured')
  isOfferUnsecured = (): boolean => {
    return (
      this.selectedOffer?.productAlias === ProductAliases.UnsecuredLoan ||
      this.selectedOffer?.productAlias === ProductAliases.PreferredCreditLine
    );
  };

  @action('ApplicationStore | isOfferSecured')
  isOfferSecured = (): boolean => {
    return (
      this.selectedOffer?.productAlias === ProductAliases.DepositSecuredLoan ||
      this.selectedOffer?.productAlias === ProductAliases.SavingsSecuredCreditLine
    );
  };

  @action('ApplicationStore | isOfferPLOC')
  isOfferPLOC = (): boolean => {
    return this.selectedOffer?.productAlias === ProductAliases.OverdraftProtectionCreditLine;
  };

  @action('ApplicationStore | isOfferLoan')
  isOfferLoan = (): boolean => {
    return (
      this.selectedOffer?.productAlias === ProductAliases.UnsecuredLoan ||
      this.selectedOffer?.productAlias === ProductAliases.DepositSecuredLoan
    );
  };

  @action('ApplicationStore | isOfferCreditLine')
  isOfferCreditLine = (): boolean => {
    return (
      this.selectedOffer?.productAlias === ProductAliases.PreferredCreditLine ||
      this.selectedOffer?.productAlias === ProductAliases.SavingsSecuredCreditLine
      //this.selectedOffer?.productAlias === ProductAliases.OverdraftProtectionCreditLine
    );
  };

  @action('ApplicationStore | calculateProcessingFee')
  calculateProcessingFee = (): void => {
    if (!this.selectedOffer.feeData) {
      return;
    }

    let calculatedProcessingFee = this.selectedOffer.feeData.find(
      (r: IFee) =>
        r.minInclusive <= this.selectedOffer.selectedLoanAmount &&
        r.maxInclusive >= this.selectedOffer.selectedLoanAmount
    )?.fee;

    if (calculatedProcessingFee) {
      this.selectedOffer.processingFee = calculatedProcessingFee;
    }
  };

  @action('ApplicationStore | setUI')
  setUI = (state: IUI): IUI => {
    set(this.ui, state);
    return this.ui;
  };

  @action('ApplicationStore | setMeta')
  setMeta = (state: IMeta): IMeta => {
    set(this.meta, state);
    return this.meta;
  };

  @action('ApplicationStore | setDisclosures')
  setDisclosures = (state: IDisclosures): IDisclosures => {
    set(this.disclosures, state);
    return this.disclosures;
  };

  @action('ApplicationStore | setLookups')
  setLookups = (state: ILookups): ILookups => {
    set(this.lookups, state);
    return this.lookups;
  };

  @action('ApplicationStore | setApplicantInfo')
  setApplicantInfo = (state: IApplicantInfo): IApplicantInfo => {
    set(this.applicantInfo, state);
    return this.applicantInfo;
  };

  @action('ApplicationStore | setAuth')
  setAuth = (state: IAuth): IAuth => {
    set(this.auth, state);
    return this.auth;
  };

  @action('ApplicationStore | updateResponseInterceptor')
  updateResponseInterceptor = (data: any): any => {
    this.responseInterceptor = data;
    return this.responseInterceptor;
  };

  // FeatureSwitch
  @action('ApplicationStore | setFeatureSwitch')
  setFeatureSwitch = (state: IFeatureSwitch): IFeatureSwitch => {
    set(this.featureSwitch, state);
    return this.featureSwitch;
  };

  // Live Person Chat Info
  @action('ApplicationStore | setLivePersonChatInfo')
  setLivePersonChatInfo = (state: ILivePersonChatInfo): ILivePersonChatInfo => {
    set(this.lpChatInfo, state);
    return this.lpChatInfo;
  };

  //Application Submit
  @action('ApplicationStore | setApplicationSubmit')
  setApplicationSubmit = (state: IApplicationSubmit): IApplicationSubmit => {
    set(this.applicationSubmit, state);
    return this.applicationSubmit;
  };

  // StepUp
  @action('ApplicationStore | setStepUp')
  setStepUp = (state: IStepUp): IStepUp => {
    set(this.stepUp, state);
    return this.stepUp;
  };

  // Offers
  @action('ApplicationStore | setOffers')
  setOffers = (state: IOfferDetails[]): IOfferDetails[] => {
    set(this.offers, state);
    return this.offers;
  };

  // SelectedOffers
  @action('ApplicationStore | setSelectedOffer')
  setSelectedOffer = (state: IOfferDetails): IOfferDetails => {
    set(this.selectedOffer, state);
    return this.selectedOffer;
  };

  // CollateralOptions
  @action('ApplicationStore | setCollateralOptions')
  setCollateralOptions = (state: IAccountOptions): void => {
    set(this.collateralOptions, state);
  };

  // OverdraftOptions
  @action('ApplicationStore | setOverdraftOptions')
  setOverdraftOptions = (state: IAccountOptions): void => {
    set(this.overdraftOptions, state);
  };

  // TransferOptions
  @action('ApplicationStore | setTransferOptions')
  setTransferOptions = (state: IAccountOptions): void => {
    set(this.transferOptions, state);
  };

  // LoanPurpose
  @action('ApplicationStore | setPurposeOptions')
  setPurposeOptions = (state: any): any => {
    set(this.loanPurposes, state);
    return this.loanPurposes;
  };

  @computed get requestData() {
    return {
      Fields: null,
      SessionMeta: this.meta.sessionMeta,
    };
  }

  initApiAndLoadApplication = async (): Promise<void> => {
    this.setApiDefaults();
    this.setDefaultProduct();
    await this.fetchToken();
    //ensighten(this.marketingData);
  };

  setApiDefaults = (): void => {
    try {
      this.api.defaults.baseURL = baseUrl();
      this.api.defaults.headers.common['Content-Type'] = 'application/json';
      this.api.defaults.headers.common['X-XSS-Protection'] = '1; mode=block';
      this.api.defaults.headers.common['X-Content-Type-Options'] = 'nosniff';
      this.api.defaults.headers.common['Strict-Transport-Security'] = 'max-age=31536000; includeSubDomains';
      this.api.interceptors.request.use(this.addDefaultDataToRequest);
      this.api.interceptors.response.use(this.updateMetaDataFromResponse);
      this.updateResponseInterceptor(this.responseInterceptor);
    } catch (error) {
      this.handleFatalErrors(error);
    }
  };

  setDefaultProduct = (): void => {
    // Temporary code to set product aliases arbitrarily
    const parsedUrl = queryString.parse(window.location.search);
    this.setMeta({ productAlias: ProductAliases.DigitalLoan });
    if (Object.prototype.hasOwnProperty.call(parsedUrl, 'token')) {
      this.setMeta({ loginSSOToken: parsedUrl.token === null ? undefined : parsedUrl.token.toString() });
    }
  };

  updateMetaDataFromResponse = (response: any): any => {
    const sessionMetaFields = pick(response.data, Object.keys(this.meta));
    this.setMeta(sessionMetaFields);

    if (sessionMetaFields.errors && sessionMetaFields.errors.length > 0) {
      if (this.meta.hasFatalError) {
        this.handleFatalErrors(response);
      }
    }

    return response;
  };

  addDefaultDataToRequest = (config: any): any => {
    if (config.data === undefined) {
      return config;
    }

    let data;
    try {
      data = {
        ...this.requestData,
        ...JSON.parse(get(config, 'data', '{}')),
      };
    } catch (_) {
      data = {
        ...this.requestData,
        ...get(config, 'data', {}),
      };
    }

    return {
      ...config,
      ...{ data },
    };
  };

  initEnsighten = async (): Promise<void> => {
    try {
      const ensightenEnv =
        window.location.hostname.indexOf('.regions.com') > -1 ? 'origination-loans-prod' : 'origination-loans-pre-prod';
      const loadEnsightenScript = {
        url: `https://nexus.ensighten.com/regions/${ensightenEnv}/Bootstrap.js`,
        type: 'script',
      };
      if (process.env.REACT_APP_DISABLE_ENSIGHTEN !== 'true') {
        await lazyLoad(loadEnsightenScript);
      }
    } catch (error) {
      console.log(`Application Store | Failed loading Ensighten script. Details: ${error}`);
    }
  };

  fetchToken = async (): Promise<void> => {
    try {
      const createTokenResponse = await this.api.get(GetStartedAPIRoutes.FetchTokenRoute);
      const { data } = createTokenResponse;
      const { token } = data.auth_token;
      this.api.defaults.headers.common.Authorization = `Bearer ${token}`;
      this.setAuth({ token });
      this.setMeta(this.meta);
    } catch (error) {
      this.handleFatalErrors(error);
    }
  };

  checkForMaintenance = async (): Promise<void> => {
    const response = await this.api({
      url: GetStartedAPIRoutes.MaintenanceCheck,
      method: 'get',
    });

    if (response?.data?.IsSatisfied) return;

    // Throw app into maintenance.
    window.location.href = 'app_offline.htm';
    throw new Error('app is in maintenance');
  };

  postRequestBuilder = (Fields = null): IPostRequestSchema => {
    return {
      Fields,
      SessionMeta: {
        ApplicationId: this.meta.applicationId,
        ExternalId: this.meta.externalId,
        Hash: this.meta.hash,
        ProductAlias: this.meta.productAlias,
        CustomerType: 'primary', // DLO is always primary customer, at the moment
      },
    };
  };

  initThreatMetrix = async (externalId: string): Promise<void> => {
    try {
      const tmxEnv = window.location.hostname.indexOf('.regions.com');

      if (!externalId) {
        return;
      }
      let tmxUrl = `https://tm.regionstest.com/tags.js?org_id=2b7lm4at&session_id=${this.meta.externalId}&pageid=0`;

      if (tmxEnv > -1) {
        tmxUrl = `https://tm.regions.com/tags.js?org_id=3uc6h1j9&session_id=${this.meta.externalId}&pageid=0`;
      }

      const tmx = {
        url: tmxUrl,
        type: 'script',
        htmlElementType: 'head',
      };

      await lazyLoad(tmx);
    } catch (error) {
      this.handleFatalErrors(error);
    }
  };

  handleFatalErrors = (err: any): void => {
    if (err && err.response && err.response.status === 503) {
      navigate(PageRoutes.Error503PageRoute);
      return;
    }

    this.setMeta({
      errors: [
        // @ts-ignore
        ...this.meta.errors.flat(),
        // @ts-ignore
        {
          ruleAlias: 'GenericError',
          name: 'GenericError',
          type: 'FATAL',
          message: 'Unable to proceed',
        },
      ],
    });
    navigate(PageRoutes.GenericErrorPageRoute);
  };

  getAuthenticationFeatureSwitches = async () => {
    try {
      await this.setApiDefaults();
      await this.fetchToken();
      if (this.auth.token && this.auth.token.length > 0) {
        const featureSwitchResponse = await this.api.get(AuthenticationAPIRoutes.AuthenticationFeatureSwitchGet);
        if (featureSwitchResponse && featureSwitchResponse.data) {
          const fields = featureSwitchResponse.data;
          this.setFeatureSwitch({ ...fields });
        }
      }
    } catch (error) {
      console.log('ApplicationStore | Failed to fetch Authentication API feature switches', error);
      this.handleFatalErrors(error);
      this.setUI({ isPageLoading: false });
    }
  };

  redirectAndFetchAuthorizationCode = async () => {
    await this.getAuthenticationFeatureSwitches();

    let authUrl = TransmitAPIRoutes.tsAuthProd;
    if (/dlo-dev/g.test(window.location.hostname) || /localhost/g.test(window.location.hostname)) {
      authUrl = TransmitAPIRoutes.tsAuthDev;
    } else if (/dlo-qa/g.test(window.location.hostname)) {
      authUrl = TransmitAPIRoutes.tsAuthQA;
    } else if (/dlo-test/g.test(window.location.hostname)) {
      authUrl = TransmitAPIRoutes.tsAuthTest;
    }

    const clientId = this.featureSwitch.transmitOAuthClientId;
    const redirectUri = `${window.location.origin}?auth=enterprise`;
    const scopes = 'openid rcifid';
    const codeVerifier = generateCodeVerifier();
    // @ts-ignore
    window.sessionStorage.setItem('cv', codeVerifier);
    const pkceCodeChallenge = await generateCodeChallenge(codeVerifier);

    // @ts-ignore
    const destUrl = new URL(authUrl, window.location);
    destUrl.searchParams.append('response_type', 'code');
    // @ts-ignore
    destUrl.searchParams.append('client_id', clientId);
    destUrl.searchParams.append('redirect_uri', redirectUri);
    destUrl.searchParams.append('scope', scopes);
    destUrl.searchParams.append('state', '');
    destUrl.searchParams.append('prompt', 'none');
    destUrl.searchParams.append('code_challenge_method', 'S256');
    // @ts-ignore
    destUrl.searchParams.append('code_challenge', pkceCodeChallenge);

    window.location.assign(destUrl.href);
  };

  downloadFile = async (
    route: string,
    token: string,
    mimeType = 'application/pdf',
    filename: string,
    additionalOptions = { inlineDownload: false }
  ): Promise<string | Blob> => {
    let modifiedApi = route.substring(route.indexOf('api/'), route.length);
    if (additionalOptions.inlineDownload) {
      modifiedApi = modifiedApi.replace(/pdf/gi, 'html');
    }
    const response = await this.api({
      url: modifiedApi,
      method: 'GET',
      responseType: mimeType === 'application/pdf' ? 'arraybuffer' : 'document',
      headers: {
        'Content-Type': mimeType,
        Accept: mimeType,
      },
    });
    const { status, data } = response;
    if (status >= 200 || status < 300) {
      if (additionalOptions.inlineDownload) {
        return new XMLSerializer().serializeToString(data);
      }
      const blob = new Blob([data], { type: 'application/pdf' });
      // For IE only
      // @ts-ignore
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        if (filename !== undefined) {
          saveAs(blob, `${filename}.pdf`);
        } else {
          saveAs(blob, 'acctdisclosures.pdf');
        }
        return blob;
      }
      const uri = window.URL.createObjectURL(blob);
      // Open the URL on new window
      window.open(uri);
      return uri;
    }
    return new Blob([''], { type: 'text/plain' });
  };

  @computed get marketingData() {
    return {
      productAlias: this.meta.productAlias,
      meta: this.meta,
      isExistingUser: this.auth.isExistingUser,
      zip: this.applicantInfo.zip,
    };
  }
}

export default ApplicationStore;
