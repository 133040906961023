import React from 'react';

const WarningIcon = (): JSX.Element => (
  <svg
    width='40px'
    height='40px'
    viewBox='0 0 40 40'
    version='1.1'
    aria-hidden='true'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-167.000000, -85.000000)' fill='#5E9C11'>
        <g transform='translate(16.000000, 11.000000)'>
          <g transform='translate(151.000000, 74.000000)'>
            <path
              d='M20,0 C31.045695,0 40,8.954305 40,20 C40,31.045695 31.045695,40 20,40 C8.954305,40 0,31.045695 0,20 C0,8.954305 8.954305,0 20,0 Z M20,24.1236356 C18.8950694,24.1236356 18,25.0304823 18,26.1419968 C18,27.2535113 18.8950694,28.160358 20,28.160358 C21.1049306,28.160358 22,27.2535113 22,26.1419968 C22,25.0304823 21.1049306,24.1236356 20,24.1236356 Z M20,11 C19.0429238,11 18.3129188,11.8735879 18.3129188,12.9000014 L18.3129188,12.9000014 L18.3129188,19.3772516 C18.3129188,20.403665 19.0429238,21.2772529 20,21.2772529 C20.9570762,21.2772529 21.6870812,20.403665 21.6870812,19.3772516 L21.6870812,19.3772516 L21.6870812,12.9000014 C21.6870812,11.8735879 20.9570762,11 20,11 Z'
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default WarningIcon;
