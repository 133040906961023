import { css, keyframes } from '@emotion/core';
import { breakpoints } from '../index';

const patterns = {
  // Screen Styles
  spin: keyframes`
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  `,

  fadeIn: keyframes`
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  `,

  slideInFromRight: keyframes`
    from {
      transform: translateX(30%);
    }
    to {
      transform: translateX(0);
    }
  `,

  slideInFromBottom: keyframes`
    from {
      transform: translateY(30%);
    }
    to {
      transform: translateY(0);
    }
  `,

  slideInFromTop: keyframes`
    from {
      transform: translateY(-30%);
    }
    to {
      transform: translateY(0);
    }
  `,

  backdropFadeIn: keyframes`
    from {
      background: transparent;
    }
  `,

  modalEntryAnimation: keyframes`
    from {
      transform: scale(0.9) translateY(-10%);
    }
  `,

  modalExitAnimation: keyframes`
    to {
      opacity: 0;
      transform: scale(0);
    }
  `,

  flexDesktop: css`
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  `,
  noWrap: css`
    white-space: nowrap;
  `,
  textStyleBody2: css`
    letter-spacing: -0.0008em;
    line-height: 1.5;
    font-size: 1rem;
    @media (${breakpoints.smMinMax}) {
      font-size: 1.0625rem;
    }
    @media (${breakpoints.mdMin}) {
      font-size: 1.125rem;
    }
  `,
};

export default patterns;
