import { navigate } from '@reach/router';
import { AccountDetailsAPIRoutes, PageRoutes } from '../../../../shared/constants';
import { currencyFormatter } from '../../../services';

export default class {
    api: any;

    private applicationStore: any;

    constructor(api: any, applicationStore: any) {
        this.api = api;
        this.applicationStore = applicationStore;
    }

    callSelectAccountInitiate = async (
        accountUsage: 'transfer' | 'collateral' | 'overdraft',
        setAccountOptions: any,
        singleAccountRedirect?: string
    ): Promise<any> => {
        const { setMeta, handleFatalErrors, postRequestBuilder } = this.applicationStore;

        try {
            let mappedAccounts: any[] = [];
            const callSelectAccountInitiateResponse = await this.api({
                url: AccountDetailsAPIRoutes.accountDetailsInitiate,
                method: 'post',
                data: postRequestBuilder([
                    {
                        Alias: accountUsage,
                    },
                ]),
            });

            const { errors, fields } = callSelectAccountInitiateResponse.data;

            if (errors && errors.length > 0) {
                setMeta({ errors });

                let fatalError = errors.find((e: { type: string }) => e.type === 'FATAL');

                if (fatalError) {
                    navigate(PageRoutes.GenericErrorPageRoute);
                    return mappedAccounts;
                }
            }

            if (fields?.length && fields.length > 0) {
                mappedAccounts = [{ label: 'Choose an account', value: '' }].concat(fields).map((a: any) => {
                    return !a.accountName
                        ? { label: 'Choose an account', value: '' }
                        : accountUsage == 'collateral' ? { label: `${a.accountName} *${a.accountNo} ${currencyFormatter(a.balance)}`, value: a.accountId, balance: a.balance }
                            : { label: `${a.accountName} *${a.accountNo}`, value: a.accountId, balance: a.balance };
                });
                setAccountOptions({ options: mappedAccounts });
            } else {
                // TODO: create error for setMeta
                navigate(PageRoutes.GenericErrorPageRoute);
            }

            if (singleAccountRedirect && fields?.length && fields.length === 1) {
                navigate(singleAccountRedirect);
            }

            return mappedAccounts;
        } catch (e) {
            console.log('SelectAccountStore | Failed on callSelectAccountInitiate. Details: ', e);
            handleFatalErrors(e);

            return [];
        }
    };
}
