const colors = {
  // Primary colors
  regionsGreen: '#417514',
  darkenedRegionsGreen: '#1d4900',
  regionsTeal: '#007C8C',
  darkenedRegionsTeal: '#004964',
  focusRegionsTeal: '#289b9f',
  gray: '#707070',
  gray8C: '#8C8C8C',
  textBlack: '#111',
  white: '#fff',

  // Secondary colors
  lifeGreen: '#5E9C11',
  red: '#fa0000',
  darkRed: '#BE1E18',

  // Tertiary colors
  yellow: '#F7B500',
  teal: '#4ED17E',
  blue: '#00B8FF',
  envRed: '#D85140',

  // Other util colors
  background: '#F8F8F8',
  border: '#EFEFEF',
  borderTable: '#DCDCDC',
  inputBorder: '#ccc',
  tableRowDarker: '#EEE',
  disabledButtonBackground: '#E8E8E8',
  disabledButtonLabel: '#474747',
  inactiveToggleBackground: '#D1D1D1',
  grayLighter: '#eee',
  black: 'black',
  grayDarker: '#333',
};

export default colors;
