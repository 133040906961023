import React, { useEffect, useState } from 'react';
import { TextInput } from '@ux/balance-react';
import { IValidation } from '../../services/validations/validations';

export const floatFrom = (usd: string): number => parseFloat(usd.replace(/,/g, '').replace(/\$/g, ''));

function formatAmount(v: string) {
  const vInt = Math.round(floatFrom(v));

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(vInt);
}

export default (props: {
  amount: number;
  validations: Array<IValidation>;
  id: string;
  isRequired?: boolean;
  isFormValid?: boolean;
  label: string;
  max?: number;
  min?: number;
  balance?: number;
  onChange(value: any): void;
  setIsFormValid?(isValid: boolean): void;
}): JSX.Element => {
  const { amount, validations, id, isRequired, isFormValid, label, max, min, balance, onChange, setIsFormValid } =
    props;

  const [isFieldValid, setIsFieldValid] = useState<boolean>(true);
  const [displayedErrorMessage, setDisplayedErrorMessage] = useState<Array<string>>([]);

  const validateAmount = (v: string, setValidationState: Function): boolean => {
    if (displayedErrorMessage == null) {
      return false;
    }
    for (let i = 0; i < validations.length; i += 1) {
      if (!validations[i].isValid(v, min, max, balance)) {
        setValidationState(false);
        setDisplayedErrorMessage([validations[i].errorMessage(min, max, balance)]);
        return false;
      }
    }
    setValidationState(true);
    setDisplayedErrorMessage([]);
    return true;
  };

  if (isFormValid !== undefined && setIsFormValid !== undefined) {
    useEffect(() => {
      setIsFormValid(isFieldValid);
    }, [isFieldValid]);
  }
  return (
    <TextInput
      autoFormat='custom'
      autoFormatOnFill
      handleCustomAutoFormat={(v: string) => {
        if (!validateAmount(v, setIsFieldValid)) return v;

        return formatAmount(v);
      }}
      handleOnChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (validateAmount(event.target.value, setIsFieldValid)) onChange(floatFrom(event.target.value));
      }}
      handleOnChangeValidation={(v: string) => validateAmount(v, setIsFieldValid)}
      inputId={id}
      initialValue={formatAmount(amount.toString())}
      label={label}
      size='small'
      inputFieldButtonTestId={`input-field-input-label-button-${id}`}
      inputMode='numeric'
      isRequired={isRequired}
      isValid={isFieldValid}
      errorMessages={displayedErrorMessage}
    />
  );
};
