const totalAmountFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const wholeDollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const currencyFormatter = (value: number, formatAsWholeNumber: boolean = false) => {

  if (formatAsWholeNumber) {
    return wholeDollarFormatter.format(value);
  }

  return totalAmountFormatter.format(value);

};

export default currencyFormatter;