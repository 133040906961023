import React from 'react';

const PercentIconGreen = (): JSX.Element => (
  <svg
    width='12px'
    height='13px'
    viewBox='0 0 12 13'
    version='1.1'
    aria-hidden='true'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        transform='translate(-34.000000, -587.000000)'
        fill='#5E9C11'
        fillRule='nonzero'
      >
        <g transform='translate(16.000000, 380.000000)'>
          <g transform='translate(18.000000, 200.000000)'>
            <g transform='translate(0.000000, 7.750000)'>
              <path
                d='M2.4375,4.875 C1.7625,4.875 1.1875,4.6375 0.7125,4.1625 C0.2375,3.6875 0,3.1125 0,2.4375 C0,1.7625 0.2375,1.1875 0.7125,0.7125 C1.1875,0.2375 1.7625,0 2.4375,0 C3.1125,0 3.6875,0.2375 4.1625,0.7125 C4.6375,1.1875 4.875,1.7625 4.875,2.4375 C4.875,3.1125 4.6375,3.6875 4.1625,4.1625 C3.6875,4.6375 3.1125,4.875 2.4375,4.875 Z M2.4375,3.75 C2.8,3.75 3.109375,3.621875 3.365625,3.365625 C3.621875,3.109375 3.75,2.8 3.75,2.4375 C3.75,2.075 3.621875,1.765625 3.365625,1.509375 C3.109375,1.253125 2.8,1.125 2.4375,1.125 C2.075,1.125 1.765625,1.253125 1.509375,1.509375 C1.253125,1.765625 1.125,2.075 1.125,2.4375 C1.125,2.8 1.253125,3.109375 1.509375,3.365625 C1.765625,3.621875 2.075,3.75 2.4375,3.75 Z M9.5625,12 C8.8875,12 8.3125,11.7625 7.8375,11.2875 C7.3625,10.8125 7.125,10.2375 7.125,9.5625 C7.125,8.8875 7.3625,8.3125 7.8375,7.8375 C8.3125,7.3625 8.8875,7.125 9.5625,7.125 C10.2375,7.125 10.8125,7.3625 11.2875,7.8375 C11.7625,8.3125 12,8.8875 12,9.5625 C12,10.2375 11.7625,10.8125 11.2875,11.2875 C10.8125,11.7625 10.2375,12 9.5625,12 Z M9.5625,10.875 C9.925,10.875 10.234375,10.746875 10.490625,10.490625 C10.746875,10.234375 10.875,9.925 10.875,9.5625 C10.875,9.2 10.746875,8.890625 10.490625,8.634375 C10.234375,8.378125 9.925,8.25 9.5625,8.25 C9.2,8.25 8.890625,8.378125 8.634375,8.634375 C8.378125,8.890625 8.25,9.2 8.25,9.5625 C8.25,9.925 8.378125,10.234375 8.634375,10.490625 C8.890625,10.746875 9.2,10.875 9.5625,10.875 Z M0.7875,12 L0,11.2125 L11.2125,0 L12,0.7875 L0.7875,12 Z'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default PercentIconGreen;
