import React from 'react';
import axios, { AxiosStatic } from 'axios';
import ApplicationStore from '../../Application/store';
import GetStartedStore from '../../views/GetStarted/store';
import SelectLoanAmountStore from '../../views/SelectLoanAmount/store';
import SelectCreditLimitStore from '../../views/SelectCreditLimit/store';
import SelectAccountStore from '../../shared/components/SelectAccount/store';
import SelectLoanPurposeStore from '../../views/SelectLoanPurpose/store';
import ReviewOfferStore from '../../views/ReviewOffer/store';
import AccountAgreementsStore from '../../views/AccountAgreements/store';
import CongratulationsStore from '../../views/Congratulations/store';
import LivePersonChatStore from '../../views/LivePersonChat/store';
import DeclinationStore from '../../views/Error/OdpDeclination/store';

export interface IStoresContext {
  api?: AxiosStatic | any;
  applicationStore?: ApplicationStore;
  getStartedStore?: GetStartedStore;
  selectLoanAmountStore?: SelectLoanAmountStore;
  selectLoanPurposeStore?: SelectLoanPurposeStore;
  selectCreditLimitStore?: SelectCreditLimitStore;
  selectAccountStore?: SelectAccountStore;
  reviewOfferStore?: ReviewOfferStore;
  accountAgreementsStore?: AccountAgreementsStore;
  congratulationsStore?: CongratulationsStore;
  livePersonChatStore?: LivePersonChatStore;
  declinationStore?: DeclinationStore;
}

const applicationStore = new ApplicationStore(axios);
const getStartedStore = new GetStartedStore(axios, applicationStore);
const selectLoanAmountStore = new SelectLoanAmountStore(axios, applicationStore);
const selectLoanPurposeStore = new SelectLoanPurposeStore(axios, applicationStore);
const selectCreditLimitStore = new SelectCreditLimitStore(axios, applicationStore);
const selectAccountStore = new SelectAccountStore(axios, applicationStore);
const reviewOfferStore = new ReviewOfferStore(axios, applicationStore);
const accountAgreementsStore = new AccountAgreementsStore(axios, applicationStore);
const congratulationsStore = new CongratulationsStore(axios, applicationStore);
const livePersonChatStore = new LivePersonChatStore(axios, applicationStore);
const declinationStore = new DeclinationStore(axios, applicationStore);

const storesContext = React.createContext<IStoresContext>({
  api: axios,
  applicationStore,
  getStartedStore,
  selectLoanAmountStore,
  selectLoanPurposeStore,
  selectCreditLimitStore,
  selectAccountStore,
  reviewOfferStore,
  accountAgreementsStore,
  congratulationsStore,
  livePersonChatStore,
  declinationStore,
});

export default storesContext;
