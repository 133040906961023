import styled from '@emotion/styled';

const StyledLayout = styled.div`
  .loader {
    position: fixed;
    z-index: 99;
  }
`;

export default StyledLayout;
