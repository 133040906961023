import styled from '@emotion/styled';
import { typography } from '../../../styles';

const StyledRangeSlider = styled.div`
  .slider {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .slider-bg {
    width: 100%;
    overflow: hidden;
  }

  .slider-bg div {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150%;
  }

  .slider-bg,
  .slider-bg div {
    height: 1.25rem;
    position: absolute;
    z-index: -1;
  }

  .slider-legend {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: ${typography.smallFontSize};
    margin-top: 1rem;
  }

  .slider-legend .left,
  .slider-legend .right {
    flex: 1;
  }

  .slider-legend .left {
    text-align: left;
  }

  .slider-legend .right {
    text-align: right;
  }

  /* BEGIN CROSS-BROWSER SLIDER-RESET */
  .slider-input {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }
  .slider-input::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  .slider-input::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* BEGIN CROSS-BROWSER SLIDER STYLING */
  .slider-input {
    height: 1rem;
  }
  /* Special styling for WebKit/Blink */
  .slider-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 0.0625rem solid #6cb043;
    box-shadow: 0rem 0.18rem 0.15rem #afafaf;
    height: 2.9rem;
    width: 1rem;
    border-radius: 0.24rem;
    background: #fff;
    cursor: pointer;
  }
  /* All the same stuff for Firefox */
  .slider-input::-moz-range-thumb {
    border: 0.0625rem solid #6cb043;
    box-shadow: 0rem 0.18rem 0.15rem #afafaf;
    height: 2.9rem;
    width: 1rem;
    border-radius: 0.24rem;
    background: #fff;
    cursor: pointer;
  }
  /* All the same stuff for IE */
  .slider-input::-ms-thumb {
    border: 0.0625rem solid #6cb043;
    box-shadow: 0rem 0.18rem 0.15rem #afafaf;
    height: 2.9rem;
    width: 1rem;
    border-radius: 0.24rem;
    background: #fff;
    cursor: pointer;
  }
`;

export default StyledRangeSlider;
