import React, { Suspense } from 'react';
import { Location } from '@reach/router';
import { Loader } from '@ux/balance-react';
import { Layout, Routes } from './shared/components';

const App = (): JSX.Element => (
  <>
    <Location>
      {({ location }) => (
        <Layout>
          <Suspense fallback={<Loader isLoading screenReaderContent='Content is loading...' />}>
            <Routes location={location} />
          </Suspense>
        </Layout>
      )}
    </Location>
  </>
);

export default App;
