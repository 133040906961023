import { Component } from 'react'
import { withIdleTimer } from 'react-idle-timer'

class IdleTimerComponent extends Component {
  render () {
    if (this.props.children ===  null || this.props.children === undefined) {
      return '';
    }
    return this.props.children;
  }
}

export const IdleTimer = withIdleTimer(IdleTimerComponent)