/* create a script tag pointing to the path set as parameter `url` */
const newScript = (url: string) => {
  const script = document.createElement('script');
  script.src = url;
  script.type = 'text/javascript';
  script.async = true;
  return script;
};

/* generate a new <iframe> tag */
const newFrame = (url: string) => {
  const frame = document.createElement('iframe');
  frame.src = url;
  // @ts-ignore
  frame.style = 'width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;';
  frame.append('<p>Your browser does not support iframes.</p>');
  return frame;
};

/* an interface for new tags generation */
export const nodes = {
  script: newScript,
  iframe: newFrame,
};

const lazyLoad = ({ url, type, htmlElementType }: any, newElement?: any) => {
  /* generate the new tag/node per configuration */
  // @ts-ignore
  const element = type in nodes ? nodes[type](url) : newElement;
  /* append the script tag to the document body */
  try {
    if (htmlElementType === 'head') {
      document.head.appendChild(element);
    } else {
      document.body.appendChild(element);
    }
  } catch (err) {
    throw new Error(`Error attempting to lazy load from ${url}. Error: ${err}`);
  }

  /* return a promise which gets resolved when the new element is loaded */
  /* if Promise is not supported (e.g. IE11), return the reference to the element instead */
  return typeof Promise === 'function'
    ? new Promise<void>((resolve) => {
        element.onload = () => resolve();
      })
    : element;
};

export default lazyLoad;
