import React from 'react';
import { Logo } from '@ux/balance-react';
import StyledHeader from './styles';
import HelpMenu from '../HelpMenu/index';

const Header = (): JSX.Element => {
  return (
    <StyledHeader>
      <header className='header' role='banner'>
        <div className='header-logo-button'>
          <Logo variant='lockup' href='https://www.regions.com/personal-banking/loans-and-lines-of-credit' />
        </div>
        <HelpMenu />
      </header>
    </StyledHeader>
  );
};

export default Header;
