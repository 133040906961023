import { observable } from 'mobx';
import { ProgressBarCurrentStep } from '../../../shared/constants';

export interface IUI {
  isPageLoading?: boolean;
  pageTitle?: string;
  progressBarCurrentStep?: number;
}

const UI: IUI = observable({
  isPageLoading: true,
  pageTitle: 'Apply for a Regions Digital Loan',
  progressBarCurrentStep: ProgressBarCurrentStep.NoProgressBarOnPage,
});

export default UI;
