import React from 'react';
import css from '@emotion/styled';

const StyledSVG = css.svg`
  .cls-1 {
    fill: url(#linear-gradient);
  }
  .cls-2 {
    fill: url(#linear-gradient-2);
  }
  .cls-3 {
    fill: url(#linear-gradient-3);
  }
  .cls-12,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-20,
  .cls-22,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9 {
    fill: none;
  }
  .cls-4 {
    stroke: #eee;
    stroke-width: 9.23px;
  }
  .cls-16,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-20,
  .cls-22,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9 {
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .cls-5 {
    stroke: #dedede;
    stroke-width: 9.35px;
  }
  .cls-6 {
    stroke: #cecece;
    stroke-width: 9.47px;
  }
  .cls-7 {
    stroke: #bebebe;
    stroke-width: 9.59px;
  }
  .cls-8 {
    stroke: #aeaeae;
    stroke-width: 9.71px;
  }
  .cls-9 {
    stroke: #ba5800;
  }
  .cls-22,
  .cls-9 {
    stroke-width: 9.83px;
  }
  .cls-10,
  .cls-16 {
    fill: #9e9e9e;
  }
  .cls-11 {
    fill: #7a7a7a;
  }
  .cls-12,
  .cls-19,
  .cls-20 {
    stroke: #727272;
  }
  .cls-12 {
    stroke-miterlimit: 10;
    stroke-width: 1.59px;
  }
  .cls-13 {
    fill: #333;
  }
  .cls-14 {
    fill: #eee;
  }
  .cls-15 {
    fill: #88bb01;
  }
  .cls-16,
  .cls-17,
  .cls-22 {
    stroke: #9e9e9e;
  }
  .cls-16,
  .cls-17 {
    stroke-width: 3.97px;
  }
  .cls-18 {
    stroke: #333;
    stroke-width: 4.76px;
  }
  .cls-19 {
    stroke-width: 3.25px;
    stroke-dasharray: 3.24 3.24;
  }
  .cls-20 {
    stroke-width: 6.66px;
  }
  .cls-21 {
    fill: #fff;
  }
`;

const Icon503BikeNumber = (): JSX.Element => (
  <StyledSVG
    id='icon-503-bike-number'
    x={0}
    y={0}
    viewBox='0 0 1920 776'
    xmlSpace='preserve'
    role='presentation'
    focusable='false'
    data-testid='503BikeNumber_Icon'
  >
    <linearGradient
      id='linear-gradient'
      x1='-200.24'
      y1='422.49'
      x2='1959.98'
      y2='422.49'
      gradientUnits='userSpaceOnUse'
    >
      <stop offset='0' stopColor='#9e9e9e' />
      <stop offset='1' stopColor='#eee' />
    </linearGradient>
    <linearGradient
      id='linear-gradient-2'
      x1='-220.24'
      y1='418.15'
      x2='1939.98'
      y2='418.15'
      xlinkHref='#linear-gradient'
    />
    <linearGradient
      id='linear-gradient-3'
      x1='-240.24'
      y1='418.15'
      x2='1919.98'
      y2='418.15'
      xlinkHref='#linear-gradient'
    />

    <path
      className='cls-1'
      d='M165.46,524.69c27.45,25.28,59.95,45.5,98.95,45.5,46.95,0,77.28-24.56,77.28-71.5,0-46.22-29.61-70.78-72.22-70.78-26.72,0-40.45,5.78-67.89,23.11L153.9,420.68l13-231.84h260V278.4H258.63l-7.94,85.95c17.33-7.94,32.5-10.83,51.28-10.83,78.72,0,145.89,44.78,145.89,142.28,0,101.84-78.72,160.34-168.28,160.34-75.11,0-125.67-28.17-161.78-64.28Z'
    />
    <path
      className='cls-2'
      d='M489.25,416.35c0-156,65.72-236.17,164-236.17s163.95,80.17,163.95,236.17S751.42,656.13,653.2,656.13,489.25,572.35,489.25,416.35Zm227.51,0c0-125.67-27.45-153.84-63.56-153.84-35.39,0-63.56,28.17-63.56,153.84,0,124.95,28.17,157.45,63.56,157.45C689.31,573.8,716.76,541.3,716.76,416.35Z'
    />
    <path
      className='cls-3'
      d='M888.87,524c27.45,26.72,61.39,46.22,100.39,46.22,44.78,0,74.39-19.5,74.39-54.17,0-40.45-23.83-65.72-123.5-65.72V375.18c82.34,0,108.34-24.56,108.34-61.39,0-31.78-20.22-50.56-56.33-50.56-32.5,0-57.06,15.17-85.95,40.45l-53.45-65c41.89-36.11,88.11-58.5,144.45-58.5,94.61,0,158.17,45.5,158.17,125.67,0,47.67-27.45,80.89-76.56,101.84v2.89c52.72,15.17,91,52.72,91,111.95,0,85.22-78,133.62-170.45,133.62-74.39,0-126.39-26-159.62-65Z'
    />
    <polygon className='cls-4' points='1521.89 495.11 1639.5 377.5 1639.5 495.5 1521.89 495.11' />
    <polygon className='cls-5' points='1523.88 525.52 1666.8 382.6 1666.8 526 1523.88 525.52' />
    <polygon className='cls-6' points='1525.87 555.93 1694.1 387.7 1694.1 556.5 1525.87 555.93' />
    <polygon className='cls-7' points='1527.85 586.35 1721.4 392.8 1721.4 587 1527.85 586.35' />
    <polygon className='cls-8' points='1529.83 616.77 1748.7 397.9 1748.7 617.5 1529.83 616.77' />
    <line className='cls-9' x1='1722.06' y1='496.64' x2='1716.93' y2='374.43' />
    <polygon className='cls-10' points='1367.56 478.27 1357.5 482.65 1381.73 496.04 1385.47 489.81 1367.56 478.27' />
    <polygon className='cls-11' points='1354.1 489.65 1357.5 482.65 1381.73 496.04 1378.77 501.93 1354.1 489.65' />
    <line className='cls-12' x1='1220.39' y1='518.15' x2='1277.64' y2='419.63' />
    <line className='cls-12' x1='1220.54' y1='518.31' x2='1319.47' y2='461.76' />
    <line className='cls-12' x1='1220.6' y1='518.52' x2='1334.55' y2='519.19' />
    <line className='cls-12' x1='1220.54' y1='518.73' x2='1318.8' y2='576.43' />
    <line className='cls-12' x1='1220.38' y1='518.88' x2='1276.48' y2='618.07' />
    <line className='cls-12' x1='1220.17' y1='518.94' x2='1218.98' y2='632.88' />
    <line className='cls-12' x1='1219.96' y1='518.88' x2='1161.81' y2='616.87' />
    <line className='cls-12' x1='1219.81' y1='518.72' x2='1120.36' y2='574.36' />
    <line className='cls-12' x1='1219.75' y1='518.51' x2='1105.81' y2='516.8' />
    <line className='cls-12' x1='1219.81' y1='518.3' x2='1122.08' y2='459.7' />
    <line className='cls-12' x1='1219.97' y1='518.15' x2='1164.78' y2='418.45' />
    <line className='cls-12' x1='1220.18' y1='518.09' x2='1222.41' y2='404.16' />
    <line className='cls-12' x1='1220.59' y1='518.41' x2='1330.74' y2='489.22' />
    <line className='cls-12' x1='1220.59' y1='518.62' x2='1330.53' y2='548.6' />
    <line className='cls-12' x1='1220.48' y1='518.81' x2='1300.58' y2='599.86' />
    <line className='cls-12' x1='1220.29' y1='518.92' x2='1248.97' y2='629.21' />
    <line className='cls-12' x1='1220.07' y1='518.92' x2='1189.59' y2='628.72' />
    <line className='cls-12' x1='1219.88' y1='518.81' x2='1138.47' y2='598.54' />
    <line className='cls-12' x1='1219.77' y1='518.62' x2='1109.36' y2='546.79' />
    <line className='cls-12' x1='1219.77' y1='518.4' x2='1110.11' y2='487.42' />
    <line className='cls-12' x1='1219.89' y1='518.21' x2='1140.53' y2='436.43' />
    <line className='cls-12' x1='1220.08' y1='518.1' x2='1192.41' y2='407.56' />
    <line className='cls-12' x1='1220.3' y1='518.11' x2='1251.77' y2='408.59' />
    <line className='cls-12' x1='1220.49' y1='518.22' x2='1302.62' y2='439.24' />
    <path
      className='cls-13'
      d='M1220,646.39a130.41,130.41,0,1,1,130.58-130.24A130.56,130.56,0,0,1,1220,646.39Zm.33-249a118.59,118.59,0,1,0,118.44,118.75A118.73,118.73,0,0,0,1220.33,397.39Z'
    />
    <path
      className='cls-14'
      d='M1220,635.42a119.44,119.44,0,1,1,119.59-119.28A119.57,119.57,0,0,1,1220,635.42Zm.29-223.68a104.24,104.24,0,1,0,104.11,104.38A104.36,104.36,0,0,0,1220.31,411.74Z'
    />
    <line className='cls-12' x1='1631.46' y1='518.69' x2='1688.71' y2='420.16' />
    <line className='cls-12' x1='1631.61' y1='518.84' x2='1730.54' y2='462.3' />
    <line className='cls-12' x1='1631.67' y1='519.06' x2='1745.62' y2='519.72' />
    <line className='cls-12' x1='1631.61' y1='519.27' x2='1729.87' y2='576.97' />
    <line className='cls-12' x1='1631.45' y1='519.42' x2='1687.55' y2='618.61' />
    <line className='cls-12' x1='1631.24' y1='519.47' x2='1630.05' y2='633.42' />
    <line className='cls-12' x1='1631.03' y1='519.42' x2='1572.88' y2='617.41' />
    <line className='cls-12' x1='1630.88' y1='519.26' x2='1531.43' y2='574.9' />
    <line className='cls-12' x1='1630.82' y1='519.05' x2='1516.88' y2='517.34' />
    <line className='cls-12' x1='1630.88' y1='518.84' x2='1533.15' y2='460.24' />
    <line className='cls-12' x1='1631.04' y1='518.68' x2='1575.85' y2='418.99' />
    <line className='cls-12' x1='1631.25' y1='518.63' x2='1633.48' y2='404.7' />
    <line className='cls-12' x1='1631.66' y1='518.94' x2='1741.81' y2='489.76' />
    <line className='cls-12' x1='1631.66' y1='519.16' x2='1741.6' y2='549.13' />
    <line className='cls-12' x1='1631.55' y1='519.35' x2='1711.65' y2='600.4' />
    <line className='cls-12' x1='1631.36' y1='519.46' x2='1660.04' y2='629.74' />
    <line className='cls-12' x1='1631.14' y1='519.46' x2='1600.66' y2='629.26' />
    <line className='cls-12' x1='1630.95' y1='519.35' x2='1549.54' y2='599.08' />
    <line className='cls-12' x1='1630.84' y1='519.16' x2='1520.43' y2='547.33' />
    <line className='cls-12' x1='1630.85' y1='518.94' x2='1521.18' y2='487.96' />
    <line className='cls-12' x1='1630.96' y1='518.75' x2='1551.6' y2='436.97' />
    <line className='cls-12' x1='1631.15' y1='518.64' x2='1603.48' y2='408.1' />
    <line className='cls-12' x1='1631.37' y1='518.65' x2='1662.84' y2='409.13' />
    <line className='cls-12' x1='1631.56' y1='518.76' x2='1713.69' y2='439.78' />
    <path
      className='cls-13'
      d='M1631.08,646.93a130.41,130.41,0,1,1,130.58-130.24A130.56,130.56,0,0,1,1631.08,646.93Zm.33-249a118.59,118.59,0,1,0,118.44,118.75A118.73,118.73,0,0,0,1631.4,397.93Z'
    />
    <path
      className='cls-14'
      d='M1631.09,636a119.44,119.44,0,1,1,119.59-119.28A119.57,119.57,0,0,1,1631.09,636Zm.29-223.68a104.24,104.24,0,1,0,104.11,104.38A104.36,104.36,0,0,0,1631.38,412.28Z'
    />
    <path
      className='cls-15'
      d='M1352.85,414.87a4.22,4.22,0,0,1-2.14-7.86,518.14,518.14,0,0,1,69.17-32.59c53.42-20.55,99.26-28,136.24-22.22a4.22,4.22,0,0,1-1.31,8.34c-84.9-13.33-198.67,53.07-199.81,53.75A4.2,4.2,0,0,1,1352.85,414.87Z'
    />
    <path
      className='cls-15'
      d='M1219.75,523.16a4.22,4.22,0,0,1-2.68-7.48c5.45-4.5,133.9-110.34,165.06-115.49,36.47-6,65.21,3.91,73.16,7.09,7.55,3,33.89,12.24,55.95,3.45a4.22,4.22,0,0,1,3.13,7.84c-24.28,9.68-51.6.81-62.22-3.46-15.3-6.15-41.37-11.11-68.63-6.6-15.63,2.58-63.75,37.67-91,58.36-36.47,27.65-69.68,55-70,55.31A4.21,4.21,0,0,1,1219.75,523.16Z'
    />
    <path
      className='cls-15'
      d='M1363.34,545.82a182.84,182.84,0,0,1-38.15-4.05c-63.91-13.77-105.51-18.6-105.92-18.64a4.22,4.22,0,0,1,1-8.39c.42,0,42.39,4.91,106.74,18.78,31.64,6.82,65.68,4.56,93.4-6.2,22.51-8.74,51.72-27.38,63-66.6,4.91-17.1,16.59-42.9,44.32-68.71,16.54-15.38,30.84-23.41,31.44-23.75a4.22,4.22,0,0,1,4.11,7.37h0c-.56.31-55.75,31.66-71.75,87.41-9.67,33.67-33.19,58.62-68,72.14C1405.21,542.26,1384.44,545.85,1363.34,545.82Z'
    />
    <path className='cls-10' d='M1330.28,339.47a4.22,4.22,0,0,0-7.89,3l22.36,58.77,7-5.43Z' />
    <path className='cls-15' d='M1405.15,536.24l-53.43-140.43-7,5.43,52.51,138a4.22,4.22,0,1,0,7.89-3Z' />
    <path
      className='cls-10'
      d='M1540.53,303.27,1552,289.69l1.74,4.89a4.22,4.22,0,0,0,8-2.83l-4.2-11.82a4.22,4.22,0,0,0-7.2-1.3L1532.5,299.7a4.22,4.22,0,0,0-.74,4.17l13.87,37.93,8-2.76Z'
    />
    <path
      className='cls-15'
      d='M1634.44,517.31l-40.22-67.24-40.61-111-8,2.76,40.8,111.55a4.2,4.2,0,0,0,.34.72l40.43,67.58a4.22,4.22,0,0,0,7.24-4.33Z'
    />
    <path className='cls-13' d='M1450.44,244.19a5.21,5.21,0,1,0-2.29,10.16l39.14,8.82,7.12-9.07Z' />
    <path
      className='cls-10'
      d='M1567.41,269.89,1538.74,242a4.22,4.22,0,0,0-1.68-1l-26.66-8.37-5.57,7.1,28.75,9L1559,273.52l-5.26,8.11-38.72-21.75a4.22,4.22,0,0,0-1.14-.44l-20.88-4.7-5.77,7.35,24.2,5.45,41.64,23.39a4.22,4.22,0,0,0,5.61-1.38l9.3-14.34A4.22,4.22,0,0,0,1567.41,269.89Z'
    />
    <path className='cls-13' d='M1480.66,223.22a4.72,4.72,0,0,0-2.83,9l27.18,8.53,6.24-7.94Z' />
    <line className='cls-16' x1='1343.98' y1='404.3' x2='1353.7' y2='395.45' />
    <line className='cls-17' x1='1543.69' y1='343.37' x2='1555.51' y2='338.32' />
    <line className='cls-18' x1='1511.34' y1='231.48' x2='1506.26' y2='242.45' />
    <line className='cls-18' x1='1493.58' y1='253.41' x2='1488.51' y2='264.37' />
    <path
      className='cls-13'
      d='M1372.23,331.93c-9.31,6.51-33.62,19.55-55,17.78a20.78,20.78,0,0,1-10.61-4c-4.16-3.07-11.1-7.59-16.61-8.15-4.26-.43-8.08-.65-10.83-.76a4.48,4.48,0,0,1-4.3-4.91c.66-6.57,4.15-17.09,20.22-12.9,22.78,5.94,41.35,8.49,54.86,6,8.26-1.54,17.06-2.13,21-1A4.48,4.48,0,0,1,1372.23,331.93Z'
    />
    <path
      className='cls-10'
      d='M1401.61,504.91a35,35,0,1,0,34.92,35A35,35,0,0,0,1401.61,504.91Zm27.84,35a27.84,27.84,0,0,1-.77,6.46l-21.73-8.63,11.67-19.91A27.85,27.85,0,0,1,1429.45,539.91Zm-16.93-25.68-11.2,19.1L1387.14,516a27.85,27.85,0,0,1,25.37-1.78Zm-30.91,6.2,14.24,17.39-21.64,7.44a27.82,27.82,0,0,1,7.4-24.83ZM1376.45,552l21.7-7.46-.54,23A28,28,0,0,1,1376.45,552Zm28.24,15.6.53-22.9,20.94,8.31A27.94,27.94,0,0,1,1404.69,567.58Z'
    />
    <circle className='cls-10' cx='1401.12' cy='539.35' r='11.51' transform='matrix(0, -1, 1, 0, 859.94, 1939.76)' />
    <path
      className='cls-19'
      d='M1405,512.24l-183.89-8.89a13.81,13.81,0,1,0-.88,27.61s177.78,42.5,180.3,42.78c0,0,1.65.16,2.5.19a30.86,30.86,0,1,0,2-61.69Z'
    />
    <line className='cls-20' x1='1400.28' y1='537.66' x2='1438.28' y2='586.4' />
    <path
      className='cls-15'
      d='M1378.43,525.05s5.93-12.65,27.87-9.25c18,2.79,16.85,21.12,16.85,21.12l17.73,0s5.11-36.29-49.75-38.89l-144.34.66-11,12.65Z'
    />
    <polygon
      className='cls-15'
      points='1227.91 506.82 1238.25 498.49 1253.79 498.68 1248.5 512.52 1225.07 509.13 1227.91 506.82'
    />
    <polygon className='cls-10' points='1429.83 592.65 1429.82 603.62 1451.79 586.77 1447.58 580.85 1429.83 592.65' />
    <polygon className='cls-11' points='1434.88 609.53 1429.82 603.62 1451.79 586.77 1456 591.84 1434.88 609.53' />
    <path
      className='cls-21'
      d='M1319.48,507.67c0-.72-.1-1-1-1.25l0-.25,4.2.28c2.44.16,3.36,1.18,3.28,2.38a2.1,2.1,0,0,1-1.92,1.92v0c1,.29,1.73,3.09,2.48,3.92a1.26,1.26,0,0,0,.77.42l0,.25-2.55-.17c-.89-1.18-1.4-3.14-2-3.85-.37-.41-.67-.44-1.45-.49l-.18,2.64c-.05.76.25,1.11,1.05,1.28l0,.25-4.2-.28,0-.25c.73,0,1.15-.34,1.19-1l.39-5.8m1.77,2.71c1.67.23,2.47-.23,2.57-1.65.07-1.1-.52-1.74-1.73-1.82l-.6,0-.24,3.52'
    />
    <path
      className='cls-21'
      d='M1333.19,513.47H1333a.86.86,0,0,0-.89-.91l-1.2-.08-.13,1.95c0,.6.2.83.88.88l.4,0a1.52,1.52,0,0,0,1.52-1.14h.2l-.24,1.6-5.21-.35v-.19c.77-.11.89-.33.94-1l.29-4.26c0-.58-.09-.79-.8-1v-.19l5.12.34-.1,1.47h-.2c-.17-.86-.41-1.13-1.16-1.18l-1.32-.09-.18,2.74,1.2.08c.55,0,.82-.17,1-.76h.22l-.14,2.05'
    />
    <path
      className='cls-21'
      d='M1341.32,516a6.61,6.61,0,0,1-2.68.3,3.45,3.45,0,0,1-3.48-3.68,3.54,3.54,0,0,1,3.95-3.29,10.64,10.64,0,0,1,1.92.53.64.64,0,0,0,.51-.19h.21l-.12,1.86h-.21a2.55,2.55,0,0,0-2.24-1.81c-1.4-.09-2.25,1.08-2.39,3.13s.74,3,2,3.09c.62,0,1-.09,1.07-.63l.07-1a.75.75,0,0,0-.65-.86v-.19l2.87.19v.19c-.51.1-.69.34-.73,1l-.09,1.4'
    />
    <path
      className='cls-21'
      d='M1345.77,515.49c0,.59.1.77.8,1v.19l-3.23-.22v-.19c.85-.15.89-.37.93-1l.28-4.24c0-.59-.1-.77-.8-1v-.19l3.23.22v.19c-.83.13-.89.37-.94,1l-.28,4.25'
    />
    <path
      className='cls-21'
      d='M1351.82,517.19a3.5,3.5,0,1,1,4-3.23,3.48,3.48,0,0,1-4,3.23m.44-6.59c-1.34-.09-2.15.94-2.29,3-.11,1.6.33,3.08,1.87,3.19,1.14.08,2.15-.73,2.29-2.92.12-1.86-.38-3.2-1.88-3.3'
    />
    <path
      className='cls-21'
      d='M1363.24,517.87H1363l-4.55-5.71-.27,4.06c0,.73.16.95.93,1.12v.19l-2.36-.16v-.19c.74-.06.94-.44,1-1.08l.3-4.44c-.49-.56-.59-.64-.87-.72v-.19l2.05.14,3.7,4.71.2-3c.05-.8-.07-1.12-.78-1.33v-.19l2.31.15v.19c-.78,0-1,.43-1.06,1l-.36,5.43'
    />
    <path
      className='cls-21'
      d='M1369.76,513.15h-.2a2,2,0,0,0-1.6-1.48,1,1,0,0,0-1.18.82,1,1,0,0,0,.29.73c.32.35,1.83,1.26,2.22,1.7a1.95,1.95,0,0,1-1.68,3.36,12.68,12.68,0,0,1-1.65-.37.38.38,0,0,0-.36.19h-.2l.13-2h.2a1.87,1.87,0,0,0,1.72,1.79c.73,0,1.29-.31,1.32-.86a1.09,1.09,0,0,0-.3-.78c-.49-.55-1.66-1.11-2.19-1.71a1.92,1.92,0,0,1-.51-1.45,2,2,0,0,1,2.28-1.79,3.28,3.28,0,0,1,.69.14,2.91,2.91,0,0,0,.58.13.53.53,0,0,0,.36-.16h.2l-.12,1.74'
    />
    <path
      className='cls-21'
      d='M1372.28,510a.8.8,0,1,1-.11,1.6.8.8,0,1,1,.11-1.6Zm0,.16a.64.64,0,0,0-.08,1.27.61.61,0,0,0,.65-.59.62.62,0,0,0-.57-.68Zm-.19,1h-.18l.05-.8a2.13,2.13,0,0,1,.31,0,.43.43,0,0,1,.27.08.2.2,0,0,1,.07.18.21.21,0,0,1-.18.18h0c.08,0,.11.1.13.2a.71.71,0,0,0,0,.2h-.19a.64.64,0,0,1-.05-.2c0-.08-.05-.13-.16-.13h-.09Zm0-.45h.09c.1,0,.19,0,.19-.1s0-.12-.16-.13h-.1Z'
    />
    <polyline className='cls-21' points='1304.2 513.79 1296.88 513.3 1301.84 507.51 1304.2 513.79' />
    <polyline className='cls-21' points='1304.51 504.38 1305.05 513.85 1302.02 507.32 1304.51 504.38' />
    <polyline className='cls-21' points='1306.56 501.97 1308.14 504.4 1305.76 513.9 1304.68 504.16 1306.56 501.97' />
    <polyline className='cls-21' points='1308.27 504.62 1310.37 507.86 1306.51 513.95 1308.27 504.62' />
    <polyline className='cls-21' points='1310.51 508.07 1314.67 514.5 1307.37 514.01 1310.51 508.07' />
    <polygon className='cls-22' points='1531.82 647.18 1776 403 1776 648 1531.82 647.18' />
    <path className='cls-9' d='M1722.06,496.64a38.59,38.59,0,1,1-77.11,3.24' />
    <line className='cls-9' x1='1644.95' y1='499.88' x2='1639.82' y2='377.67' />
    <rect
      className='cls-13'
      x='1626.34'
      y='360.04'
      width='103.39'
      height='16.02'
      rx='4.65'
      ry='4.65'
      transform='translate(3370.03 665.43) rotate(177.6)'
    />
  </StyledSVG>
);

export default Icon503BikeNumber;
