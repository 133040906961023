import React from 'react';
import { ProgressIndicator } from '@ux/balance-react';
import StyledProgressBar from './styles';

type ProgressBarProps = {
  currentStep: number;
};

const ProgressBar = ({ currentStep }: ProgressBarProps): JSX.Element => (
  <StyledProgressBar>
    <ProgressIndicator
      ariaLabel='Progress indicator status'
      currentStep={currentStep}
      totalSteps={6}
      dataTestId='Progress_Indicator'
    />
  </StyledProgressBar>
);

export default ProgressBar;
