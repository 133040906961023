import { LoanTermsAPIRoutes, PageRoutes, ProductAliases } from '../../../shared/constants';
import PMT from '../../../utils/PMT';
import { navigate } from '@reach/router';
import { ITerm, IFee, IRate, IRepay } from '../../../Application/store/Models/Offers';
import { IBaseSelectKeys } from '../../../Application/store/Models/ApplicantInfo';

class SelectCreditLimitStore {
  api: any;

  private applicationStore: any;

  constructor(api: any, applicationStore: any) {
    this.api = api;
    this.applicationStore = applicationStore;
  }

  findMaxCreditAmount = (selectedOffer: any, collateralOptions:any) =>
  {
    const collateralAccount = collateralOptions.options.find(
      (k: IBaseSelectKeys) => k.value === selectedOffer.collateralAccount
    );
   
  var maxDocStampFee = 0;
  const maxLoanAmount =Math.min(selectedOffer.loanMax, Math.floor(collateralAccount.balance));
      if(selectedOffer.hasDocStampFee) {
        const feeData = selectedOffer.feeData.find(
          (k: IFee) =>
            k.minInclusive <= maxLoanAmount && k.maxInclusive >= maxLoanAmount
        );
       maxDocStampFee =
          ((maxLoanAmount + (feeData ? feeData.fee : 0)) / 100.0) * 0.35;
        }
        
        if((maxLoanAmount + maxDocStampFee)>collateralAccount.balance)
        {
            return maxLoanAmount - maxDocStampFee;
        }
        else
        {
            return maxLoanAmount;
        }
  }

  handleLineAmountChange = async (selectedLoanAmount: string): Promise<void> => {
    let selectedOffer = this.applicationStore.selectedOffer;

    if (!selectedOffer || selectedOffer === '') return;

    selectedOffer.selectedLoanAmount = parseInt(selectedLoanAmount);

    selectedOffer.calculatedterms = selectedOffer.termData?.find(
      (k: ITerm) =>
        k.minInclusive <= selectedOffer.selectedLoanAmount && k.maxInclusive >= selectedOffer.selectedLoanAmount
    )?.term;

    if (selectedOffer.hasDocStampFee) {
      const feeData = selectedOffer.feeData?.find(
        (k: IFee) =>
          k.minInclusive <= selectedOffer.selectedLoanAmount && k.maxInclusive >= selectedOffer.selectedLoanAmount
      );
      selectedOffer.calculatedDocStampFee =
        ((selectedOffer.selectedLoanAmount + (feeData ? feeData.fee : 0)) / 100.0) * 0.35;
    }
     
    selectedOffer.totalAmountFinanced = selectedOffer.selectedLoanAmount + selectedOffer.calculatedDocStampFee;

    if (selectedOffer.repayData && selectedOffer.repayData.length > 0) {
      const repayData = selectedOffer.repayData?.find(
        (k: IRepay) =>
          k.minInclusive <= selectedOffer.selectedLoanAmount && k.maxInclusive >= selectedOffer.selectedLoanAmount
      );
      selectedOffer.paymentAmountMin = repayData?.paymentAmountMin;
      selectedOffer.paymentAmountPercent = repayData?.paymentAmountPercent;
    }
  
    if (this.applicationStore.isOfferPLOC() || this.applicationStore.isOfferUnsecured()) {
      selectedOffer.annualPercentRateCalculated = selectedOffer.annualPercentRateTotal;
    } else if (this.applicationStore.isOfferSecured()) {
      const rateData = selectedOffer.rateData.find(
        (k: IRate) =>
          k.minInclusive <= selectedOffer.selectedLoanAmount && k.maxInclusive >= selectedOffer.selectedLoanAmount
      );
      selectedOffer.annualPercentRateExtra = rateData.indicator * rateData.rate;
      selectedOffer.annualPercentRateCalculated =
        selectedOffer.annualPercentRatePrime + selectedOffer.annualPercentRateExtra;
    }
  };

  callLineValidate = async (): Promise<void> => {
    const { setMeta, handleFatalErrors, postRequestBuilder, selectedOffer, isOfferPLOC } = this.applicationStore;

    try {
      const Fields = [
        {
          loanAmount: selectedOffer.selectedLoanAmount,
          fundsRemaining:
            selectedOffer.productAlias === ProductAliases.SavingsSecuredCreditLine
              ? selectedOffer.fundsRemaining
              : null,
          collateralAccountNumber:
            selectedOffer.productAlias === ProductAliases.SavingsSecuredCreditLine
              ? selectedOffer.collateralAccount
              : null,
          annualPercentRateTotal: selectedOffer.annualPercentRateCalculated,
          annualPercentRateExtra: selectedOffer.annualPercentRateExtra,
          paymentAmountPercent: selectedOffer.paymentAmountPercent,
          paymentAmountMin: selectedOffer.paymentAmountMin,
        },
      ];

      const callLineValidateResponse = await this.api({
        url: LoanTermsAPIRoutes.loanValidate,
        method: 'post',
        data: postRequestBuilder(Fields),
      });

      const { errors } = callLineValidateResponse.data;

      if (errors && errors.length > 0) {
        setMeta({ errors });

        let fatalError = errors.find((e: { type: string }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }

      if (isOfferPLOC()) {
        navigate(PageRoutes.SelectOverdraftAccountPageRoute);
      } else if (selectedOffer.hasTransferAccounts) {
        navigate(PageRoutes.SelectTransferAmountPageRoute);
      } else {
        navigate(PageRoutes.ReviewOfferPageRoute);
      }
    } catch (e) {
      console.log('SelectLineAmountStore | Failed on callLineValidate. Details: ', e);
      handleFatalErrors(e);
    }
  };
}
export default SelectCreditLimitStore;
