import React from 'react';
import numberWithCommas from '../../../utils/numberWithCommas';
import StyledRangeSlider from './styles';

type RangeSliderProps = {
  minValue: number;
  maxValue: number;
  stepBy: number;
  sliderValue: number;
  setSliderValue(value: number): void;
  ariaLabel: string;
};

const RangeSlider = ({
  minValue,
  maxValue,
  stepBy,
  sliderValue,
  setSliderValue,
  ariaLabel,
}: RangeSliderProps): JSX.Element => {
  // formula for slider to reach max value when slider minValue is not in multiples of stepBy
  // When minValue is not in multiples of stepBy, then slider max value should increased to remainder of (minValue % stepBy) or (minValue) conditionally. 
  const result = minValue > stepBy ? minValue % stepBy : minValue;
  const sliderMaxValue = maxValue + result;
  const handleChange = (event: any) => {
    setSliderValue(Math.min(parseInt(event.target.value), maxValue) );
  };

  // formula for value in one range to value in another range:
  // (((OldValue - OldMin) * (NewMax - NewMin)) / (OldMax - OldMin)) + NewMin
  const bgColorMin = 91; // #D5D5D5 (l of 84%)
  const bgColorMax = 84; // #E8E8E8 (l of 91%)
  const bgColorValue = ((Math.min(sliderValue, maxValue) - minValue) * (bgColorMax - bgColorMin)) / (maxValue - minValue) + bgColorMin;

  return (
    <StyledRangeSlider>
      <div className='slider'>
        <div className='slider-bg'>
          <div
            style={{
              background: `linear-gradient(
              to bottom right, 
              white,
              white 48%, 
              hsl(0, 0%, ${bgColorValue}%) 52%,
              hsl(0, 0%, ${bgColorValue}%) 100%)`,
            }}
          />
        </div>
        <input
          type='range'
          value={sliderValue}
          min={minValue}
          max={sliderMaxValue}
          step={stepBy}
          className='slider-input'
          onInput={handleChange}
          aria-label={ariaLabel}
          aria-valuenow={sliderValue}
          aria-valuetext={`${sliderValue} dollars`}
        />
      </div>
      <div className='slider-legend'>
        <span className='left'>${numberWithCommas(minValue)}</span>
        <span className='right'>Up to ${numberWithCommas(maxValue)}</span>
      </div>
    </StyledRangeSlider>
  );
};

RangeSlider.defaultProps = {
  minValue: 200,
  maxValue: 15000,
  stepBy: 100,
  ariaLabel: 'Loan amount',
};

export default RangeSlider;
