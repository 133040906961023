import { ChangeEvent, useState } from 'react';
import { toJS } from 'mobx';
import { autoFormatter } from '../../shared/services';
import { GenericErrorMessages } from '../../shared/constants';

export interface IFormValidation {
  required?: boolean;
  requiredCustomErrorMessage?: string | null;
  pattern?: {
    value: string;
    errorMessage: string;
  };
  additionalValidation?: any;
  autoFormat?: string;
}

export interface IValidationResult {
  valid: boolean;
  errorMessage: string;
}

export interface IErrorSummaryData {
  ariaLabel: string;
  inputID: string;
  text: string;
  dataTestId: string;
}

export interface IValidationResultWithErrors {
  valid: boolean;
  errors: unknown[];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useFieldValidation = (options?: { validations?: any }, initialState?: any): any => {
  const [localData, setLocalData] = useState<any>(initialState || {});
  const [fieldValidationResult, setFieldValidationResult] = useState<any>({});

  const handleTextChange = (
    e: ChangeEvent<HTMLInputElement & HTMLSelectElement>,
    key: string,
    callback?: any
  ): void => {
    const validations: IFormValidation = options?.validations;

    const validation = (validations as any)[key];
    let inputValue = e.target.value;

    if (validation?.autoFormat) {
      inputValue = (autoFormatter(inputValue) as any)[validation.autoFormat];
    }

    const updatedData = {
      ...localData,
      [key]: inputValue,
    };

    setLocalData(updatedData);
    callback(updatedData);
  };

  const handleSelectChange = (
    e: ChangeEvent<HTMLInputElement & HTMLSelectElement>,
    key: string,
    opts: any[],
    callback?: any
  ): void => {
    const mappedSelectOption = opts.find((opt) => opt.value === e.target.value);
    let updatedData = {
      ...localData,
      [key]: mappedSelectOption,
    };

    if (!mappedSelectOption) {
      updatedData = {
        ...localData,
        [key]: { label: '', value: '' },
      };
    }

    setLocalData(updatedData);
    callback(updatedData);
    if (key === 'verificationType') {
      setFieldValidationResult({});
    }
  };

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement & HTMLSelectElement>,
    key: string,
    callback?: any
  ): void => {
    const updatedData = {
      ...localData,
      [key]: e.target.checked,
    };

    setLocalData(updatedData);
    callback(updatedData);
  };

  /* - Special handler for confirmation and acknowledgement checkboxes
     A single handler to call the checkchange event and also ensure in-line messaging
     is handled immediately on checkchange (instead of only on page submit). */
  const handleConfirmationCheckboxChange = (
    e: ChangeEvent<HTMLInputElement & HTMLSelectElement>,
    key: string,
    callback?: any,
    initialValidationResult?: any,
    showMessageWhenUnchecked = false // indicates if unchecking should trigger message
  ): void => {
    // eslint-disable-next-line
    const validationResult: IValidationResult = <IValidationResult>{ ...initialValidationResult };

    // call standard checkbox change
    handleCheckboxChange(e, key, callback);

    /* - hide message based on check status, OR
       - show message based on check status and showMessageWhenUnchecked */
    if (e.target?.checked || showMessageWhenUnchecked) {
      // @ts-ignore
      validationResult[key] = {
        valid: e.target?.checked,
        errorMessage: e.target?.checked
          ? ''
          : options?.validations[key]?.requiredCustomErrorMessage || GenericErrorMessages.FieldIsRequiredMessage,
      };
      setFieldValidationResult(validationResult);
    }
  };

  const handleRadioChange = (
    e: ChangeEvent<HTMLInputElement & HTMLSelectElement>,
    key: string,
    callback?: any
  ): void => {
    let { value } = e.target;
    if (key === 'usCitizenship') {
      if (e.target.value === 'usCitizen') {
        // @ts-ignore
        value = { value: 'U.S. Citizen', key: 'usCitizen' };
      } else {
        // @ts-ignore
        value = { value: 'Non U.S. Citizen', key: 'nonUSCitizen' };
      }
    }
    const updatedData = {
      ...localData,
      [key]: value,
    };

    setLocalData(updatedData);
    callback(updatedData);
  };

  const handleFieldValidation = async (
    fieldName?: string,
    initialValidationResult?: any,
    isValidating = false,
    initState: any = null
  ): Promise<IValidationResultWithErrors> => {
    const validations: IFormValidation = options?.validations;
    // eslint-disable-next-line
    const validationResult: IValidationResult = <IValidationResult>{ ...initialValidationResult };
    const errorList: IErrorSummaryData[] = [];
    if (validations) {
      Object.keys(validations).forEach((key) => {
        let value = initState ? initState[key] : localData[key];

        if (typeof value === 'object') {
          value = toJS(value).value;
        }
        // @ts-ignore
        const validation = validations[key];

        if (key !== fieldName && !isValidating) {
          return;
        }

        if ((validation?.required && value?.length === 0) || (isValidating && validation?.required && !value)) {
          // First, we clear out any old error state
          errorList.filter((err) => err.text === validation.key);
          // @ts-ignore
          validationResult[key] = {
            valid: false,
            errorMessage: validation?.requiredCustomErrorMessage || GenericErrorMessages.FieldIsRequiredMessage,
          };
          // This array handles populating the error array for the ErrorSummary
          errorList.push({
            ariaLabel: `Correct ${validation.ariaLabel}`,
            inputID: `${validation.inputId}_Input_Field`,
            text: validation.key,
            dataTestId: validation.dataTestId,
          });
        } else {
          // @ts-ignore
          validationResult[key] = {
            valid: true,
            errorMessage: '',
          };
        }

        if (!value || value?.length === 0) {
          return;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          errorList.filter((err) => err.text === validation.key);
          // @ts-ignore
          validationResult[key] = {
            valid: false,
            errorMessage: pattern.errorMessage,
          };
          errorList.push({
            ariaLabel: `Correct ${validation.ariaLabel}`,
            inputID: `${validation.inputId}_Input_Field`,
            text: validation.key,
            dataTestId: validation.dataTestId,
          });
        } else {
          // @ts-ignore
          validationResult[key] = {
            valid: true,
            errorMessage: '',
          };
        }

        const additionalValidation = validation?.additionalValidation;
        if (additionalValidation !== undefined) {
          const res = additionalValidation(value);

          // Verify regex pattern first if present in the validations list
          if (pattern?.value && !RegExp(pattern.value).test(value)) {
            return;
          }

          if (res.valid) {
            // @ts-ignore
            validationResult[key] = {
              valid: true,
              errorMessage: '',
            };
            return;
          }

          errorList.filter((err) => err.text === validation.key);
          // @ts-ignore
          validationResult[key] = {
            valid: false,
            errorMessage: res.errorMessage,
          };
          errorList.push({
            ariaLabel: `Correct ${validation.ariaLabel}`,
            inputID: `${validation.inputId}_Input_Field`,
            text: validation.key,
            dataTestId: validation.dataTestId,
          });
        }
      });
    }

    setFieldValidationResult(validationResult);

    return { valid: errorList.length === 0, errors: errorList };
  };

  const updateFormData = (data: any) => {
    setLocalData({
      ...localData,
      ...data,
    });
  };

  return {
    data: localData,
    handleTextChange,
    handleSelectChange,
    handleCheckboxChange,
    handleConfirmationCheckboxChange,
    handleRadioChange,
    handleFieldValidation,
    validationResult: fieldValidationResult,
    updateFormData,
  };
};

export default useFieldValidation;
