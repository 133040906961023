import React from 'react';
import { Modal, Button, Stack } from '@ux/balance-react';
import { CommonUrlRoutes } from '../../../constants';
import {StyledH2Wrapper} from './styles';
import { ModalTitles } from '../../../constants';

type MakeAppointmentModalProps = {
  isOpen: boolean;
  closeMethod(): void;
};

const MakeAppointmentModal = ({ isOpen, closeMethod }: MakeAppointmentModalProps): JSX.Element => {
  const modalCTAs = (
    <>
      <Button
        text='Cancel'
        size='medium'
        dataTestId='Cancel_Make_Appointment_Modal_Button'
        variant='text-secondary'
        clickMethod={() => closeMethod()}
      />
      <Button
        text='Continue'
        size='medium'
        dataTestId='Continue_Make_Appointment_Modal_Button'
        variant='primary'
        clickMethod={() => window.location.assign(CommonUrlRoutes.MakeAppointmentTimeTradeRoute)}
      />
    </>
  );
  return (
    <Modal
      isOpen={isOpen}
      closeMethod={() => closeMethod()}
      ariaLabel={ModalTitles.MakeAppointmentModalTitle}
      modalCTAs={modalCTAs}
      isModalTitleStatic
    >
      <StyledH2Wrapper>{ModalTitles.MakeAppointmentModalTitle}</StyledH2Wrapper>
      <Stack>
        <p className='text-style-body-2'>
          Appointment Scheduler is provided by TimeTrade<sup>&copy;</sup> Systems. The privacy policies and security at
          the linked website may differ from Regions&apos; privacy and security policies and procedures. You should
          consult privacy disclosures at the linked website for further information. Customer information provided in
          order to set up this appointment will not be used to update any customer records, and this information will
          only be used to service this appointment.
        </p>
        <p className='modal-disclaimer'>
          Banking products are provided by Regions Bank. Member FDIC.&nbsp;
          <a
            href='https://regions.com/personal_banking/ehl.rf'
            rel='noopener noreferrer'
            target='_blank'
            data-testid='Equal_Housing_Lender_Link_Make_Appointment_Modal'
          >
            Equal Housing Lender
          </a>
          .
        </p>
      </Stack>
    </Modal>
  );
};

export default MakeAppointmentModal;
