import { observable } from 'mobx';
import { IBaseSelectKeys } from './ApplicantInfo';

export interface ILookups {
  suffixOptions?: IBaseSelectKeys[];
  citizenshipOptions?: IBaseSelectKeys[];
  additionalCitizenshipOptions?: IBaseSelectKeys[];
  verificationOptions?: IBaseSelectKeys[];
  footprintStatesOptions?: IBaseSelectKeys[];
  allStatesOptions?: IBaseSelectKeys[];
  allIdentificationOptions?: IBaseSelectKeys[];
  filteredMiltiaryIdTypes?: IBaseSelectKeys[];
  filteredIdTypes?: IBaseSelectKeys[];
  countriesOptions?: IBaseSelectKeys[];
  employmentStatusOptions?: IBaseSelectKeys[];
  housingStatusOptions?: IBaseSelectKeys[];
}

const Lookups: ILookups = observable({
  suffixOptions: [],
  citizenshipOptions: [],
  additionalCitizenshipOptions: [],
  verificationOptions: [],
  footprintStatesOptions: [],
  allStatesOptions: [],
  countriesOptions: [],
  allIdentificationOptions: [],
  employmentStatusOptions: [],
  housingStatusOptions: [],
  get filteredMilitaryIdTypes() {
    if (this.allIdentificationOptions.length > 0) {
      return this.allIdentificationOptions.filter(
        (id: any) => id.value === 'DL' || id.value === 'MILI' || id.value === 'ID' || id.value === 'PASS'
      );
    }
    return [];
  },
  get filteredIdTypes() {
    if (this.allIdentificationOptions.length > 0) {
      return this.allIdentificationOptions.filter(
        (id: any) =>
          id.value === 'AIR' || id.value === 'ARMY' || id.value === 'COST' || id.value === 'MAR' || id.value === 'NAVY'
      );
    }
    return [];
  },
});

export default Lookups;
