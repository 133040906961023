import React from 'react';

const DocumentVerifiedIcon = (): JSX.Element => (
    <svg
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>Document Verified icon</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h48v48H0z" />
      <path
        d="M5 5.722A5.22 5.22 0 0 1 10.222.5h27.652a5.22 5.22 0 0 1 5.222 5.222v36.556a5.22 5.22 0 0 1-5.222 5.222H10.222A5.22 5.22 0 0 1 5 42.278V5.722Zm7.063 22.6a1.17 1.17 0 0 0 1.175 1.174h21.685a1.17 1.17 0 0 0 1.175-1.175 1.17 1.17 0 0 0-1.175-1.175H13.238a1.17 1.17 0 0 0-1.175 1.175Zm0 7.089a1.17 1.17 0 0 0 1.175 1.175h15.706a1.17 1.17 0 0 0 1.175-1.175 1.17 1.17 0 0 0-1.175-1.175H13.238a1.17 1.17 0 0 0-1.175 1.175Zm1.253-20.08a1.179 1.179 0 0 0-.065 1.671l4.126 4.491 9.4-9.752.065-.079a1.172 1.172 0 0 0-.104-1.592 1.178 1.178 0 0 0-1.672.026l-7.65 7.95-2.428-2.637-.079-.078a1.182 1.182 0 0 0-1.593 0Z"
        fill="#5E9C11"
      />
    </g>
  </svg>
);
export default DocumentVerifiedIcon;
