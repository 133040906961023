import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import { Modal, Button, Stack, TextInput } from '@ux/balance-react';
import { runInAction } from 'mobx';
import { StyledH2Wrapper, OTPCodeStyles } from '../CoBrowseModal/styles';
import { ModalTitles } from '../../../../shared/constants';
import { StyledContainerWrapper, StyledFullFormRow } from '../../../../shared/styles';
import { useFieldValidation, useStores } from '../../../../hooks';
import { AlertCircleIcon } from '../../../../shared/assets';
import { oneTimeCodeFieldValidations } from './utils';
import { InjectScript } from '../../ImportScript';
import { ensighten } from '../../../services';

type CoBrowseModalProps = {
  isOpen: boolean;
  closeMethod(): void;
};

const CoBrowseModal = ({ isOpen, closeMethod }: CoBrowseModalProps): JSX.Element => {
  const errorRef = useRef();
  const [coBrowseCodeRetry, setCoBrowseCodeRetry] = useState('');
  const {
    applicationStore: {
      lpChatInfo: { agentServiceID },
      setLivePersonChatInfo,
      marketingData,
      offers,
      selectedOffer
    },
    livePersonChatStore: { getSub },
  } = useStores();

  useEffect(() => {
    // @ts-ignore
    window.ServiceAgentIDError = (message) => {
      // @ts-ignore
      serviceIDValidationCallBack(message);
    };
    // @ts-ignore
    window.lpIdentities = (callback) => {
      const identityObj = { iss: window.location.origin, acr: 'loa1', sub: getSub() };
      callback(identityObj);
    };
    return () => {
      // @ts-ignore
      window.CloseScreenShareModal = null;
      // @ts-ignore
      window.ServiceAgentIDError = null;
      // @ts-ignore
      window.lpIdentities = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let serviceIDValidationCallBack = setCoBrowseCodeRetry;

  const CloseScreenShareModal = () => {
    closeMethod();
  };
  // @ts-ignore
  window.CloseScreenShareModal = CloseScreenShareModal;

  const handleOnClose = () => {
    closeMethod();
    serviceIDValidationCallBack('');
    validationResult.agentServiceID.valid = true;
    setLivePersonChatInfo({ agentServiceID: '' });
  };

  const handleOnChangeOfAgentServiceID = (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    handleTextChange(e, 'agentServiceID', handleUpdateState);
    serviceIDValidationCallBack('');
  };

  const handleOnBlurOfAgentServiceID = () => {
    if (agentServiceID !== '') {
      handleFieldValidation('agentServiceID', validationResult);
    }
  };

  const handleUpdateState = (val: any) =>
    runInAction(() => {
      /* need a function to update coBrowseCode */
      setLivePersonChatInfo({ ...val });
    });
  const { validationResult, handleFieldValidation, handleTextChange } = useFieldValidation(oneTimeCodeFieldValidations);

  const handleOnFormSubmit = async (): Promise<void> => {
    const res = await handleFieldValidation('', validationResult, true, {
      agentServiceID,
    });
    if (!res.valid) {
      // @ts-ignore
      errorRef.current.focus();
      return;
    }
    // need validate
    if (coBrowseCodeRetry) {
      // @ts-ignore
      errorRef.current.focus();
      return;
    }
    ensighten(marketingData, offers, selectedOffer.productAlias !== '' ? selectedOffer : null, '>screen share');
    InjectScript({ scriptData: `requestCobrowse(${agentServiceID})` });
  };

  const handleOnEnterKeySubmit = (e: any): void => {
    e.preventDefault();
    handleOnFormSubmit();
  };
  const modalCTAs = (
    <>
      <Button
        text='Cancel'
        size='medium'
        dataTestId='Cancel_CoBrowse_Modal_Button'
        variant='text-secondary'
        clickMethod={() => closeMethod()}
      />
      <Button
        text='Continue'
        size='medium'
        dataTestId='Continue_CoBrowse_Modal_Button'
        variant='primary'
        clickMethod={() => handleOnFormSubmit()}
      />
    </>
  );
  return (
    <Modal
      isOpen={isOpen}
      closeMethod={() => handleOnClose()}
      ariaLabel={ModalTitles.CoBrowseModalTitle}
      modalCTAs={modalCTAs}
      isModalTitleStatic
    >
      <StyledH2Wrapper>{ModalTitles.CoBrowseModalTitle}</StyledH2Wrapper>
      <Stack>
        <p className='text-style-body-2'>Please enter the service number provided over the phone.</p>
      </Stack>
      <StyledContainerWrapper>
        <form noValidate onSubmit={handleOnEnterKeySubmit}>
          <StyledFullFormRow>
            <TextInput
              label='Service Number'
              name='serviceNumber'
              initialValue={agentServiceID}
              isRequired
              type='text'
              handleOnChange={(e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) =>
                handleOnChangeOfAgentServiceID(e)
              }
              isValid={validationResult?.agentServiceID?.valid && coBrowseCodeRetry === ''}
              errorMessages={
                validationResult?.agentServiceID?.errorMessage ? [validationResult?.agentServiceID?.errorMessage] : []
              }
              handleOnBlurValidation={() => handleOnBlurOfAgentServiceID()}
              dataTestId='CoBrowse_Code_Input_Field'
              inputId='CoBrowse_Code_Input_Field'
              minLength={6}
              maxLength={6}
              ref={errorRef}
            />
          </StyledFullFormRow>

          {coBrowseCodeRetry !== '' && (
            <OTPCodeStyles>
              <div className='error_alert-tile-with-icon' data-testid='One_Time_Code_Not_Match'>
                <AlertCircleIcon />
                <p className='inner-content'>{coBrowseCodeRetry}</p>
              </div>
            </OTPCodeStyles>
          )}
        </form>
      </StyledContainerWrapper>
    </Modal>
  );
};

export default CoBrowseModal;
