import React, { useEffect } from 'react';

type ImportScriptProps = {
  scriptData: string;
};

// ImportScript used when we need to clear out the script inserted by components when they unload
const ImportScript = ({ scriptData }: ImportScriptProps) => {
  let script: any;
  let timeTagCreated: any;
  useEffect(() => {
    if (document != null && document.body !== undefined && document.body !== null) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      script = document.createElement('script');
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeTagCreated = `${new Date().getTime()}_${Math.floor(Math.random() * 1000 + 1)}`;
      script.id = timeTagCreated;
      script.innerHTML = scriptData;
      document.body.appendChild(script);
    }
    return () => {
      if (document.body !== undefined && document.body !== null) {
        if (script !== undefined && script !== null) {
          const chkElement = document.body.contains(script);
          if (chkElement && script.id === timeTagCreated.toString()) {
            const elem = document.getElementById(timeTagCreated);
            // eslint-disable-next-line
            elem?.parentElement?.removeChild(elem);
            script = null;
          }
        }
      }
    };
  }, []);
  return <></>;
};

// InjectScript used when we don't need to clear out the script inserted to dom by the component
const InjectScript = ({ scriptData }: ImportScriptProps) => {
  const script = document.createElement('script');
  script.innerHTML = scriptData;
  document.body.appendChild(script);
  return true;
};

export { ImportScript, InjectScript };
