import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import '@ux/balance-base/build/assets/style.css';
import * as serviceWorker from './serviceWorker';
import App from './App';

const domNode = document.getElementById('root');
const root = createRoot(domNode as any);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorker.unregister();
