const RATE = (numberOfMonthlyPayments, estimatedMonthlyPayment, amountFinanced, futureValue, type, guess) => {
  var nper = numberOfMonthlyPayments;
  var pmt = estimatedMonthlyPayment;
  var pv = amountFinanced;
  var fv = futureValue;

  // Sets default values for missing parameters
  fv = typeof fv !== 'undefined' ? fv : 0; // fv=future value
  type = typeof type !== 'undefined' ? type : 0;
  guess = typeof guess !== 'undefined' ? guess : 0.1;

  // Sets the limits for possible guesses to any
  // number between 0% and 100%
  var lowLimit = 0;
  var highLimit = 1;

  // Defines a tolerance of up to +/- 0.00005% of pmt, to accept
  // the solution as valid.
  var tolerance = Math.abs(0.00000005 * pmt);

  // Tries at most 40 times to find a solution within the tolerance.
  for (var i = 0; i < 40; i++) {
    // Resets the loanAmount to the original pv.
    var loanAmount = pv;

    // Calculates the loanAmount at the end of the loan, based
    // on loan conditions.
    for (var j = 0; j < nper; j++) {
      if (type == 0) {
        // Interests applied before payment
        loanAmount = loanAmount * (1 + guess) + pmt;
      } else {
        // Payments applied before insterests
        loanAmount = (loanAmount + pmt) * (1 + guess);
      }
    }

    // Returns the guess if loanAmount is within tolerance.  If not, adjusts
    // the limits and starts with a new guess.
    if (Math.abs(loanAmount + fv) < tolerance) {
      guess = guess * 12 * 100; // we want annual rate (so *12) and *100 since we want to see % in UI, so 16.00%, not .16
      return guess;
    } else if (loanAmount + fv > 0) {
      // Sets a new highLimit knowing that
      // the current guess was too big.
      highLimit = guess;
    } else {
      // Sets a new lowLimit knowing that
      // the current guess was too small.
      lowLimit = guess;
    }

    // Calculates the new guess.
    guess = (highLimit + lowLimit) / 2;
  }
  // Returns null if no acceptable result was found after 40 tries.
  return null;
};

export default RATE;
