import { PageTitles, ProductAliases } from '../../constants';

interface IProduct {
  title: ProductAliases | string;
  imageSrc: string | null;
  imageDataTestId: string;
}

const getLoanProduct = (productAlias: string): IProduct => {
  let product: IProduct;

  switch (productAlias) {
    default:
      product = {
        title: PageTitles.ApplyForARegionsDigitalLoan,
        imageSrc: null,
        imageDataTestId: '',
      };
  }

  return product;
};

export default getLoanProduct;
