import { observable } from 'mobx';

export interface IApplicationInformation {
  acapsApplicationId?: string;
  acapsCreditLimit?: string;
  acapsRate?: string;
  acapsStatus?: string;
  bridgeTrackFailOverLimit?: number;
  bridgeTrackPlacementID?: string;
  bridgeTrackScript?: string;
  bridgeTrackTargetsAvoka?: string;
  hasPromoCode?: string;
  identifier?: string;
  productAlias?: string;
  products?: string[];
  zipCode?: string;
}

export interface IProductInfoTile {
  introPeriod: string;
  specialIntroductoryApr: string;
  introductoryRewards: string;
  standardCardFeature1: string;
  standardCardFeature2: string;
  standardCardFeature3: string;
  standardCardFeature4: string;
  footNoteContent: string;
  pricingAgreementLink: string;
}

export interface IApplicationSubmit {
  applicationInformation?: IApplicationInformation | any;
  productInfoTile?: IProductInfoTile;
}
const ApplicationSubmit: IApplicationSubmit = observable({
  applicationInformation: {},
  productInfoTile: {
    introPeriod: '',
    specialIntroductoryApr: '',
    introductoryRewards: '',
    standardCardFeature1: '',
    standardCardFeature2: '',
    standardCardFeature3: '',
    standardCardFeature4: '',
    footNoteContent: '',
    pricingAgreementLink: '',
  },
});

export default ApplicationSubmit;
