// Source: https://github.com/panva/oauth4webapi/blob/main/examples/code.ts

const decodeBase64Url = (input: any) => {
    try {
      const binary = atob(input.replace(/-/g, '+').replace(/_/g, '/').replace(/\s/g, ''));
      const bytes = new Uint8Array(binary.length);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < binary.length; i++) {
        bytes[i] = binary.charCodeAt(i);
      }
      return bytes;
    } catch {
      throw new TypeError('generateCodeVerifier() | The input to be decoded is not correctly encoded.');
    }
  };
  
  const CHUNK_SIZE = 0x8000;
  const encodeBase64Url = (input: any) => {
    if (input instanceof ArrayBuffer) {
      // eslint-disable-next-line no-param-reassign
      input = new Uint8Array(input);
    }
  
    const arr = [];
    for (let i = 0; i < input.byteLength; i += CHUNK_SIZE) {
      arr.push(String.fromCharCode.apply(null, input.subarray(i, i + CHUNK_SIZE)));
    }
    return btoa(arr.join('')).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  };
  
  const b64u = (input: any) => {
    if (typeof input === 'string') {
      return decodeBase64Url(input);
    }
    return encodeBase64Url(input);
  };
  
  const buf = (input: any) => {
    if (typeof input === 'string') {
      return new TextEncoder().encode(input);
    }
    return new TextDecoder().decode(input);
  };
  
  export const generateCodeVerifier = () => {
    return b64u(crypto.getRandomValues(new Uint8Array(32)));
  };
  
  export const generateCodeChallenge = async (codeVerifier: any) => {
    if (typeof codeVerifier !== 'string') {
      throw new TypeError('code_verifier must be a non-empty string');
    }
    // @ts-ignore
    return b64u(await crypto.subtle.digest({ name: 'SHA-256' }, buf(codeVerifier)));
  };