import { CoBrowseServiceNumberValidationMessage } from '../../../../../shared/constants';
import { hasMinMaxLength } from '../../../../../shared/services';

export const oneTimeCodeFieldValidations = {
  validations: {
    agentServiceID: {
      required: true,
      requiredCustomErrorMessage: CoBrowseServiceNumberValidationMessage.RequiredErrorMessage,
      pattern: {
        value: '^[0-9]*$',
        errorMessage: CoBrowseServiceNumberValidationMessage.InvalidServiceNumber,
      },
      additionalValidation: (val: string) =>
        hasMinMaxLength(val, 6, 6, CoBrowseServiceNumberValidationMessage.InvalidServiceNumberLength),
      ariaLabel: 'service number',
      inputId: 'CoBrowse_Code',
      key: 'One-Time Code',
      dataTestId: 'Error_Summary_CoBrowse_Code',
    },
  },
};
export default oneTimeCodeFieldValidations;