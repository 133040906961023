import React, { useEffect, useState } from 'react';
import { IdleTimer } from './IdleTimer';
import SessionTimeoutModal from './SessionTimeoutModal';
import {
  SessionWarningHeader,
  SessionWarningModalBodyContent,
  SessionExpiredHeader,
  SessionExpiredModalBodyContent,
} from './content';
import { ensighten } from '../../services';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../hooks';

type SessionTimeoutProps = {
  idleTimer: any;
  timeElapsedSinceWarning: number;
};

const SessionTimeout = observer(({ idleTimer, timeElapsedSinceWarning }: SessionTimeoutProps) => {
  const {
    applicationStore: { marketingData, offers, selectedOffer },
  } = useStores();

  const convertMinutesToMs = (minutes: any) => {
    return minutes * 60000;
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSessionWarning, setIsSessionWarning] = useState<boolean>(false);
  const [isSessionTimedOut, setIsSessionTimedOut] = useState<boolean>(false);
  const [modalHeader, setModalHeader] = useState<string>('');
  const [modalContent, setModalContent] = useState<string>('');
  const [countdownIntervalId, setCountdownIntervalId] = useState<number>();

  const sessionTimeoutInMinutes = process.env.REACT_APP_SESSION_TIMEOUT_MINUTES || 20;
  const warningTimeoutInMinutes = process.env.REACT_APP_SESSION_WARNING_MINUTES || 18;
  const sessionKickoutTime = convertMinutesToMs(sessionTimeoutInMinutes);
  const warningModalTime = convertMinutesToMs(warningTimeoutInMinutes);

  // Once we land within the warning time frame,
  // this tracks how much time has passed since we warned the user.
  idleTimer = null;
  timeElapsedSinceWarning = 0;

  const onIdle = () => {
    if (isSessionTimedOut) return;

    warnActivation();
    setCountdownIntervalId(
      window.setInterval(() => {
        var timeElapsedSinceWarningNew = timeElapsedSinceWarning + 1000;
        timeElapsedSinceWarning = timeElapsedSinceWarningNew;
        var totalTimeElapsed = timeElapsedSinceWarningNew + warningModalTime;

        if (totalTimeElapsed > sessionKickoutTime) {
          timeOutActivation(marketingData, offers, selectedOffer);
        } else {
          updateWarning(timeElapsedSinceWarningNew);
        }
      }, 1000)
    );
  };

  const onAction = () => {
    if (!isSessionWarning && !isSessionTimedOut) {
      idleTimer.reset();
    }
  };

  const resetAllTimers = () => {
    idleTimer.reset();
    clearTimeout(countdownIntervalId);
    timeElapsedSinceWarning = 0;
  };

  const warnActivation = () => {
    setIsModalOpen(true);
    setIsSessionWarning(true);
    setIsSessionTimedOut(false);
  };

  const updateWarning = (elapsedIdleTime: any) => {
    let remainingTime = '';
    const seconds = Math.floor(elapsedIdleTime / 1000);
    // Minutes were delayed a second, so had to subtract a second.
    const minutes = Math.floor((sessionKickoutTime - warningModalTime - timeElapsedSinceWarning - 1000) / 60000);
    // Two minutes before session time out, call a modal.
    const totalSeconds = Math.abs(59 - (seconds % 60));
    if (minutes >= 0) {
      remainingTime = `${minutes}:${totalSeconds < 10 ? `0${totalSeconds}` : totalSeconds}`;
    }
    setModalHeader(SessionWarningHeader);
    remainingTime !== '' && totalSeconds && setModalContent(SessionWarningModalBodyContent(remainingTime));
  };

  const continueActivation = () => {
    resetAllTimers();
    setIsModalOpen(false);
    setIsSessionWarning(false);
    setIsSessionTimedOut(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (isSessionTimedOut) exitActivation();
    else resetAllTimers();
  };

  const timeOutActivation = (marketingData: any, offers: any, selectedOffer: any) => {
    setIsModalOpen(true);
    setIsSessionWarning(false);
    setIsSessionTimedOut(true);
    setModalHeader(SessionExpiredHeader);
    setModalContent(SessionExpiredModalBodyContent);
  };

  useEffect(() => {
    if (isSessionTimedOut) {
      ensighten(marketingData, offers, selectedOffer, undefined, true);
    }
  }, [isSessionTimedOut]);

  const exitActivation = () => {
    // This keeps the browser from alerting the user and asking them if they are sure they want to
    // navigate away from the page. We must return undefined here because that is the only value
    // supported by all browsers:
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    window.addEventListener('beforeunload', () => undefined);
    // window.location.assign('/');
    window.location.href = 'https://www.regions.com/personal-banking/loans-and-lines-of-credit';
  };

  return (
    <>
      {!isSessionTimedOut && (
        <IdleTimer
          ref={(ref: any) => {
            idleTimer = ref;
          }}
          element={document}
          onAction={onAction}
          onIdle={onIdle}
          throttle={3000}
          timeout={warningModalTime}
        />
      )}
      {modalHeader && modalContent && (
        <SessionTimeoutModal
          isOpen={isModalOpen}
          modalHeader={modalHeader}
          modalContent={modalContent}
          isWarningPopUp={isSessionWarning}
          closeMethod={closeModal}
          exitApplication={exitActivation}
          continueApplication={continueActivation}
          completeExitApplication={exitActivation}
        />
      )}
    </>
  );
});

export default SessionTimeout;
