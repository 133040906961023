import { navigate } from '@reach/router';
import { ProductAliases, ReviewLoanOfferAPIRoutes, PageRoutes } from '../../../shared/constants';

class ReviewOfferStore {
  api: any;

  private applicationStore: any;

  constructor(api: any, applicationStore: any) {
    this.api = api;
    this.applicationStore = applicationStore;
  }

  callReviewOfferValidate = async (): Promise<void> => {
    const {
      setMeta,
      handleFatalErrors,
      postRequestBuilder,
      selectedOffer,
      isOfferLoan,
      isOfferCreditLine,
      isOfferPLOC,
    } = this.applicationStore;

    try {
      let amt = 0,
        monthlyPaymentEstimate = null;
      if (isOfferLoan() || isOfferPLOC()) {
        amt = selectedOffer.selectedLoanAmount;
      } else if (isOfferCreditLine()) {
        amt = selectedOffer.transferAmount;
        monthlyPaymentEstimate = selectedOffer.monthlyPaymentEstimate;
      }

      const fields = [
        {
          TransferAmount: amt,
          TransferAccount: !isOfferPLOC() ? selectedOffer.transferAccount : selectedOffer.overdraftAccount,
          estimatedPayment: monthlyPaymentEstimate,
        },
      ];

      const callReviewOfferValidateResponse = await this.api({
        url: ReviewLoanOfferAPIRoutes.reviewLoanValidate,
        method: 'post',
        data: postRequestBuilder(fields),
      });

      const { errors } = callReviewOfferValidateResponse.data;

      if (errors && errors.length > 0) {
        setMeta({ errors });

        let fatalError = errors.find((e: { type: string }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }

      navigate(PageRoutes.AccountAgreementsPageRoute);
    } catch (e) {
      console.log('ReviewOfferStore | Failed on callReviewOfferValidate. Details: ', e);
      handleFatalErrors(e);
    }
  };
}
export default ReviewOfferStore;
