import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { InjectScript, ImportScript } from '../../shared/components/ImportScript';
import { useStores } from '../../hooks';
import { ensighten } from '../../shared/services';
import { Global } from '@emotion/react';

const LivePersonChat = observer((): JSX.Element => {
  const {
    applicationStore: {
      setLivePersonChatInfo,
      marketingData,
      lpChatInfo: {
        sub,
        isChatSessionCreated,
        chatToken,
        lPJSTagScript,
        isUnAuthenticatedChatToken,
        shouldChatReload,
        tokenExpiryInSec,
      },
      meta: { applicationId },
      offers,
      selectedOffer,
    },
    livePersonChatStore: { updateChatToken, shouldChatDisplay, shouldDisplayCoBrowse },
  } = useStores();

  useEffect(() => {
    setLivePersonChatInfo({ openChat });

    // @ts-ignore
    window.lpIdentities = (callback: any) => {
      const identityObj = { iss: window.location.origin, acr: 'loa1', sub: sub };
      callback(identityObj);
    };

    return () => {
      // @ts-ignore
      window.CloseScreenShareModal = null;
      // @ts-ignore
      window.ServiceAgentIDError = null;
      // @ts-ignore
      window.lpIdentities = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let chatTokenRefresh: NodeJS.Timeout;
    if (isChatSessionCreated) {
      chatTokenRefresh = setInterval(() => {
        updateChatToken();
      }, 1000 * tokenExpiryInSec - 10000);
    }
    return () => {
      if (isChatSessionCreated) {
        clearInterval(chatTokenRefresh);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatSessionCreated]);

  // Call JWT API call to get the token and Store it in Store
  const lpGetJWT = async (callback: any) => {
    let chatJWT = chatToken;

    ensighten(marketingData, offers, selectedOffer.productAlias !== '' ? selectedOffer : null, '>chat');
    if (chatToken === '' || (chatToken !== '' && isUnAuthenticatedChatToken)) {
      chatJWT = await updateChatToken();
      if (chatJWT !== '') {
        setUnAuthenticatedChatToken();
        setLivePersonChatInfo({ isChatSessionCreated: true });
      }
    }
    callback(chatJWT);
  };

  const openChat = () => {
    ensighten(marketingData, offers, selectedOffer.productAlias !== '' ? selectedOffer : null, '>chat');
    InjectScript({ scriptData: `openLPChat()` });
  };

  const setUnAuthenticatedChatToken = () => {
    if (applicationId > 0) {
      setLivePersonChatInfo({ isUnAuthenticatedChatToken: false });
      return true;
    }
    setLivePersonChatInfo({ isUnAuthenticatedChatToken: true });
    setLivePersonChatInfo({ shouldChatReload: true });
    return false;
  };

  if (shouldChatDisplay && isUnAuthenticatedChatToken && shouldChatReload && isChatSessionCreated) {
    InjectScript({
      scriptData: `document.querySelector("#lpChat > div.lp_maximized.lpmx.lpc_window.lpc_window_maximized.lpc_desktop.lp_floating.lp_maximized_large > div.lp_header.lpc_maximized-header.lpc_desktop > div > div.lp_header-buttons-container > button.lp_minimize.lpc_maximized-header__minimize-button.lpc_desktop > div > img").click();`,
    });
    setLivePersonChatInfo({ shouldChatReload: false });
  }

  const buttonChatHidden = {
    '[data-lp-event="click"]': {
      display: 'none',
    },
    '[id^="liveperson-banner"]': {
      display: 'none',
    },
    '[id^="LPMcontainer"]': {
      display: 'none !important',
    },
  };
  const importLpScript = () => {
    // @ts-ignore
    window.lpAcctNum = '79124512';
    // @ts-ignore
    window.lpGetJWT = lpGetJWT;

    if (!shouldChatDisplay && !shouldDisplayCoBrowse) {
      return <></>;
    }

    return (
      <>
        {(shouldChatDisplay && (
          <>
            <div id='liveperson-chat-banner-container'></div>
            <div id='liveperson-chat-btn-container'></div>
          </>
        )) || <Global styles={buttonChatHidden} />}
        <ImportScript scriptData={lPJSTagScript} />
        <ImportScript scriptData='lpTag.identities = [];lpTag.identities.push(window.lpIdentities);' />
      </>
    );
  };
  return importLpScript();
});

export default LivePersonChat;
