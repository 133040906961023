import React, { useState } from 'react';
import {
  DropdownMenu,
  DropdownTrigger,
  DropdownMenuContent,
  MenuList,
  MenuButton,
  MenuLink,
  MenuText,
  MenuDivider,
} from '@ux/balance-react';
import MakeAppointmentModal from './MakeAppointmentModal';
import CoBrowseModal from './CoBrowseModal';
import { useStores } from '../../../hooks';
import { ensighten } from '../../services';

const HelpMenu = (): JSX.Element => {
  const [openMakeAppointmentModal, setMakeAppointmentOpenModal] = useState(false);
  const [openCoBrowseModal, setOpenCoBrowseModal] = useState(false);

  const {
    livePersonChatStore: { shouldChatDisplay, shouldDisplayCoBrowse },
    applicationStore: { lpChatInfo, marketingData, offers, selectedOffer },
  } = useStores();



  const openLPChat = async (): Promise<void> => {
    const lpElement = document.querySelectorAll('[data-lp-event="click"]')[0] as HTMLElement | null;
    ensighten(marketingData, offers, selectedOffer.productAlias !== '' ? selectedOffer : null, '>chat');
    if (lpElement) {
      lpElement.click();
    }
  }

  return (
    <>
      <MakeAppointmentModal isOpen={openMakeAppointmentModal} closeMethod={() => setMakeAppointmentOpenModal(false)} />
      <CoBrowseModal
        isOpen={openCoBrowseModal}
        closeMethod={() => setOpenCoBrowseModal(false)}
      />
      <DropdownMenu classes='help-menu'>
        <DropdownTrigger text='Help' ariaLabel='Toggle help menu' dataTestId='Help_Dropdown_Trigger' />
        <DropdownMenuContent>
          <MenuList>
            <MenuLink
              href='https://www.regions.com/FAQ/open_account_online.rf'
              icon='IconHelpCircle'
              text='FAQ'
              dataTestId='FAQ_Help_Menu_Link'
              target='_blank'
            />

            {shouldChatDisplay && (
              <MenuButton clickMethod={() => openLPChat()} icon='IconChatBubble' text='Chat' dataTestId='Chat_Help_Menu_Link' />
            )}

            {shouldDisplayCoBrowse && lpChatInfo.isCoBrowseValidOnPage && (
              <MenuButton
                clickMethod={() => setOpenCoBrowseModal(true)}
                icon='IconScreenShare'
                text='Screen Share'
                dataTestId='Screen_Share_Help_Menu_Link'
              />
            )}

            <MenuButton
              clickMethod={() => setMakeAppointmentOpenModal(true)}
              icon='IconCalendar'
              text='Make Appointment'
              dataTestId='Make_Appointment_Help_Menu_Link'
            />
            <MenuLink
              href='https://www.regions.com/about_regions/online_privacy_notice_to_customers.rf'
              icon='IconInfoShield'
              text='Your Privacy'
              dataTestId='Your_Privacy_Help_Menu_Link'
              target='_blank'
            />
          </MenuList>
          <MenuDivider />
          <MenuList>
            <MenuLink
              href='tel:18007344667'
              icon='IconPhone'
              text='Call 1-800-REGIONS'
              dataTestId='Phone_Help_Menu_Link'
            />
            <MenuText isIndented classes='phone-hours' dataTestId='Phone_Hours_Help_Menu_Text'>
              <p>
                Mon - Fri: 6 AM - 7 PM CST
                <br />
                Sat: 8 AM - 2 PM CST
              </p>
            </MenuText>
          </MenuList>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default HelpMenu;
