import { observable } from 'mobx';

export interface IBaseSelectKeys {
  label?: string;
  value?: string;
}

export interface IApplicantInfo {
  firstName?: string;
  middleInitial?: string;
  lastName?: string;
  suffix?: IBaseSelectKeys;
  usCitizenship?: IBaseSelectKeys;
  additionalCitizenship?: IBaseSelectKeys;
  ssn?: string;
  dateOfBirth?: string;
  phone?: string;
  isNotMobilePhone?: boolean;
  email?: string;
  promoCode?: string;
  bankerId?: string;
  isNotForeignPoliticalOfficial?: boolean;
  hasAcceptedTerms?: boolean;
  currentStreetAddress?: string;
  aptOrSuite?: string;
  city?: string;
  state?: IBaseSelectKeys;
  zip?: string;
  isCurrentAddressLessThanYear?: boolean;
  needsMailingAddress?: boolean;
  previousStreetAddress?: string;
  previousAptOrSuite?: string;
  previousCity?: string;
  previousState?: IBaseSelectKeys;
  previousZip?: string;
  mailingStreetAddress?: string;
  mailingAptOrSuite?: string;
  mailingCity?: string;
  mailingState?: IBaseSelectKeys;
  mailingZip?: string;
  ssaAccepted?: boolean;
  associateId?: string;
  hasExpiredId?: boolean;
  workPhone?: string;
  isEmployerLessThanYear?: boolean;
  previousEmployerName?: string;
  needsEmployerLessThanYear?: boolean;
  isEnrolledInOnlineBanking?: boolean;
  isPrivateWealth?: boolean;
  identificationNumber?: string;
  driversLicenseNumber?: string;
  militaryIdNumber?: string;
  passportNumber?: string;
  stateIssuedIdNumber?: string;
  identificationType?: IBaseSelectKeys;
  militaryBranch?: IBaseSelectKeys;
  issueDate?: string;
  expirationDate?: string;
  stateOfIssue?: IBaseSelectKeys;
  isChangingCurrentAddress?: boolean;
  isChangingEmail?: boolean;
  isChangingIdentification?: boolean;
  isChangingUSCitizenship?: boolean;
  isChangingPrimaryPhone?: boolean;
  needsMoreEmploymentInfo?: boolean;
  employmentStatus?: IBaseSelectKeys;
  employerName?: string;
  totalAnnualIncome?: string;
  housingStatus?: IBaseSelectKeys;
  housingMonthlyPayment?: string;
  mailingAddressDifferentFromCurrentAddress?: boolean;
  atPrimaryAddressForLessThanOneYear?: boolean;
  amountExempt?: string;
  isFederal?: boolean;
  isStudent?: boolean;
  businessName?: string;
  isPromoCodeInvalid?: boolean;
  isAcapsPromoCodeRuleNotSatisfied?: boolean;
  isRegionsEmployee?: boolean;
  OtpAdditionalVerificationAccepted?: boolean;
}

const ApplicantInfo: IApplicantInfo = observable({
  firstName: '',
  middleInitial: '',
  lastName: '',
  suffix: { label: '', value: '' },
  usCitizenship: { label: 'U.S. citizen', value: 'usCitizen' },
  additionalCitizenship: { label: '', value: '' },
  ssn: '',
  dateOfBirth: '',
  phone: '',
  isNotMobilePhone: false,
  email: '',
  promoCode: '',
  bankerId: '',
  isNotForeignPoliticalOfficial: false,
  hasAcceptedTerms: false,
  // Address
  currentStreetAddress: '',
  aptOrSuite: '',
  city: '',
  state: { label: '', value: '' },
  zip: '',
  atPrimaryAddressForLessThanOneYear: false,
  mailingAddressDifferentFromCurrentAddress: false,
  previousStreetAddress: '',
  previousAptOrSuite: '',
  previousCity: '',
  previousState: { label: '', value: '' },
  previousZip: '',
  mailingStreetAddress: '',
  mailingAptOrSuite: '',
  mailingCity: '',
  mailingState: { label: '', value: '' },
  mailingZip: '',
  associateId: '',
  hasExpiredId: false,
  isEmployerLessThanYear: false,
  previousEmployerName: '',
  needsEmployerLessThanYear: false,
  isEnrolledInOnlineBanking: false,
  isPrivateWealth: false,
  // Identification
  identificationNumber: '',
  identificationType: { label: "Driver's license", value: 'DL' },
  militaryBranch: { label: '', value: '' },
  issueDate: '',
  expirationDate: '',
  stateOfIssue: { label: '', value: '' },
  // Update Intent
  isChangingCurrentAddress: false,
  isChangingEmail: false,
  isChangingIdentification: false,
  isChangingUSCitizenship: false,
  isChangingPrimaryPhone: false,
  // Employment & Income
  needsMoreEmploymentInfo: false,
  employmentStatus: { label: 'Employed', value: 'employed' },
  employerName: '',
  workPhone: '',
  totalAnnualIncome: '',
  housingStatus: { label: 'Own/Buying', value: 'own' },
  housingMonthlyPayment: '',
  amountExempt: '',
  isFederal: false,
  isStudent: false,
  businessName: '',
  isPromoCodeInvalid: false,
  isAcapsPromoCodeRuleNotSatisfied: false,
  isRegionsEmployee: false,
  // OTP
  OtpAdditionalVerificationAccepted: false
});

export default ApplicantInfo;
