import styled from '@emotion/styled';
import { breakpoints } from '../../../../styles';

export const StyledH2Wrapper = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1.75rem;
  @media ${breakpoints.xsMinMax} {
    font-size: 20px;
  }
`;

export const StyledModalFooterCta = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-end;
  @media ${breakpoints.xsMinMax} {
    flex-direction: column-reverse;
    justify-content: center;
    min-width: 100%;
    > * {
      width: 100%;
    }
  }
`;
