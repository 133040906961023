import React from 'react';

const DollarIcon = (): JSX.Element => (
  <svg
    width='8px'
    height='14px'
    viewBox='0 0 8 14'
    version='1.1'
    aria-hidden='true'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        transform='translate(-36.000000, -554.000000)'
        fill='#5E9C11'
        fillRule='nonzero'
      >
        <g transform='translate(16.000000, 380.000000)'>
          <g transform='translate(20.720000, 168.000000)'>
            <g transform='translate(0.000000, 6.064000)'>
              <path
                d='M2.768,13.696 L2.768,12.096 C2.27733333,12.0426667 1.784,11.912 1.288,11.704 C0.792,11.496 0.362666667,11.2213333 0,10.88 L0.832,9.632 C1.20533333,9.93066667 1.584,10.1706667 1.968,10.352 C2.352,10.5333333 2.76266667,10.624 3.2,10.624 C3.72266667,10.624 4.112,10.504 4.368,10.264 C4.624,10.024 4.752,9.68533333 4.752,9.248 C4.752,8.896 4.64533333,8.60266667 4.432,8.368 C4.21866667,8.13333333 3.94933333,7.92 3.624,7.728 C3.29866667,7.536 2.94933333,7.352 2.576,7.176 C2.20266667,7 1.85333333,6.792 1.528,6.552 C1.20266667,6.312 0.933333333,6.024 0.72,5.688 C0.506666667,5.352 0.4,4.93333333 0.4,4.432 C0.4,3.68533333 0.613333333,3.064 1.04,2.568 C1.46666667,2.072 2.04266667,1.76 2.768,1.632 L2.768,0 L4.048,0 L4.048,1.6 C4.56,1.664 5,1.81066667 5.368,2.04 C5.736,2.26933333 6.06933333,2.53866667 6.368,2.848 L5.424,3.92 C5.12533333,3.64266667 4.83733333,3.432 4.56,3.288 C4.28266667,3.144 3.952,3.072 3.568,3.072 C3.13066667,3.072 2.78933333,3.18133333 2.544,3.4 C2.29866667,3.61866667 2.176,3.936 2.176,4.352 C2.176,4.672 2.28266667,4.936 2.496,5.144 C2.70933333,5.352 2.97866667,5.544 3.304,5.72 C3.62933333,5.896 3.97866667,6.072 4.352,6.248 C4.72533333,6.424 5.07466667,6.63466667 5.4,6.88 C5.72533333,7.12533333 5.99466667,7.42666667 6.208,7.784 C6.42133333,8.14133333 6.528,8.58666667 6.528,9.12 C6.528,9.888 6.31466667,10.5306667 5.888,11.048 C5.46133333,11.5653333 4.848,11.8986667 4.048,12.048 L4.048,13.696 L2.768,13.696 Z'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default DollarIcon;
