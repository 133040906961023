import { CongratulationsAPIRoutes, PageRoutes } from '../../../shared/constants';
import { navigate } from '@reach/router';

class CongratulationsStore {
  api: any;

  private applicationStore: any;

  constructor(api: any, applicationStore: any) {
    this.api = api;
    this.applicationStore = applicationStore;
  }

  callCongratulationsInitiate = async (): Promise<void> => {
    const { setMeta, handleFatalErrors, postRequestBuilder } = this.applicationStore;

    try {
      const callCongratulationsInitiateResponse = await this.api({
        url: CongratulationsAPIRoutes.bookLoan, // API route url
        method: 'post',
        data: postRequestBuilder(),
      });

      const { errors } = callCongratulationsInitiateResponse.data;

      if (errors && errors.length > 0) {
        const isDeclined = errors.some((e: { ruleAlias: string }) => e.ruleAlias === 'DeclinedRule');
        if (isDeclined) {
          navigate(PageRoutes.DeclinationPageRoute);
          return;
        }

        setMeta({ errors });

        let fatalError = errors.find((e: { type: string }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }
    } catch (e) {
      console.log('CongratulationsStore | Failed on callCongratulationsInitiate. Details: ', e);
      handleFatalErrors(e);
    }
  };
}
export default CongratulationsStore;
