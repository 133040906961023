const typography = {
  // Font Family
  baseFontFamily: "'Source Sans Pro', Arial, sans-serif",
  serifFontFamily: "Georgia, 'Times New Roman', Times, serif",
  monospaceFontFamily: "Menlo, Monaco, Consolas, 'Courier New', monospace",

  // Font sizes
  extraSmallFontSize: '0.5625rem', // 9px
  smallFontSize: '0.875rem', // 14px
  baseFontSize: '1rem', // 16px
  mediumFontSize: '1.125rem', // 18px
  mediumLargeFontSize: '1.25rem', // 20px
  pageHeadingFontSize: '1.5rem', //
  titleHeadingFontSize: '3rem', // 48px - Largest possible font size

  // Font weights
  thinFontWeight: 100,
  semiBoldFontWeight: 300,
  regularFontWeight: 400,
  boldFontWeight: 700,
};

export default typography;
