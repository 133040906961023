import styled from '@emotion/styled';
import { breakpoints, typography, colors } from '../index';

type IStyledContainerWrapper = {
  isNonFormWrapper?: boolean;
};

// Layout Classes ------
export const StyledContainerWrapper = styled.div<IStyledContainerWrapper>`
  margin: 0 auto;
  @media ${breakpoints.smMin} {
    max-width: ${(props: IStyledContainerWrapper) =>
    props.isNonFormWrapper ? '39.25rem' : '25.625rem'}; // 628px | 410px
  }
`;

export const StyledPageWrapper = styled.div`
  max-width: 39rem; // 410px
  margin: 0 auto;
`;

export const StyledPageWrapperWithPageIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

// Common Form Layout Classes
export const StyledFullFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

export const StyledBlockFormRow = styled.div`
  display: block;
  margin-top: 1.5rem;
  .block-spacing {
    margin-bottom: 1rem;
  }
  .form-control__input-group .form-control__legend {
    font-size: 1rem;
  }
`;

export const StyledDividedFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  > .form-control__container:first-of-type {
    flex-basis: 66%;
  }
  > .form-control__container:last-child {
    flex-basis: calc(33.33333% - 0.75rem);
  }
`;

export const StyledButtonRow = styled.div`
  margin-top: 3rem;
  .cta--text-secondary {
    align-self: center;
  }
`;

export const StyledViewPdfButtonRow = styled.div`
  margin-top: 3rem;
  text-align: center;
  .cta--text-secondary {
    align-self: center;
  }
`;

export const StyledErrorSummaryWrapper = styled.div`
  margin-top: 1.5rem;
`;

export const StyledErrorTileWrapper = styled.div`
  margin-top: 1.5rem;
  .error-text-style-16 {
    color: ${colors.red};
  }
  .tile .alert-tile-with-icon {
    display: flex;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 0.75rem;
      fill: ${colors.red};
    }
  }
`;

type IStyledPopoverWrapper = {
  shiftLeft?: boolean;
  padPopover?: boolean;
};

export const StyledPopoverWrapper = styled.div<IStyledPopoverWrapper>`
  flex: 0 0 auto;

  margin-left: ${(props: IStyledPopoverWrapper) => (props.shiftLeft ? '0.5rem' : '')};
  .popover {
    margin: ${(props: IStyledPopoverWrapper) => (props.padPopover ? '0 0.7rem 0 0.2rem' : '0')};
    transform: translateY(2.1rem);
  }
`;

export const StyledSkipToMainLink = styled.div`
  .skip-to-main-link {
    position: absolute;
    left: 2rem;
    top: 0;
    transition: transform 0.2s;
    transform: translate3d(0, -100%, 0);
    box-shadow: none;
    z-index: 10000;
    &:focus {
      transform: translate3d(0, 10px, 0);
      box-shadow: 0 1px 4px rgb(0 0 0 / 16%);
    }
  }
`;

// Typography Classes ------
// Page Heading Classes
type IIsCenteredProps = {
  isCentered?: boolean;
};

export const StyledH1PageHeading = styled.h1<IIsCenteredProps>`
  &.text-style-heading-3 {
    margin-top: 0.5rem;
    font-size: ${typography.mediumLargeFontSize};
    font-weight: ${typography.boldFontWeight};
    text-align: ${(props: IIsCenteredProps) => (props.isCentered ? 'center' : 'left')};
  }
  &.text-style-heading-4 {
    margin-top: 0.5rem;
    font-size: ${typography.pageHeadingFontSize};
    text-align: ${(props: IIsCenteredProps) => (props.isCentered ? 'center' : 'left')};
  }
`;

export const StyledH1WelcomePageHeading = styled.h1<IIsCenteredProps>`
  &.text-style-heading-3 {
    max-width: 39.25rem;
    margin: 0 auto;
    text-align: ${(props: IIsCenteredProps) => (props.isCentered ? 'center' : 'left')};
  }
`;

export const StyledH2PageSubheading = styled.h2<IIsCenteredProps>`
  &.text-style-heading-3 {
    max-width: 39.25rem;
    margin: 0 auto;
    text-align: ${(props: IIsCenteredProps) => (props.isCentered ? 'center' : 'left')};
  }
  &.text-style-body-1-strong {
    margin-top: 2rem;
  }
`;

// Common Text Classes
export const StyledRequiredFieldsText = styled.p`
  margin-top: 2.5rem;
  font-size: ${typography.smallFontSize};
  letter-spacing: -0.008em;
  line-height: 1.375;
  align-self: flex-start;
  font-style: italic;
`;

export const StyledInformationalText14 = styled.p`
  font-size: ${typography.smallFontSize};
`;

type IStyledInformationalText18 = {
  isEmphasized?: boolean;
  mobileFriendly? : boolean;
};

export const StyledInformationalText18 = styled.p<IStyledInformationalText18>`
  font-size: ${typography.mediumFontSize};
  margin: ${(props: IStyledInformationalText18) => (props.isEmphasized ? '2.5rem 0 0.25rem 0' : '0')};
  ${(props: IStyledInformationalText18) =>
    props.mobileFriendly
      ? `@media ${breakpoints.smOnly} {
        font-size: ${typography.baseFontSize};
    }`
      : ''};
`;

type IStyledInformationalText20 = {
  includesButton?: boolean;
  isCentered?: boolean;
};

export const StyledInformationalText20 = styled.p<IStyledInformationalText20>`
  margin-top: 2rem;
  font-size: ${typography.mediumLargeFontSize}; // 20px
  text-align: ${(props: IIsCenteredProps) => (props.isCentered ? 'center' : 'left')};

  .cta {
    margin-left: 0;
    @media ${breakpoints.smMin} {
      margin-left: ${(props: IStyledInformationalText20) => (props.includesButton ? '1rem' : '0')};
    }
  }
`;

export const StyledPrefilledDataFieldWrap = styled.div`
  .prefilled-data-wrapper {
    margin-bottom: 1.87rem;
    padding-right: 1rem;
    font-size: 1.03rem;
  }
  .prefilled-field-label {
    display: block;
    font-size: 1.03rem;
    margin-top: 1.5rem;
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;
    font-weight: 600;
  }
  .prefilled-field-data {
    font-size: ${typography.mediumFontSize};
  }
`;
