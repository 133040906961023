import styled from '@emotion/styled';
import { breakpoints } from '../../../styles';

const StyledHeader = styled.div`
  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
    padding: 0.5rem 0 0 0;
    @media ${breakpoints.smMin} {
      padding: 0.5rem 0;
    }
  }
  .header-logo-button {
    padding-left: 1rem;
    @media ${breakpoints.xlgMin} {
      padding-left: 0;
    }
  }

  .regions-logo__lockup {
    display: none;
  }

  @media ${breakpoints.smMin} {
    .regions-logo__lockup {
      display: block;
    }
  }

  @media ${breakpoints.xlgMin} {
    padding-left: 0;
    padding-right: 0;
    padding-top: 1rem;
  }
`;

export default StyledHeader;
