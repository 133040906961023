import React, { lazy } from 'react';
import { Router, RouteComponentProps } from '@reach/router';

export type RoutesProps = {
  location: any;
};

// Page Chunks --------------
// @ts-ignore
const ActivateChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "GetStarted" */ '../../../views/Activate')
);

// Page Chunks --------------
// @ts-ignore
const GetStartedChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "GetStarted" */ '../../../views/GetStarted')
);

// Page Chunks --------------
// @ts-ignore
const SelectCollateralChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "GetStarted" */ '../../../views/SelectCollateral')
);

// Page Chunks --------------
// @ts-ignore
const SelectLoanAmountChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "SelectLoan" */ '../../../views/SelectLoanAmount')
);

// Page Chunks --------------
// @ts-ignore
const SelectCreditLimitChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "SelectCredit" */ '../../../views/SelectCreditLimit')
);

// Page Chunks --------------
// @ts-ignore
const SelectOverdraftChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "SelectOverdraft" */ '../../../views/SelectOverdraft')
);

// Page Chunks --------------
// @ts-ignore
const SelectTransferAmountChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "SelectTransferAmount" */ '../../../views/SelectTransferAmount')
);

// Page Chunks --------------
// @ts-ignore
const TransferFundsChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "TransferFunds" */ '../../../views/TransferFunds')
);

// Page Chunks --------------
// @ts-ignore
const SelectLoanPurposeChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "SelectLoan" */ '../../../views/SelectLoanPurpose')
);

// Page Chunks --------------
// @ts-ignore
const TransferConfirmationChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "TransferConfirmation" */ '../../../views/TransferConfirmation')
);

// Page Chunks --------------
// @ts-ignore
const ReviewOfferChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "ReviewOffer" */ '../../../views/ReviewOffer')
);

// Page Chunks --------------
// @ts-ignore
const AccountAgreementsChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "AccountAgreements" */ '../../../views/AccountAgreements')
);

// Page Chunks --------------
// @ts-ignore
const CongratulationsChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "AccountAgreements" */ '../../../views/Congratulations')
);

// Page Chunks --------------
// @ts-ignore
const ErrorChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "Error" */ '../../../views/Error/Error')
);

// Page Chunks --------------
// @ts-ignore
const Error503Chunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "Error503" */ '../../../views/Error503')
);

// Page Chunks --------------
// @ts-ignore
const DeclinationChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "Declination" */ '../../../views/Error/Declination')
);

// Page Chunks --------------
// @ts-ignore
const OdpDeclinationChunk: React.LazyExoticComponent<React.ComponentClass<Partial<any>>> = lazy(
  () => import(/* webpackChunkName: "OdpDeclination" */ '../../../views/Error/OdpDeclination')
);

const Activate = (props: RouteComponentProps) => <ActivateChunk {...props} />;
const GetStarted = (props: RouteComponentProps) => <GetStartedChunk {...props} />;
const SelectCollateral = (props: RouteComponentProps) => <SelectCollateralChunk {...props} />;
const SelectLoanAmount = (props: RouteComponentProps) => <SelectLoanAmountChunk {...props} />;
const SelectCreditLimit = (props: RouteComponentProps) => <SelectCreditLimitChunk {...props} />;
const SelectOverdraft = (props: RouteComponentProps) => <SelectOverdraftChunk {...props} />;
const SelectTransferAmount = (props: RouteComponentProps) => <SelectTransferAmountChunk {...props} />;
const TransferFunds = (props: RouteComponentProps) => <TransferFundsChunk {...props} />;
const SelectLoanPurpose = (props: RouteComponentProps) => <SelectLoanPurposeChunk {...props} />;
const TransferConfirmation = (props: RouteComponentProps) => <TransferConfirmationChunk {...props} />;
const ReviewOffer = (props: RouteComponentProps) => <ReviewOfferChunk {...props} />;
const AccountAgreements = (props: RouteComponentProps) => <AccountAgreementsChunk {...props} />;
const Congratulations = (props: RouteComponentProps) => <CongratulationsChunk {...props} />;
const Error = (props: RouteComponentProps) => <ErrorChunk {...props} />;
const Error503 = (props: RouteComponentProps) => <Error503Chunk {...props} />;
const Declination = (props: RouteComponentProps) => <DeclinationChunk {...props} />;
const OdpDeclination = (props: RouteComponentProps) => <OdpDeclinationChunk {...props} />;

const Routes = ({ location }: RoutesProps): any => {
  return (
    <Router location={location} id='router'>
      <Activate path='/activate' />
      <GetStarted path='/' default />
      <GetStarted path='/get-started' />
      <SelectCollateral path='/select-collateral' />
      <SelectLoanAmount path='/select-loan-amount' />
      <SelectCreditLimit path='/select-credit-limit' />
      <SelectOverdraft path='/select-overdraft' />
      <SelectTransferAmount path='/select-transfer-amount' />
      <TransferFunds path='/transfer-funds' />
      <SelectLoanPurpose path='/select-loan-purpose' />
      <TransferConfirmation path='/transfer-confirmation' />
      <ReviewOffer path='/review-offer' />
      <AccountAgreements path='/account-agreements' />
      <Congratulations path='congratulations' />
      <Error path='error' />
      <Error503 path='/error-503' />
      <Declination path='/declination' />
      <OdpDeclination path='/odp-declination' />
    </Router>
  );
};

export default Routes;
