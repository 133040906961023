import React from 'react';

const AlertCircleIcon = (): JSX.Element => (
  <svg aria-hidden='true' viewBox='0 0 24 24' fillRule='nonzero'>
    <path
      d='M12,1 C18.0665059,1 23,5.93349408 23,12 C23,18.0665059 18.0665059,23 12,23 C5.93349408,23 1,18.0665059 1,12 C1,5.93349408 5.93349408,1 12,1 Z M12,3 C7.03806358,3 3,7.03806358 3,12 C3,16.9619364 7.03806358,21 12,21 C16.9619364,21 21,16.9619364 21,12 C21,7.03806358 16.9619364,3 12,3 Z M11.9232955,15.0582237 C12.6543703,15.0582237 13.2465909,15.6582367 13.2465909,16.3936678 C13.2465909,17.1290988 12.6543703,17.7291118 11.9232955,17.7291118 C11.1922206,17.7291118 10.6,17.1290988 10.6,16.3936678 C10.6,15.6582367 11.1922206,15.0582237 11.9232955,15.0582237 Z M11.9232955,6.375 C12.5565427,6.375 13.0395489,6.95300747 13.0395489,7.63213158 L13.0395489,11.9177895 C13.0395489,12.5969136 12.5565427,13.1749211 11.9232955,13.1749211 C11.2900482,13.1749211 10.807042,12.5969136 10.807042,11.9177895 L10.807042,7.63213158 C10.807042,6.95300747 11.2900482,6.375 11.9232955,6.375 Z'
      fillRule='nonzero'
    />
  </svg>
);

export default AlertCircleIcon;
