import { SelectLoanPurposeAPIRoutes, PageRoutes } from '../../../shared/constants';
import { navigate } from '@reach/router';
import { ILoanPurpose } from '../../../Application/store/Models/LoanPurposes';

class SelectLoanPurposeStore {
  api: any;

  private applicationStore: any;

  constructor(api: any, applicationStore: any) {
    this.api = api;
    this.applicationStore = applicationStore;
  }

  callLoanPurposeInitiate = async (): Promise<void> => {
    const { setMeta, handleFatalErrors, postRequestBuilder, setPurposeOptions } = this.applicationStore;

    try {

      const callOfferInitiateResponse = await this.api({
        url: SelectLoanPurposeAPIRoutes.loanPurposeInitiate,
        method: 'post',
        data: postRequestBuilder(),
      });

      const { errors, fields } = callOfferInitiateResponse.data;

      if (errors && errors.length > 0) {
        setMeta({ errors })

        let fatalError = errors.find((e: { type: string; }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }

      if (fields.length > 0) {
        setPurposeOptions(fields);
      }

    } catch (e) {
      console.log('SelectLoanPurposeStore | Failed on callLoanPurposeInitiate. Details: ', e);
      handleFatalErrors(e);
    }
  };

  callLoanPurposeValidate = async (): Promise<void> => {
    const { setMeta, handleFatalErrors, postRequestBuilder, loanPurposes, selectedOffer } = this.applicationStore;
    try {

      const Fields = [{
        alias: selectedOffer.purposeType,
        name: loanPurposes.find((purpose : ILoanPurpose) => {
          return purpose.alias === selectedOffer.purposeType;
        }).name
      }];

      const callLoanPurposeValidateResponse = await this.api({
        url: SelectLoanPurposeAPIRoutes.loanPurposeValidate,
        method: 'post',
        data: postRequestBuilder(Fields),
      });

      const { errors } = callLoanPurposeValidateResponse.data;

      if (errors && errors.length > 0) {
        setMeta({ errors })

        let fatalError = errors.find((e: { type: string; }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }

      navigate(PageRoutes.TransferFundsPageRoute);

    } catch (e) {
      console.log('SelectLoanPurposeStore | Failed on callLoanPurposeValidate. Details: ', e);
      handleFatalErrors(e);
    }
  };

}
export default SelectLoanPurposeStore;
