import { GetStartedAPIRoutes, AppSettings, PageRoutes, ProductAliases } from '../../../shared/constants';
import queryString from 'query-string';
import { IOfferDetails } from '../../../Application/store/Models/Offers';
import { navigate } from '@reach/router';
import { CaughtException } from 'mobx/dist/internal';

class GetStartedStore {
  api: any;

  private applicationStore: any;

  constructor(api: any, applicationStore: any) {
    this.api = api;
    this.applicationStore = applicationStore;
  }

  callAuthorizeEnterprise = async (): Promise<void> => {
    const { meta, setMeta, handleFatalErrors, postRequestBuilder } = this.applicationStore;
    const parsedUrl = queryString.parse(window.location.search);
    const codeVerifier = window.sessionStorage.getItem('cv');

    try {
      const Fields = [
        {
          authorizationCode: parsedUrl.code,
          codeVerifier,
          redirectUri: `${window.location.origin}?auth=enterprise`,
        },
      ];

      const callAuthorizeEnterpriseResponse = await this.api({
        url: GetStartedAPIRoutes.AuthorizeEnterprise,
        method: 'post',
        data: postRequestBuilder(Fields),
      });

      const { errors, hash, applicationId } = callAuthorizeEnterpriseResponse.data;

      if (errors && errors.length > 0) {
        setMeta({ errors });

        let fatalError = errors.find((e: { type: string }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }

      setMeta({ hash, applicationId });
    } catch (e) {
      console.log('GetStartedStore | Failed on callAuthorizeEnterprise. Details: ', e);
      handleFatalErrors(e);
    }
  };

  callSSOLoginValidate = async (): Promise<void> => {
    const { meta, setMeta, handleFatalErrors, postRequestBuilder } = this.applicationStore;
    const parsedUrl = queryString.parse(window.location.search);

    try {
      const Fields = [
        {
          tokenid: parsedUrl.token,
          hash: meta.hash,
        },
      ];

      const callSSOLoginValidateResponse = await this.api({
        url: GetStartedAPIRoutes.SSOLoginValidate,
        method: 'post',
        data: postRequestBuilder(Fields),
      });

      const { errors, Hash, ApplicationId } = callSSOLoginValidateResponse.data;

      if (errors && errors.length > 0) {
        setMeta({ errors });

        let fatalError = errors.find((e: { type: string }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }

      setMeta({ hash: Hash, applicationId: ApplicationId });
    } catch (e) {
      console.log('GetStartedStore | Failed on callSSOLoginValidate. Details: ', e);
      handleFatalErrors(e);
    }
  };

  callOfferInitiate = async (isEnterpriseAuth: boolean): Promise<void> => {
    const { setMeta, handleFatalErrors, postRequestBuilder, setOffers } = this.applicationStore;

    try {
      await this.callConfigSettings();

      const callOfferInitiateResponse = await this.api({
        url: isEnterpriseAuth ? GetStartedAPIRoutes.offersInitiateEap : GetStartedAPIRoutes.offersInitiateSso,
        method: 'post',
        data: postRequestBuilder(),
      });

      const { errors, fields, hash, externalId } = callOfferInitiateResponse.data;

      if (errors && errors.length > 0) {
        const isDeclined = errors.some((e: { ruleAlias: string }) => e.ruleAlias === 'DeclinedRule');
        if (isDeclined) {
          navigate(PageRoutes.DeclinationPageRoute);
          return;
        }

        const isOdpDeclined = errors.some((e: { ruleAlias: string }) => e.ruleAlias === 'PlocEligbleAccountsRule');
        if (isOdpDeclined) {
          navigate(PageRoutes.OdpDeclinationPageRoute);
          return;
        }

        setMeta({ errors });

        let fatalError = errors.find((e: { type: string }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }

      setMeta({ hash: hash, externalId: externalId });

      if (fields.length > 0) {
        fields.forEach((offer: IOfferDetails) => {
          if (offer.repayData && offer.repayData.length > 0) {
            offer.paymentAmountMin = offer.repayData[offer.repayData.length - 1].paymentAmountMin;
            offer.paymentAmountPercent = offer.repayData[offer.repayData.length - 1].paymentAmountPercent;
          }
        });
        setOffers(fields);
      }
    } catch (e) {
      console.log('GetStartedStore | Failed on callOfferinitiate. Details: ', e);
      handleFatalErrors(e);
    }
  };

  callConfigSettings = async (): Promise<void> => {
    const { setMeta, postRequestBuilder, handleFatalErrors, setFeatureSwitch, setLivePersonChatInfo } =
      this.applicationStore;

    try {
      const configSettingsResponse = await this.api({
        url: GetStartedAPIRoutes.configInitiate,
        method: 'post',
        data: postRequestBuilder([
          { settingNames: [AppSettings.LoansChatEnabled, AppSettings.LpJsTag, AppSettings.LoansCoBrowseEnabled] },
        ]),
      });

      const { fields, errors } = configSettingsResponse.data;

      if (errors && errors.length > 0) {
        setMeta({ errors });

        let fatalError = errors.find((e: { type: string }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }
      setFeatureSwitch({
        loansChatEnabled: fields[0].configKeyValuePairs.loansChatEnabled === 'true' ? true : false,
        loansCoBrowseEnabled: fields[0].configKeyValuePairs.loansCoBrowseEnabled === 'true' ? true : false,
      });
      if (fields[0].configKeyValuePairs.lpjstag) {
        setLivePersonChatInfo({ lPJSTagScript: fields[0].configKeyValuePairs.lpjstag });
      }
    } catch (e) {
      console.log('GetStartedStore | Failed on callConfigSettings. Details: ', e);
      handleFatalErrors(e);
    }
  };

  callOfferValidate = async (selectedOffer: IOfferDetails): Promise<void> => {
    const { setMeta, handleFatalErrors, postRequestBuilder } = this.applicationStore;

    try {
      const Fields = [{ ...selectedOffer }];

      const callOfferValidateResponse = await this.api({
        url: GetStartedAPIRoutes.offersValidate,
        method: 'post',
        data: postRequestBuilder(Fields),
      });

      const { errors } = callOfferValidateResponse.data;

      if (errors && errors.length > 0) {
        setMeta({ errors });

        let fatalError = errors.find((e: { type: string }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }

      setMeta({ productAlias: selectedOffer.productAlias });

      switch (selectedOffer.productAlias) {
        case ProductAliases.UnsecuredLoan:
          navigate(PageRoutes.SelectLoanAmountPageRoute);
          break;
        case ProductAliases.PreferredCreditLine:
        case ProductAliases.OverdraftProtectionCreditLine:
          navigate(PageRoutes.SelectCreditLimitPageRoute);
          break;
        case ProductAliases.SavingsSecuredCreditLine:
        case ProductAliases.DepositSecuredLoan:
          navigate(PageRoutes.SelectCollateralAccountPageRoute);
          break;
      }
    } catch (e) {
      console.log('GetStartedStore | Failed on callOfferValidate. Details: ', e);
      handleFatalErrors(e);
    }
  };
}
export default GetStartedStore;
