import { computed } from 'mobx';
import { LivePersonRoutes } from '../../../shared/constants';

class LivePersonChatStore {
  api: any;

  applicationStore: any;

  constructor(api: any, applicationStore: any) {
    this.api = api;
    this.applicationStore = applicationStore;
  }

  @computed get shouldDisplayCoBrowse() {
    const {
      featureSwitch: { loansCoBrowseEnabled },
      lpChatInfo: { lPJSTagScript },
    } = this.applicationStore;
    return (
      (loansCoBrowseEnabled &&
        lPJSTagScript !== ''
      )
    );
  }

  @computed get shouldChatDisplay() {
    const {
      featureSwitch: { loansChatEnabled },
      lpChatInfo: { lPJSTagScript },
    } = this.applicationStore;
    return (
      loansChatEnabled &&
      lPJSTagScript !== ''
    );
  }

  updateChatToken = async () => {
    try {
      const { postRequestBuilder, setLivePersonChatInfo } = this.applicationStore;
      const response = await this.api({
        url: LivePersonRoutes.GetJWT,
        method: 'POST',
        data: postRequestBuilder([
          {
            domainName: window.location.origin,
          },
        ]),
      });
      const responseFields = response.data.fields.shift();
      const { jwtToken, sub, tokenExpiryInSec } = responseFields;
      setLivePersonChatInfo({ chatToken: jwtToken, sub, tokenExpiryInSec });
      return jwtToken;
    } catch (error) {
      console.log('LivePersonChat | Failed to fetch JWTToken', error);
      return '';
    }
  };
}

export default LivePersonChatStore;
