import { observable } from 'mobx';

export interface ILivePersonChatInfo {
  lPJSTagScript: string;
  chatToken: string;
  agentServiceID: string;
  isUnAuthenticatedChatToken: boolean;
  shouldChatReload: boolean;
  isChatSessionCreated: boolean;
  openChat: CallableFunction;
  // eslint-disable-next-line
  requestScreenShare: CallableFunction;
  sub: string;
  tokenExpiryInSec: Number;
  isCoBrowseValidOnPage: boolean;
}

const LivePersonChatInfo: ILivePersonChatInfo = observable({
  lPJSTagScript: '',
  chatToken: '',
  agentServiceID: '',
  isUnAuthenticatedChatToken: false,
  shouldChatReload: false,
  isChatSessionCreated: false,
  openChat: () => {},
  // eslint-disable-next-line
  requestScreenShare: ({}) => {},
  sub: '',
  tokenExpiryInSec: 0,
  isCoBrowseValidOnPage: true,
});
export default LivePersonChatInfo;
