import { observable } from 'mobx';

export interface IDisclosure {
    alias: string;
    html?: null;
    name: string;
    order?: number;
    pdf?: null;
    type?: null;
    url: string;
    version?: null;
}

export interface IDisclosures {
    disclosures?: IDisclosure[] | any[];
    hasAcceptedDisclosures?: boolean;
}

const Disclosures: IDisclosures = observable({
    disclosures: [],
    hasAcceptedDisclosures: false
});

export default Disclosures;
