import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Footer, FooterServiceLinks, FooterLegal, Link, Loader } from '@ux/balance-react';
import Header from '../Header';
import StyledLayout from './styles';
import footerLinks from './utils';
import { StyledSkipToMainLink } from '../../styles';
import { useStores } from '../../../hooks';
import ProgressBar from '../ProgressBar';
import SessionTimeout from '../SessionTimeout';
import LivePersonChat from '../../../views/LivePersonChat';

type LayoutProps = {
  children: JSX.Element;
};

const Layout = observer(({ children }: LayoutProps): JSX.Element => {
  const {
    applicationStore: {
      ui: { isPageLoading, pageTitle, progressBarCurrentStep },
      lpChatInfo: { lPJSTagScript },
      initEnsighten,
    },
  } = useStores();

  useEffect(() => {
    const callEnsighten = async () => {
      await initEnsighten();
    };
    callEnsighten();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <StyledLayout>
      <SessionTimeout idleTimer={null} timeElapsedSinceWarning={0} />
      <Loader isLoading={isPageLoading} />
      <StyledSkipToMainLink>
        <Link
          href='#main'
          classes='skip-to-main-link'
          text='Skip to main content'
          dataTestId='Skip_To_Main_Content_Link'
          size='small'
          variant='secondary'
        />
      </StyledSkipToMainLink>
      <Header />
      {progressBarCurrentStep !== 1 && <ProgressBar currentStep={progressBarCurrentStep} />}
      {lPJSTagScript && <LivePersonChat />}
      <main id='main' role='main'>
        {children}
      </main>
      <Container verticalSpacing='30'>
        <Footer>
          <FooterServiceLinks footerLinks={footerLinks} />
          <FooterLegal />
        </Footer>
      </Container>
    </StyledLayout>
  );
});

export default Layout;
