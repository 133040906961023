import React from 'react';

const PiggyBankIcon = (): JSX.Element => (
  <svg
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>Piggy Bank icon</title>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-164-138h375v1850h-375z" />
      <g>
        <circle fill="#5E9C11" cx="24" cy="24" r="20" />
        <path
          d="m28.125 16.75-.035.026a6.144 6.144 0 0 0-.59-.026h-5a7.51 7.51 0 0 0-1.867.234c-.004-.078-.008-.155-.008-.234a3.728 3.728 0 0 1 3.75-3.75 3.75 3.75 0 0 1 3.75 3.75ZM27.5 18a6.552 6.552 0 0 1 .898.063 3.753 3.753 0 0 1 2.852-1.313h1.25l-.734 2.934a6.246 6.246 0 0 1 1.464 2.066h.52c.691 0 1.25.559 1.25 1.25v3.75c0 .691-.559 1.25-1.25 1.25H32.5a6.316 6.316 0 0 1-1.25 1.25v2.5c0 .691-.559 1.25-1.25 1.25h-1.25c-.691 0-1.25-.559-1.25-1.25V30.5h-5v1.25c0 .691-.559 1.25-1.25 1.25H20c-.691 0-1.25-.559-1.25-1.25v-2.5a6.23 6.23 0 0 1-2.47-4.375h-1.124a2.655 2.655 0 0 1-2.656-2.656 2.655 2.655 0 0 1 2.656-2.657h.156a.936.936 0 1 1 0 1.875h-.156a.76.76 0 0 0-.781.782c0 .43.35.781.781.781h1.219a6.259 6.259 0 0 1 6.125-5h5Zm1.563 4.375a.935.935 0 0 0-.938.938c0 .519.418.937.938.937.519 0 .937-.418.937-.938a.935.935 0 0 0-.938-.937Z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);
export default PiggyBankIcon;
