import { navigate } from '@reach/router';
import { PageRoutes, GetStartedAPIRoutes } from '../../../../shared/constants';
import { getRegionsHost } from '../../../../shared/services';


class DeclinationStore {
  api: any;

  private applicationStore: any;

  constructor(api: any, applicationStore: any) {
    this.api = api;
    this.applicationStore = applicationStore;
  }

  calltoQuickStartPage = async (): Promise<void> => {
    const {
      setMeta,
      handleFatalErrors,
      postRequestBuilder,
    } = this.applicationStore;

    try {

      const calltoQuickStartPageResponse = await this.api({
        url: GetStartedAPIRoutes.TokenInitiate, // API route url
        method: 'post',
        data: postRequestBuilder(),
      });

      const { errors, fields } = calltoQuickStartPageResponse.data;

      if (fields && fields.length > 0) {
        window.open(`${getRegionsHost()}?productAlias=quickstart&token=${fields[0].scalarValue}`, '_blank');
      }

      if (errors && errors.length > 0) {

        setMeta({ errors });

        let fatalError = errors.find((e: { type: string }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }
    } catch (e) {
      console.log('OdpDeclinationStore | Failed on calltoQuickStartPage. Details: ', e);
      handleFatalErrors(e);
    }
  };
}
export default DeclinationStore;
