import styled from '@emotion/styled';
import { breakpoints } from '../../../../styles';
import colors from '../../../../../shared/styles/settings/colors';

export const StyledH2Wrapper = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1.75rem;
  @media ${breakpoints.xsMinMax} {
    font-size: 20px;
  }
`;


export const OTPCodeStyles = styled.div`
  .inner-content {
    height: 48px;
    width: 380px;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    letter-spacing: -0.06px;
    line-height: 24px;
  }

  .alert-tile-with-icon {
    display: flex;
    color: ${colors.lifeGreen};
    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      margin-top: 4px;
      fill: ${colors.lifeGreen};
    }
  }

  .error-message {
    height: 48px;
    width: 380px;
    color: ${colors.red};
    font-family: 'Source Sans Pro';
    font-size: 16px;
    letter-spacing: -0.06px;
    line-height: 24px;
  }
  .error_alert-tile-with-icon {
    margin-top: 0.75rem;
    display: flex;
    color: ${colors.red};
    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      margin-top: 4px;
      fill: ${colors.red};
    }
  }
`;

