import { observable } from 'mobx';
import { IBaseSelectKeys } from './ApplicantInfo';

export interface IIdologyQuestion {
  prompt: string;
  type: string;
  answers: string[] | any;
}

export interface IIdologyAnswer {
  type: string;
  answer: string;
}

export interface IAuth {
  token?: string;
  olbUserName?: string;
  olbPassword?: string;
  verificationType?: IBaseSelectKeys;
  cardNumber?: any;
  expirationDate?: string;
  pin?: any;
  cvvRequired?: boolean;
  securityCode?: any;
  challengeQuestionId?: string;
  challengeQuestionText?: string;
  challengeQuestionAnswer?: string;
  idologyQuestions?: IIdologyQuestion[];
  idologyIdNumber?: string;
  idologyAnswers?: IIdologyAnswer[];
  isChallengeIdologyQuestion?: boolean;
  challengeIdologyQuestionType?: string;
  hasAnsweredSecurityQuestions?: boolean;
  hasFailedLogin?: boolean;
  isExistingUser?: boolean;
}

const Auth: IAuth = observable({
  token: '',
  olbUserName: '',
  olbPassword: '',
  verificationType: { label: 'Online Banking', value: 'nolb' },
  cardNumber: '',
  expirationDate: '',
  pin: '',
  cvvRequired: false,
  securityCode: '',
  challengeQuestionId: '',
  challengeQuestionText: '',
  challengeQuestionAnswer: '',
  idologyQuestions: [],
  idologyIdNumber: '',
  idologyAnswers: [],
  isChallengeIdologyQuestion: false,
  challengeIdologyQuestionType: '',
  hasAnsweredSecurityQuestions: false,
  hasFailedLogin: false,
  isExistingUser: false
});

export default Auth;
