import { observable } from 'mobx';

export interface IStepUp {
  hasOtpSucceeded?: boolean,
  isOtpRetry?: boolean,
  oneTimePasscode?: string,
  phoneList?: any[],
  phoneSelected?: string,
  phoneSelectedKey?: string
};

const StepUp : IStepUp = observable({  
  hasOtpSucceeded: false,
  isOtpRetry: false,
  oneTimePasscode: undefined,
  phoneList: [],
  phoneSelected: undefined,
  phoneSelectedKey: undefined
});

export default StepUp
