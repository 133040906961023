import React from 'react';
import { Modal, Button, Stack } from '@ux/balance-react';
import { StyledH2Wrapper, StyledModalFooterCta } from '../../HelpMenu/MakeAppointmentModal/styles';

type SessionTimeoutModalProps = {
  isOpen: boolean;
  modalHeader: string;
  modalContent: string;
  isWarningPopUp: boolean;
  closeMethod(): void;
  exitApplication(): void;
  continueApplication(): void;
  completeExitApplication(): void;
};

const SessionTimeoutModal = ({
  isOpen,
  modalHeader,
  modalContent,
  isWarningPopUp,
  closeMethod,
  exitApplication,
  continueApplication,
  completeExitApplication,
}: SessionTimeoutModalProps): JSX.Element => {
  const modalCTAs = (
    <>
      {isWarningPopUp && (
        <StyledModalFooterCta>
          <Button
            text='No, exit activation'
            size='medium'
            dataTestId='Exit_Activation_Button'
            variant='text-secondary'
            clickMethod={() => exitApplication()}
          />
          <Button
            text='Yes, continue'
            size='medium'
            dataTestId='Return_To_Activation_Button'
            variant='primary'
            clickMethod={() => continueApplication()}
          />
        </StyledModalFooterCta>
      )}

      {!isWarningPopUp && (
        <StyledModalFooterCta>
          <Button
            text='Exit activation'
            size='medium'
            dataTestId='Exit_Activation_CTA'
            variant='primary'
            clickMethod={() => completeExitApplication()}
          />
        </StyledModalFooterCta>
      )}
    </>
  );
  return (
    <Modal
      isOpen={isOpen}
      closeMethod={() => closeMethod()}
      ariaLabel={modalHeader}
      modalCTAs={modalCTAs}
      isModalTitleStatic
    >
      <StyledH2Wrapper>{modalHeader}</StyledH2Wrapper>
      <Stack>
        <p className='text-style-body-2'>{modalContent}</p>
      </Stack>
    </Modal>
  );
};

export default SessionTimeoutModal;
