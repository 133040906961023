// @ts-nocheck

const defaults = {
  ssn: {
    delimiter: '-',
    grouping: [3, 2, 4],
  },
  phone: {
    delimiter: '-',
    grouping: [3, 3, 4],
  },
  dateMDY: {
    delimiter: '/',
    grouping: [2, 2, 4],
  },
  zip: {
    delimiter: '-',
    grouping: [5, 4],
  },
  currency: {
    symbol: '',
    delimiter: ',',
    grouping: [3, 3, 3, 3],
    decimalPoint: '.',
    decimals: [2],
    isCurrency: true
  },
};

const filters = ({ delimiter, isCurrency, symbol, decimalPoint, decimals }) => {
  const leading = symbol ? `(\\${symbol})?` : '';
  const tailing = decimals ? `(\\${decimalPoint}\\d{0,${decimals}})?` : '';
  const seperator = delimiter ? `\\${delimiter}?` : '';
  const group = val => (isCurrency ? `(\\d{${val}})?` : `(\\d{0,${val}})?`);
  const first = val =>
    isCurrency ? `([0]|[1-9]${val > 1 ? `\\d{0,${val - 1}}` : ''})?` : group(val);

  return (val, ind, arr) => {
    switch (true) {
      case arr.length === 1:
        return `${leading}${first(val)}${tailing}`;
      case ind + 1 === 1:
        return `${leading}${first(val)}`;
      case ind + 1 === arr.length:
        return `${seperator}${group(val)}${tailing}`;
      default:
        return `${seperator}${group(val)}`;
    }
  };
};

const values = ({ symbol, delimiter, decimals }) => {
  const group = (val, ind) => (symbol && ind === 1 ? `${val}` : `${delimiter}${val}`);
  const last = (val) => (decimals ? val : `${delimiter}${val}`);

  return (val, ind, arr) => {
    switch (ind + 1) {
      case 1:
        return val;
      case arr.length:
        return val && last(val);
      default:
        return val && (!arr[ind - 1] ? val : group(val, ind));
    }
  };
};

const pattern = ({ filter, grouping, delimiter, isCurrency, symbol, decimalPoint, decimals }) =>
  filter ||
  grouping
    .map(
      filters({
        delimiter,
        isCurrency,
        symbol,
        decimalPoint,
        decimals,
      })
    )
    .join('');

class FormattedString {
  constructor(value = '') {
    this.raw = value;
  }

  config({ filter, grouping, delimiter = '', isCurrency = false, symbol, decimalPoint, decimals }) {
    /* arbitrary regex */
    this.filter = filter;

    if (isCurrency) {
      /* settings for currency formats */
      this.isCurrency = isCurrency;
      this.symbol = symbol || defaults.currency.symbol;
      this.grouping = grouping || defaults.currency.grouping;
      this.delimiter = delimiter || defaults.currency.delimiter;
      this.decimalPoint = decimalPoint || defaults.currency.decimalPoint;
      this.decimals = decimals || defaults.currency.decimals;
    }
    else {
      /* settings for common formats */
      this.delimiter = delimiter;
      this.grouping = grouping || [this.raw.length];
    }
    return this;
  }

  format() {
    let str = this.isCurrency ? this.raw.split(`${this.delimiter}`).join('') : this.raw;
    // Band-aid fix for date fields
    if (this.delimiter === '/' && this.raw.includes('-')) {
      str = str.replace(/-/g, '/');
    }

    const re = new RegExp(`^\\s*${pattern(this)}\\s*$`);
    const parsed = str.match(re);
    // If the inputted string does not match any regex cases for autoformatting, just return the string as it is so that the field
    // validation can display the invalid input error
    if (!parsed) {
      return str;
    }

    return (
      (parsed &&
        parsed
          .filter((val, ind) => ind > 0)
          .map(values(this))
          .join('')) ||
      ''
    );
  }

  get value() {
    return this.format();
  }

  get ssn() {
    return this.config(defaults.ssn).value;
  }

  get phone() {
    return this.config(defaults.phone).value;
  }

  get dateMDY() {
    return this.config(defaults.dateMDY).value;
  }

  get zip() {
    return this.config(defaults.zip).value;
  }

  get currency() {
    return this.config(defaults.currency).value;
  }
}

const autoFormatter = (str: string) => new FormattedString(str.valueOf());

export default autoFormatter;