import { navigate } from '@reach/router';
import { AccountAgreementsAPIRoutes, BookingTermsAPIRoutes, PageRoutes, ProductAliases } from '../../../shared/constants';
import { IDisclosure } from '../../../Application/store/Models/Disclosures';
import { forEach, set } from 'lodash-es';
import { IBaseSelectKeys } from '../../../Application/store/Models/ApplicantInfo';

class AccountAgreementsStore {
  api: any;

  private applicationStore: any;

  constructor(api: any, applicationStore: any) {
    this.api = api;
    this.applicationStore = applicationStore;
  }

  callAccountAgreementsInitiate = async (): Promise<void> => {
    const { setMeta, handleFatalErrors, postRequestBuilder, setDisclosures } = this.applicationStore;

    try {
      const callAccountAgreementsInitiateResponse = await this.api({
        url: AccountAgreementsAPIRoutes.accountAgreementsInitiate,
        method: 'post',
        data: postRequestBuilder(),
      });

      const { errors, fields } = callAccountAgreementsInitiateResponse.data;

      if (fields !== null) {
        await setDisclosures({ disclosures: fields });
      }

      setMeta({ errors } ?? []);

      let fatalError = errors?.find((e: { type: string }) => e.type === 'FATAL');

      if (fatalError) {
        navigate(PageRoutes.GenericErrorPageRoute);
      }
    } catch (e) {
      console.log('AccountAgreementsStore | Failed on callAccountAgreementsInitiate. Details: ', e);
      handleFatalErrors(e);
    }
  };

  callTermsInitiate = async (): Promise<void> => {
    const {
      isOfferSecured,
      collateralOptions,
      setMeta,
      handleFatalErrors,
      postRequestBuilder,
      selectedOffer,
      setSelectedOffer,
      isOfferCreditLine
    } = this.applicationStore;

    try {
      const callTermsInitiateResponse = await this.api({
        url: BookingTermsAPIRoutes.bookingTermsInitiate,
        method: 'post',
        data: postRequestBuilder(),
      });

      const { errors, fields } = callTermsInitiateResponse.data;

      if (fields && fields.length > 0) {
        let fundsRemainingRecalculated = 0;
        if (isOfferSecured()) {
          const collateralAccount = collateralOptions.options.find(
            (k: IBaseSelectKeys) => k.value === selectedOffer.collateralAccount
          );

          /*isOfferCreditLine()
            ? fundsRemainingRecalculated = collateralAccount.balance - fields[0].loanAmount
            : fundsRemainingRecalculated = collateralAccount.balance - fields[0].amountFinancedTotal; */
            fundsRemainingRecalculated = collateralAccount.balance - fields[0].loanAmount;
        }

        await setSelectedOffer({
          selectedLoanAmount: fields[0].loanAmount,
          annualPercentRateCalculated: fields[0].annualPercentRateTotal,
          monthlyPaymentEstimate: isOfferCreditLine() ? selectedOffer.monthlyPaymentEstimate : fields[0].estimatedPayment,
          calculatedterms: fields[0].monthlyPaymentsCount,
          calculatedDocStampFee: fields[0].docStampFee,
          processingFee: fields[0].processingFee,
          totalAmountFinanced: fields[0].amountFinancedTotal,
          fundsRemaining: fundsRemainingRecalculated,
        });
      }

      setMeta({ errors } ?? []);

      let fatalError = errors?.find((e: { type: string }) => e.type === 'FATAL');

      if (fatalError) {
        navigate(PageRoutes.GenericErrorPageRoute);
      }
    } catch (e) {
      console.log('AccountAgreementsStore | Failed on callTermsInitiate. Details: ', e);
      handleFatalErrors(e);
    }
  };

  callAccountAgreementsPdfOpen = async (): Promise<void> => {
    const { handleFatalErrors, postRequestBuilder, disclosures } = this.applicationStore;

    try {
      const callAccountAgreementsPdfOpenResponse = await this.api({
        url: AccountAgreementsAPIRoutes.accountAgreementsInitiate + '/' + disclosures.disclosures[0].alias,
        method: 'post',
        data: postRequestBuilder(),
        responseType: 'arraybuffer',
      });

      const { data } = callAccountAgreementsPdfOpenResponse;
      const blob = new Blob([data], { type: 'application/pdf' });

      window.open(window.URL.createObjectURL(blob));
    } catch (e) {
      console.log('AccountAgreementsStore | Failed on callAccountAgreementsPdfOpen. Details: ', e);
      handleFatalErrors(e);
    }
  };

  callAccountAgreementsValidate = async (disclosure: IDisclosure): Promise<void> => {
    const { setMeta, handleFatalErrors, postRequestBuilder, disclosures } = this.applicationStore;

    try {
      let fields: any = null;
      if (disclosures) {
        forEach(disclosures.disclosures, function (d) {
          set(d, 'url', '');
        });

        fields = disclosures.disclosures;
      }

      const callAccountAgreementsValidateResponse = await this.api({
        url: AccountAgreementsAPIRoutes.accountAgreementsValidate,
        method: 'post',
        data: postRequestBuilder(fields),
      });

      const { errors } = callAccountAgreementsValidateResponse.data;

      if (errors && errors.length > 0) {
        setMeta({ errors });

        let fatalError = errors.find((e: { type: string }) => e.type === 'FATAL');

        if (fatalError) {
          navigate(PageRoutes.GenericErrorPageRoute);
          return;
        }
      }

      navigate(PageRoutes.CongratulationsPageRoute);
    } catch (e) {
      console.log('AccountAgreementsStore | Failed on callAccountAgreementsValidate. Details: ', e);
      handleFatalErrors(e);
    }
  };
}
export default AccountAgreementsStore;
