import React from 'react';

const SuccessIcon = (): JSX.Element => (
  <svg
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>Success icon</title>
    <path
      d="M24 .5C36.984.5 47.5 11.016 47.5 24S36.984 47.5 24 47.5.5 36.984.5 24 11.016.5 24 .5Zm9.53 15.733a1.653 1.653 0 0 0-2.339.047L20.475 27.407l-3.396-3.689-.105-.106a1.646 1.646 0 0 0-2.233 0 1.653 1.653 0 0 0-.094 2.339l5.77 6.274 13.148-13.654.093-.105a1.663 1.663 0 0 0-.14-2.233h.011Z"
      fill="#5E9C11"
      fillRule="evenodd"
    />
  </svg>
);
export default SuccessIcon;
