import React from 'react';

const LoanIcon = (): JSX.Element => (
    <svg
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>Icon Loan</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h48v48H0z" />
      <path
        d="M40.75 10.08c.9-.14 1.23-.07 1.59.05.42.14.77.4 1.03.75.23.3.4.59.54 1.49l2.17 13.68c.14.9.07 1.23-.05 1.59-.14.42-.4.77-.75 1.03-.3.23-.59.4-1.49.54l-31.27 4.95c-.9.14-1.23.07-1.59-.05a2.1 2.1 0 0 1-1.03-.75c-.23-.3-.4-.59-.54-1.49L7.19 18.19c-.14-.9-.07-1.23.05-1.59.14-.42.4-.77.75-1.03.3-.23.59-.4 1.49-.54l31.27-4.95Z"
        fill="#5E9C11"
      />
      <path
        d="M11.67 35.02c-.4 0-.7-.07-1-.17-.57-.2-1.04-.54-1.4-1.02-.32-.43-.53-.85-.69-1.84L6.41 18.31c-.16-.99-.09-1.45.08-1.96.2-.57.54-1.04 1.02-1.4.43-.32.85-.53 1.84-.69l31.28-4.95c.99-.16 1.45-.09 1.96.08.56.2 1.03.54 1.4 1.01.32.43.53.85.69 1.84l2.17 13.68c.16.99.09 1.45-.08 1.96-.2.57-.54 1.03-1.01 1.4-.43.32-.85.53-1.84.69l-31.27 4.95c-.39.06-.7.09-.96.09l-.02.01Zm29.21-24.18L9.6 15.8c-.8.13-.97.25-1.15.39-.23.17-.39.4-.49.67-.07.21-.14.41-.02 1.21l2.17 13.68c.13.8.25.97.39 1.15.17.23.4.39.67.49.21.07.41.14 1.21.02l31.27-4.95c.8-.13.97-.25 1.15-.39.23-.17.39-.4.49-.67.07-.21.14-.41.02-1.21l-2.17-13.68c-.13-.8-.25-.97-.39-1.15-.17-.23-.4-.39-.67-.49-.21-.07-.42-.14-1.21-.02l.01-.01Z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M38.46 14.19c.91-.08 1.24.01 1.58.16.41.17.74.45.98.82.2.31.35.62.43 1.53l1.21 13.79c.08.91-.01 1.24-.16 1.58-.17.41-.45.74-.82.98-.31.2-.62.35-1.53.43L8.61 36.24c-.91.08-1.24-.01-1.58-.16-.41-.17-.74-.45-.98-.82-.2-.31-.35-.62-.43-1.53L4.41 19.94c-.08-.91.01-1.24.16-1.58.17-.41.45-.74.82-.98.31-.2.62-.35 1.53-.43l31.54-2.76Z"
        fill="#5E9C11"
      />
      <path
        d="M8.02 37.05c-.58 0-.93-.09-1.3-.25-.55-.24-1-.61-1.32-1.11-.29-.45-.47-.88-.56-1.88L3.63 20.02c-.09-1 0-1.46.22-1.95.24-.55.61-1 1.11-1.32.45-.29.88-.47 1.88-.56l31.54-2.76c1-.09 1.46.01 1.95.22.55.24 1 .61 1.32 1.11.29.45.47.88.56 1.88l1.21 13.79c.09 1 0 1.46-.22 1.95-.24.55-.61 1-1.11 1.32-.45.29-.88.47-1.88.56L8.67 37.02c-.25.02-.46.03-.65.03Zm31.03-22.11c-.14 0-.31 0-.52.03L6.99 17.73c-.8.07-.98.19-1.17.31-.24.16-.42.37-.53.63-.09.21-.17.4-.1 1.21L6.4 33.67c.07.8.19.98.31 1.17.16.24.37.42.64.53.21.09.4.17 1.21.1l31.54-2.76c.8-.07.98-.19 1.17-.31.24-.16.42-.37.53-.63.09-.21.17-.4.1-1.21l-1.21-13.79c-.07-.8-.19-.98-.31-1.17a1.41 1.41 0 0 0-.63-.53c-.15-.07-.3-.13-.69-.13h-.01Z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M36.27 18.63c.91 0 1.23.12 1.56.3.39.21.7.51.9.9.18.33.3.65.3 1.56v13.85c0 .91-.12 1.23-.3 1.56-.21.39-.51.7-.9.9-.33.18-.65.3-1.56.3H4.6c-.91 0-1.23-.12-1.56-.3-.39-.21-.7-.51-.9-.9-.18-.33-.3-.65-.3-1.56V21.39c0-.91.12-1.23.3-1.56.21-.39.51-.7.9-.9.33-.18.65-.3 1.56-.3h31.67Z"
        fill="#5E9C11"
      />
      <path
        d="M36.27 38.78H4.6c-1.01 0-1.45-.14-1.92-.39-.53-.28-.94-.69-1.22-1.22-.25-.47-.39-.92-.39-1.92V21.4c0-1 .14-1.45.39-1.92.28-.53.69-.94 1.22-1.22.47-.25.92-.39 1.92-.39h31.66c1 0 1.45.14 1.92.39.53.28.94.69 1.22 1.22.25.47.39.92.39 1.92v13.85c0 1.01-.14 1.45-.39 1.92-.28.53-.69.94-1.22 1.22-.47.25-.92.39-1.92.39h.01ZM4.6 19.41c-.81 0-.99.1-1.19.21-.25.14-.45.33-.59.59-.11.2-.21.39-.21 1.19v13.85c0 .81.1.99.21 1.19.14.25.33.45.59.59.2.11.39.21 1.19.21h31.66c.81 0 .99-.1 1.19-.21.25-.14.45-.33.59-.59.11-.2.21-.39.21-1.19V21.4c0-.81-.1-.99-.21-1.19-.14-.25-.33-.45-.59-.59-.2-.11-.39-.21-1.19-.21H4.6Z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M21.67 22.36c.23 0 .45.23.45.45v.45c.98.08 1.65.45 2.4.9.15.15.3.23.3.45 0 .3-.23.53-.53.53-.08 0-.23 0-.3-.08-.6-.45-1.28-.75-1.88-.83v3.53c2.18.45 3.08 1.35 3.08 2.78 0 1.5-1.28 2.55-3 2.7v.98c0 .23-.23.45-.45.45s-.45-.23-.45-.45v-1.05c-1.2-.08-2.18-.53-3.08-1.28-.15-.15-.23-.23-.23-.45 0-.3.23-.53.53-.53.15 0 .23.08.3.15.75.68 1.5 1.05 2.55 1.2v-3.61c-2.1-.53-3-1.28-3-2.78 0-1.5 1.28-2.55 3-2.63v-.45c-.23-.23 0-.45.3-.45l.01.02Zm-.45 5.18v-3.38c-1.2.08-1.95.75-1.95 1.58 0 .9.38 1.43 1.95 1.8Zm.83 1.35v3.38c1.2-.08 1.95-.75 1.95-1.65 0-.83-.38-1.35-1.95-1.73Z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
export default LoanIcon;
