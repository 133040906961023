import { observable } from 'mobx';

export interface IFeatureSwitch {
  applyNowNewCustomerPromoCodeRequired?: boolean;
  idExpirationDateAllowedDays?: null | number;
  maximumBalanceTransfers?: string;
  maximumAdditionalAccountHolders?: string;
  loansChatEnabled?: boolean;
  loansCoBrowseEnabled?: boolean;
  transmitOAuthClientId?: string;
}

const FeatureSwitch: IFeatureSwitch = observable({
  applyNowNewCustomerPromoCodeRequired: false,
  idExpirationDateAllowedDays: null,
  maximumBalanceTransfers: '',
  maximumAdditionalAccountHolders: '',
  loansChatEnabled: false,
  loansCoBrowseEnabled: false,
  transmitOAuthClientId: '',
});

export default FeatureSwitch;
