const PMT = (rate, nper, pv, fv, type) => {
    /*
     * rate   - interest rate per month
     * nper   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    let pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (rate === 0)
      return -(pv + fv) / nper;

    pvif = Math.pow(1 + rate, nper);
    pmt = - rate * (pv * pvif + fv) / (pvif - 1);

    if (type === 1)
      pmt /= (1 + rate);
    return (isNaN(pmt) ? 0 : pmt);
  }
 
//   let rate = (4 / 100) / 12; // 4% rate 
//   let nper = 30 * 12; //30 years in months
//   let pv = -400000 * (1 - (3.5 / 100)); //3.5%
  
//   // call the function
//   PMT(rate, nper, pv, 0, 0)
  
  
// // if you dont't have optional argument (fv and type) you can initialized them to 0 or follow the bellow codes.
// let PMT = rate * pv * Math.pow((1 + rate), nper) / (1 - Math.pow((1 + rate), nper));

export default PMT;