import styled from '@emotion/styled';

const StyledProgressBar = styled.div`
  margin: 0.75rem auto 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80rem;
`;

export default StyledProgressBar;
