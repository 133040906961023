import { ProductAliases } from '../../../constants';
import _ from 'lodash';
import { IOfferDetails } from '../../../../Application/store/Models/Offers';

const defaultProps = {
  appId: 0,
  postalCode: '',
  product: '',
  isExistingCustomer: true,
  page: '',
};

/* eslint no-console: 0 */
const dispatchEvent = (
  storeData: any,
  offers: IOfferDetails[] | null = null,
  selectedOffer: any = null,
  clickEvent: any = null,
  isSessionTimeout: boolean = false
): void => {
  const { meta } = storeData;
  let numOfOffers = 0;
  if (offers) {
    numOfOffers = offers.length;
  }
  // Ensighten is throwing console errors due to a missing function. This checks to see if that
  // function exists and noop's it if it does not exist.
  if (!('ttd_dom_ready' in window)) {
    // @ts-ignore
    window.ttd_dom_ready = () => { };
  }
  let pageTitle = '';
  let events = '';
  const page = () => {
    const pageName = window.location.pathname.replace(/-/g, ' ').replace('/', '');
    if (isSessionTimeout) {
      events = 'event1';
      pageTitle = 'your session has expired';
    } else if (pageName === 'error') {
      events = 'event1';
      pageTitle = 'were unable to proceed';
      return 'were unable to proceed';
    } else if (pageName === 'declination' || pageName === 'odp declination') {
      events = 'event1';
      pageTitle = 'were unable to proceed eligibility';
      return 'were unable to proceed eligibility';
    } else if (pageName === 'activate') {
      events = 'event1';
      pageTitle = 'You are approved';
      return 'you are approved';
    }else if (numOfOffers > 0 && !selectedOffer) {
      pageTitle = "You've been approved!";
      events = 'scAdd,event1';

      try {
        if (offers) {
          if (
            offers.filter(
              (offer: IOfferDetails) =>
                offer.productAlias === ProductAliases.SavingsSecuredCreditLine ||
                offer.productAlias === ProductAliases.DepositSecuredLoan
            ).length === 2 ||
            offers.filter((offer: IOfferDetails) => offer.productAlias === ProductAliases.OverdraftProtectionCreditLine)
              .length === 1
          ) {
            return 'You have been approved';
          }
        }
      } catch (e) {
        return 'youve been approved';
      }

      return 'youve been approved';
    } else if (numOfOffers === 0 && !selectedOffer) {
      pageTitle = 'No current offers';
      events = 'event1';
      return 'no current offers';
    } else if (numOfOffers > 0 && selectedOffer) {
      // Offer has been selected
      events = 'event1';
      switch (pageName) {
        case 'select collateral':
          pageTitle = 'select collateral';
          events = 'event1';
          return 'select collateral';
        case 'select overdraft':
          pageTitle = 'overdraft coverage account';
          events = 'event1';
          return 'overdraft coverage account';
        case 'select transfer amount':
          pageTitle = 'Select transfer amount';
          return 'select transfer amount';
        case 'select credit limit':
          pageTitle = 'Select credit limit';
          return 'select credit limit';
        case 'select loan amount':
          pageTitle = 'Select loan amount';
          return 'select loan amount';
        case 'transfer funds':
          pageTitle = 'Transfer funds to your Regions account';
          return 'transfer funds to your regions account';
        case 'select loan purpose':
          pageTitle = 'Loan purpose';
          return 'loan purpose';
        case 'transfer confirmation':
          pageTitle = 'Transfer Confirmation';
          return 'transfer confirmation';
        case 'review offer': {
          if (
            selectedOffer.productAlias === ProductAliases.PreferredCreditLine ||
            selectedOffer.productAlias === ProductAliases.SavingsSecuredCreditLine ||
            selectedOffer.productAlias === ProductAliases.OverdraftProtectionCreditLine
          ) {
            pageTitle = 'Review your line of credit';
            return 'review your line of credit';
          } else if (selectedOffer.productAlias === ProductAliases.UnsecuredLoan) {
            pageTitle = 'Review your loan';
            return 'review your loan';
          } else if (selectedOffer.productAlias === ProductAliases.DepositSecuredLoan) {
            pageTitle = 'Saving Secure review your loan';
            return 'review your loan';
          }
          break;
        }
        case 'account agreements':
          pageTitle = 'Account agreements';
          return 'account agreements';
        case 'congratulations': {
          if (selectedOffer.productAlias === ProductAliases.OverdraftProtectionCreditLine) {
            pageTitle = `Congratulations`;
          } else {
            pageTitle = `Congratulations, ` + _.capitalize(selectedOffer.customerFirstName) + `!`;
          }
          events = 'event1,purchase';
          return 'congratulations';
        }
      }
    } else {
      pageTitle = '';
      events = '';
      return '';
    }
  };

  const pageProduct = () => {
    if (offers && offers.length > 0 && selectedOffer) {
      switch (selectedOffer.productAlias) {
        case ProductAliases.UnsecuredLoan:
        case ProductAliases.PreferredCreditLine:
          return 'loc';
        case ProductAliases.SavingsSecuredCreditLine:
          return 'slc';
        case ProductAliases.DepositSecuredLoan:
          return 'ssl';
        case ProductAliases.OverdraftProtectionCreditLine:
          return 'poc';
      }
    }

    return 'none';
  };

  const product = () => {
    if (offers && offers.length > 0 && selectedOffer) {
      switch (selectedOffer.productAlias) {
        case ProductAliases.UnsecuredLoan:
          return 'installment loan';
        case ProductAliases.PreferredCreditLine:
          return 'preferred line of credit';
        case ProductAliases.SavingsSecuredCreditLine:
          return 'savings secured line of credit';
        case ProductAliases.DepositSecuredLoan:
          return 'saving secure loan';
        case ProductAliases.OverdraftProtectionCreditLine:
          return 'protection line of credit';
      }
    }

    return 'none';
  };

  const appData: IAppData = {
    AppID: (meta && meta.applicationId) || defaultProps.appId,
    Product: product(),
    Page: `lnd|${pageProduct()}|${page()}`,
    UniquePageName: page(),
    Channel: pageProduct(),
    Subdomain: 'lnd',
    URL: window.location.href,
    Events: events,
    PageTitle: pageTitle,
  };

  interface IAppData {
    AppID?: number;
    Product?: any;
    Page?: string;
    UniquePageName?: string;
    Channel?: string;
    Subdomain?: string;
    URL?: string;
    EventName?: string;
    Events?: string;
    PageTitle?: string;
  }

  if (clickEvent) {
    if (clickEvent.startsWith('>')) {
      appData.EventName = appData.Page + clickEvent;
    } else {
      appData.EventName = clickEvent;
    }
  }

  console.log('Page event: ', appData);
  const evt = new CustomEvent('REGIONS.tracking', { detail: appData });
  window.dispatchEvent(evt);
};

export default dispatchEvent;
